import {
  useCmsContentTypes,
  useCmsMappingDestinations,
  useCmsMappingSources,
  useGetCmsMappings,
  useMutateCmsMappings,
} from '@/api/openapiComponents';
import { CmsContentTypeResource } from '@/api/openapiSchemas';
import { Button } from '@/Components/v2/Button';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import * as Table from '@/Components/Table';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { Autocomplete } from '@/Components/v2/Select/AutoComplete';
import { ArrowRight, Plus, RefreshCcw } from 'lucide-react';
import PageContainer from '@/Components/PageContainer';
import { AddCustomFieldDialog } from '../CustomFieldsTable/components/EditCustomFieldDialog';

export const MapCustomFields = () => {
  const appState = useAppStore();
  const client = useQueryClient();
  const navigate = useNavigate({ from: '/settings/custom-fields/mapping' });
  const search = useSearch({ from: '/settings/custom-fields/mapping' });
  const { showSnackbar } = useSnackbar();

  const [contentType, setContentType] = useState<CmsContentTypeResource>();
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);

  const updateMappingsMutation = useMutateCmsMappings();
  const contentTypesQuery = useCmsContentTypes({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });
  const mappingsQuery = useGetCmsMappings({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });
  const destinationsQuery = useCmsMappingDestinations(
    {
      pathParams: {
        contentType: contentType?.id,
        project: appState.currentProject!.id,
      },
      queryParams: {
        limit: 100,
        page: search.page,
      },
    },
    {
      enabled: !!contentType,
    },
  );

  const sourcesQuery = useCmsMappingSources({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const handleChange = (value: string | undefined, destinationId: number) => {
    const newMappings = mappingsQuery.data?.data.some(
      (item) => item.destination === destinationId,
    )
      ? mappingsQuery.data.data.map((mapping) =>
          mapping.destination === destinationId
            ? { ...mapping, source: value ?? null }
            : mapping,
        )
      : [
          ...(mappingsQuery.data?.data ?? []),
          { destination: destinationId, source: value },
        ];
    client.setQueriesData(
      {
        predicate: (query) => query.queryKey.includes('getCmsMappings'),
      },
      (prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          data: newMappings,
        };
      },
    );
    updateMappingsMutation.mutate(
      {
        body: {
          mappings: newMappings,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onError: () => {
          showSnackbar({
            color: 'red',
            message: 'Failed to save changes',
          });
        },
        onSuccess: () => {
          showSnackbar({
            color: 'green',
            message: 'Changes saved',
            duration: 800,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (contentTypesQuery.isSuccess) {
      setContentType(contentTypesQuery.data?.data[0]);
    }
  }, [contentTypesQuery.isSuccess]);

  return (
    <>
      <AddCustomFieldDialog
        key={`${showAddNewDialog}`}
        isOpen={showAddNewDialog}
        onClose={() => setShowAddNewDialog(false)}
        onSuccess={() => sourcesQuery.refetch()}
      />
      <PageContainer
        title="Mapping"
        isSubContainer
        actions={
          <div className="flex gap-2">
            <Button
              text="Refresh"
              prependIcon={RefreshCcw}
              variant="ghost"
              isLoading={destinationsQuery.isRefetching}
              onClick={() => destinationsQuery.refetch()}
            />
            <Button
              text="Manage"
              variant="ghost"
              onClick={() => navigate({ to: '/settings/custom-fields/manage' })}
            />
            <Button
              prependIcon={Plus}
              text="new SEO.AI field"
              color="secondary"
              onClick={() => setShowAddNewDialog(true)}
            />
          </div>
        }
      >
        <div className="mb-8 mt-2">
          <InputDecoration label="Content type">
            <Select
              disabled={contentTypesQuery.isLoading}
              value={contentType?.id}
              onChange={(value) =>
                setContentType(
                  contentTypesQuery.data?.data.find(
                    (type) => type.id === value,
                  ),
                )
              }
              options={
                contentTypesQuery.data?.data.map((type) => ({
                  title: type.name,
                  value: type.id,
                })) || []
              }
            />
          </InputDecoration>
        </div>

        {contentType && (
          <Table.Root
            skeletonLoaders={5}
            isLoading={
              destinationsQuery.isLoading ||
              sourcesQuery.isLoading ||
              mappingsQuery.isLoading
            }
            items={destinationsQuery.data?.data ?? []}
            columns={[
              {
                heading: `Shopify field`,
                render: (destination) => (
                  <div className="flex justify-between">
                    {destination.display_name}{' '}
                    <ArrowRight className="mr-8 text-primary-400" size={20} />
                  </div>
                ),
              },
              {
                heading: 'SEO.AI Field',
                render: (destination) => {
                  const mapping = mappingsQuery.data?.data.find(
                    (mapping) => mapping.destination === destination.id,
                  );

                  return (
                    <Autocomplete
                      clearable
                      value={mapping?.source ?? ''}
                      onChange={(value) => handleChange(value, destination.id)}
                      options={(() => {
                        const data = (sourcesQuery.data?.data ?? []).map(
                          (source) => ({
                            title: source.name,
                            value: source.value,
                          }),
                        );
                        return [
                          {
                            group: 'Default',
                          },
                          ...data.filter((source) =>
                            Number.isNaN(Number(source.value)),
                          ),
                          {
                            group: 'Custom',
                          },
                          ...data.filter(
                            (source) => !Number.isNaN(Number(source.value)),
                          ),
                        ];
                      })()}
                    />
                  );
                },
              },
            ]}
          >
            <Table.Footer>
              <Pagination
                currentPage={search.page}
                lastPage={destinationsQuery.data?.meta.total_pages ?? 0}
                setCurrentPage={(page) => navigate({ search: { page } })}
              />
            </Table.Footer>
          </Table.Root>
        )}
      </PageContainer>
    </>
  );
};
