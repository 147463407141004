import { useRouter, useSearch } from '@tanstack/react-router';
import { CreatePageContainer } from '../../../components';
import { Card } from '@/Components/v2/Card';
import { Input } from '@/Components/v2/Input/Input';
import { FormEvent, useState } from 'react';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Button } from '@/Components/v2/Button';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '../../../AppLoader/stores';
import { hasher } from '../../../AppLoader';
import { ErrorHelper } from '@/Services/ErrorHandling';
import {
  useCreateDocument,
  useListContentTypes,
} from '@/api/openapiComponents';
import { ErrorAlert } from '@/Components/v2/Alert';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';

export const CreateBlankDocument = () => {
  const router = useRouter();
  const appState = useAppStore();
  const params = useSearch({ from: '/create-content/blank' });
  const placeholder = useGetRandomPlaceHolder();
  const [keywordInput, setKeywordInput] = useState(params.keyword ?? '');
  const [selectedContentTypeId, setSelectedContentTypeId] = useState<number>();

  const contentTypeQeury = useListContentTypes({});
  const createDocumentMutation = useCreateDocument();

  const handleCreate = (e: FormEvent) => {
    e.preventDefault();
    createDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },

        body: {
          keyword: keywordInput,
          content_type_id: selectedContentTypeId,
        },
      },
      {
        onSuccess: (data) => {
          router.navigate({
            to: `/documents/$documentId`,
            params: { documentId: hasher.encode(data.data.id) },
          });
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(createDocumentMutation.error as any);

  return (
    <CreatePageContainer title="New content">
      <div className="mt-4 w-[min(40rem,90vw)]">
        <Card fullWidth>
          <CardBody>
            <form
              onSubmit={handleCreate}
              className="flex  flex-col gap-6 px-10 py-4"
            >
              {contentTypeQeury.isLoading ? (
                <>
                  <SkeletonLoader />
                  <SkeletonLoader />
                </>
              ) : (
                <>
                  {errorHelper.message() && (
                    <ErrorAlert title={errorHelper.message()} />
                  )}
                  <InputDecoration label="Target keyword">
                    <Input
                      error={errorHelper.has('keyword')}
                      fullWidth
                      value={keywordInput}
                      onChange={setKeywordInput}
                      placeholder={placeholder.keyword}
                      clearable
                    />
                  </InputDecoration>

                  <ContentTypeSelect
                    contentTypes={contentTypeQeury.data?.data ?? []}
                    isLoading={contentTypeQeury.isLoading}
                    onChange={(value) => setSelectedContentTypeId(value)}
                    value={selectedContentTypeId}
                  />
                  <div className="mx-auto w-1/2">
                    <Button
                      fullWidth
                      text="Create"
                      color="secondary"
                      type="submit"
                      disabled={
                        !appState.subscription!.usage.credits.content.is_allowed
                      }
                      isLoading={createDocumentMutation.isPending}
                    />
                  </div>
                </>
              )}
            </form>
          </CardBody>
        </Card>
      </div>
    </CreatePageContainer>
  );
};
