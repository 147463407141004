import { HelpCircle } from 'lucide-react';
import React from 'react';
import { Tooltip } from '../Tooltip';

type Props = {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  description?: string;
  hint?: string;
  tooltip?: string;
  variant?: 'default' | 'overlay';
};

export const InputDecoration = ({
  children,
  label,
  description,
  required,
  tooltip,
  hint,
  variant = 'default',
}: Props) => {
  const getVariantStyling = () => {
    switch (variant) {
      case 'overlay':
        return 'absolute -top-2 left-2 z-50 bg-white px-2 text-sm font-normal';
      default:
        return '';
    }
  };

  return (
    <div className={`relative w-full ${variant === 'overlay' ? 'mt-2' : ''}`}>
      <p
        className={`mb-1 flex items-end gap-1 font-bold text-primary-800 ${getVariantStyling()}`}
      >
        {label} {required ? <span className="text-red-400">*</span> : undefined}{' '}
        {hint && <span className="text-sm text-primary-400">{hint}</span>}
        {tooltip && (
          <div className="-mb-0.5">
            <Tooltip description={tooltip}>
              <HelpCircle size={14} className="stroke-black" />
            </Tooltip>
          </div>
        )}
      </p>
      {description ? (
        <p className=" mb-3 text-primary-600">{description}</p>
      ) : undefined}
      {children}
    </div>
  );
};
