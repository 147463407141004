import { ListPageQueriesQueryParams } from '@/api/openapiComponents';

export const convertData = <T>(data?: { current: T[]; previous?: T[] }) => {
  return data?.current.map((item) => {
    return {
      current: item,
      previous: data.previous?.find((prev) => prev.page === item.page),
    };
  });
};

export const calculateGrowth = <T>(
  data: {
    current: T;
    previous?: T;
  },
  dataKey: string,
) => {
  return {
    growth: !data.previous?.[dataKey]
      ? null
      : ((data?.current?.[dataKey] - data?.previous?.[dataKey] ?? 0) /
          data?.previous?.[dataKey]) *
        100,
    difference: data.previous
      ? data?.current?.[dataKey] - data?.previous?.[dataKey]
      : 0,
  };
};

export const sortByGrowth = <T>(
  data: {
    current: T;
    previous?: T;
  }[],
  direction: 'ASC' | 'DESC',
  dataKey: string,
) => {
  return data.toSorted((a, b) => {
    if (direction === 'ASC') {
      return (
        (a.current[dataKey] ?? 0) -
        (a.previous?.[dataKey] ?? 0) -
        ((b.current[dataKey] ?? 0) - (b.previous?.[dataKey] ?? 0))
      );
    } else {
      return (
        (b.current[dataKey] ?? 0) -
        (b.previous?.[dataKey] ?? 0) -
        ((a.current[dataKey] ?? 0) - (a.previous?.[dataKey] ?? 0))
      );
    }
  });
};
