type Props = {
  succeeded: number;
  failed: number;
  total: number;
};

export const DualProgressBar = ({ failed, succeeded, total }: Props) => {
  return (
    <div className="mt-6 flex h-1 flex-shrink-0 bg-gray-100">
      <div
        style={{
          width: `${(succeeded / total) * 100}%`,
        }}
        className="h-full bg-green transition-all"
      />
      <div
        style={{
          width: `${(failed / total) * 100}%`,
        }}
        className="h-full bg-red transition-all"
      />
    </div>
  );
};
