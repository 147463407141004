import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { AddKeywordsToListDialog } from '@/Pages/components';
import { useNavigate } from '@tanstack/react-router';
import { ListPlus, MoreVertical, Plus, Stars } from 'lucide-react';
import { useState } from 'react';

type Props = {
  keyword: string;
};

export const KeywordResearchActions = ({ keyword }: Props) => {
  const navigate = useNavigate();

  const [keywordToBeAddedToList, setKeywordToBeAddedToList] =
    useState<string>();

  return (
    <>
      <AddKeywordsToListDialog
        keyword={keywordToBeAddedToList}
        onClose={() => setKeywordToBeAddedToList(undefined)}
      />
      <PopoverMenu
        trigger={<IconButton icon={MoreVertical} />}
        items={[
          {
            prependIcon: Plus,
            title: 'Create new Content',
            onClick: () => {
              navigate({
                to: '/create-content/blank',
                search: (prev) => ({ ...prev, keyword: keyword }),
              });
            },
          },
          {
            prependIcon: Stars,
            title: 'Create AI draft',
            onClick: () => {
              navigate({
                to: '/create-content/ai-generated',
                search: (prev) => ({ ...prev, keyword: keyword }),
              });
            },
          },
          {
            prependIcon: ListPlus,
            title: 'Add to list',
            onClick: () => setKeywordToBeAddedToList(keyword),
          },
        ]}
      />
    </>
  );
};
