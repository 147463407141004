import { validateTanStackSearch } from '@/utils';
import { createRoute, redirect } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  isAuthenticated,
  hasProject,
  hasActiveSubscription,
} from '../Router/guards';
import { BrandVoice } from './BrandVoice';
import { Connections } from './Connections/Connections';
import { CustomFields } from './CustomFields/CustomFields';
import EditProject from './EditProject/EditProject';
import { CustomFieldsTable } from './CustomFields/Pages/CustomFieldsTable/CustomFieldsTable';
import { ReorderCustomFields } from './CustomFields/Pages/ReorderCustomFields/ReorderCustomFields';
import { Settings } from './Settings';
import { MapCustomFields } from './CustomFields/Pages/MapCustomFields/MapCustomFields';

const settingsRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },

  path: '/settings',
  component: () => <Settings />,
});

const editProjectRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/edit-project',
  component: () => <EditProject />,
});

const brandVoiceRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/brand-voice',
  component: () => <BrandVoice />,
});

const connectionsRoute = createRoute({
  getParentRoute: () => settingsRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/connections',
  component: () => <Connections />,
});

const customFieldsManageRoute = createRoute({
  getParentRoute: () => settingsRoute,
  validateSearch: validateTanStackSearch,
  beforeLoad: ({ context }) => {
    guards(context, [
      isAuthenticated,
      hasProject,
      hasActiveSubscription,
      (context) => {
        if (!context.hasFeature('custom-fields')) {
          throw redirect({
            to: '/',
          });
        }
      },
    ]);
  },
  path: '/custom-fields/manage',
  component: () => <CustomFieldsTable />,
});

const customFieldsMappingRoute = createRoute({
  getParentRoute: () => settingsRoute,
  validateSearch: validateTanStackSearch,
  beforeLoad: ({ context }) => {
    guards(context, [
      isAuthenticated,
      hasProject,
      hasActiveSubscription,
      (context) => {
        if (!context.hasFeature('custom-fields')) {
          throw redirect({
            to: '/',
          });
        }
      },
    ]);
  },
  path: '/custom-fields/mapping',
  component: () => <MapCustomFields />,
});

const customFieldsReorderRoute = createRoute({
  getParentRoute: () => settingsRoute,
  validateSearch: validateTanStackSearch,
  beforeLoad: ({ context }) => {
    guards(context, [
      isAuthenticated,
      hasProject,
      hasActiveSubscription,
      (context) => {
        if (!context.hasFeature('custom-fields')) {
          throw redirect({
            to: '/',
          });
        }
      },
    ]);
  },
  path: '/custom-fields/reorder',
  component: () => <ReorderCustomFields />,
});

export const routes = [
  settingsRoute.addChildren([
    editProjectRoute,
    brandVoiceRoute,
    connectionsRoute,
    customFieldsManageRoute,
    customFieldsMappingRoute,
    customFieldsReorderRoute,
  ]),
];
