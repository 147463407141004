import React from 'react';
import { Button, ButtonProps } from '../Button';
import { LucideProps } from 'lucide-react';
import { Tooltip, TooltipProps } from '../Tooltip';

export type IconButtonProps = {
  tooltip?: Omit<TooltipProps, 'children'>;
  icon: React.ComponentType<LucideProps>;
} & Omit<
  ButtonProps,
  | 'title'
  | 'fullwidth'
  | 'prependIcon'
  | 'appendIcon'
  | 'alignment'
  | 'round'
  | 'fixedWidth'
>;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ tooltip, ...props }: IconButtonProps, ref) => {
    const { icon, variant = 'ghost', color = 'primary' } = props;

    const button = (
      <Button
        ref={ref}
        round
        prependIcon={icon}
        variant={variant}
        color={color}
        {...props}
      />
    );

    return tooltip ? <Tooltip {...tooltip}>{button}</Tooltip> : button;
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
