import * as Table from '@/Components/Table';
import { ValueWithGrowth } from './components/ValueWIthGrowth';
import { GrowthValueHeader } from './components/GrowthValueHeader';
import { useListPageQueries } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { calculateGrowth, convertData, sortByGrowth } from './util';
import { CellWithBar } from './components/CellWithBar';
import PageContainer from '@/Components/PageContainer';
import { removeDomain, removeProtocol } from '@/utils';
import { useParams } from '@tanstack/react-router';
import { Menus } from '@/Components/Menus';
import { useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsGraph } from './components/AnalyticsGraph';

export const PageDetails = () => {
  const appState = useAppStore();

  const params = useParams({ from: '/page-analytics/page/$pageId' });
  const [graphState, setGraphState] = useGraphState();
  const [growthSorting, setGrowthSorting] = useGrowthSorting('pagedetails');

  const [pageNumber, setPageNumber] = useState(1);

  const keywordsQuery = useListPageQueries({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      page: params.pageId,
      range: graphState.range,
      granularity: graphState.granularity,
    },
  });

  const data = convertData(keywordsQuery.data?.data.top_queries) ?? [];
  const mostClicks =
    data.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]?.current
      .clicks ?? 0;

  return (
    <Menus>
      <PageContainer title={`Page: ${removeDomain(params.pageId)}`} canGoBack>
        <AnalyticsGraph
          onStateChange={setGraphState}
          state={graphState}
          data={keywordsQuery.data?.data.graph}
          metrics={keywordsQuery.data?.data.metrics}
        />
        <Table.Root
          isLoading={keywordsQuery.isLoading}
          skeletonLoaders={20}
          items={(growthSorting === 'popular'
            ? data
            : sortByGrowth(
                data ?? [],
                growthSorting === 'rising' ? 'DESC' : 'ASC',
                'clicks',
              )
          )?.slice((pageNumber - 1) * 20, pageNumber * 20)}
          columns={[
            {
              heading: 'Keyword',
              expanded: true,
              render: (item) => (
                <CellWithBar
                  value={item.current.clicks}
                  topValue={mostClicks}
                  name={item.current.query}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Clicks" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  responsive={false}
                  value={item.current.clicks}
                  growth={calculateGrowth(item, 'clicks')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Impressions" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  responsive={false}
                  value={item.current.impressions}
                  growth={calculateGrowth(item, 'impressions')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="CTR" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  responsive={false}
                  value={(item.current.ctr * 100).toFixed(1)}
                  growth={calculateGrowth(item, 'ctr')}
                />
              ),
            },
            {
              heading: <GrowthValueHeader heading="Position" />,
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  responsive={false}
                  value={item.current.position.toFixed(1)}
                  growth={calculateGrowth(item, 'position')}
                />
              ),
            },
          ]}
        >
          <Table.Header>
            <h1 className="text-lg font-semibold">Keywords</h1>
            <ToggleButton
              value={growthSorting}
              onChange={setGrowthSorting}
              options={[
                {
                  displayName: 'Popular',
                  value: 'popular',
                },
                {
                  displayName: 'Rising',
                  value: 'rising',
                },
                {
                  displayName: 'Falling',
                  value: 'falling',
                },
              ]}
            />
          </Table.Header>
          <Table.Footer>
            <Pagination
              currentPage={pageNumber}
              lastPage={
                Math.floor(
                  (keywordsQuery.data?.data.top_queries.current.length ?? 0) /
                    20,
                ) ?? 1
              }
              setCurrentPage={setPageNumber}
            />
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
};
