import {
  useCreatePageGroup,
  useTestFilters,
  useUpdatePageGroup,
} from '@/api/openapiComponents';
import {
  AnalyticsGroupConditionResource,
  AnalyticsGroupResource,
  CreateAnalyticsGroupRequest,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import * as Table from '@/Components/Table';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useDebounce } from '@/Hooks/useDebounce';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { removeDomain } from '@/utils';
import { Plus, Trash, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  existingGroup?: AnalyticsGroupResource;

  onClose: () => void;
  onSuccess: () => void;
};

export const EditGroupDialog = ({
  isOpen,
  onClose,
  existingGroup,
  onSuccess,
}: Props) => {
  const appState = useAppStore();

  const [rules, setRules] = useState<AnalyticsGroupConditionResource[]>([]);
  const [nameInput, setNameInput] = useState('');

  const createGroupMutation = useCreatePageGroup();
  const updateGroupMutation = useUpdatePageGroup();
  const testMutation = useTestFilters();

  const handleTestFilters = () => {
    testMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: {
        filters: [
          {
            dimension: 'country',
            operator: 'equals',
            expression: 'USA',
          },
          ...rules.map((rule) => ({
            dimension: 'page' as const,
            operator: rule.operator,
            expression: rule.expression,
          })),
        ],
      },
    });
  };

  useDebounce(handleTestFilters, [rules]);

  useEffect(() => {
    testMutation.reset();
    if (existingGroup?.conditions) {
      setNameInput(existingGroup.name);
      setRules(existingGroup.conditions);
    } else {
      setNameInput('');
      setRules([
        {
          operator: 'contains',
          expression: '',
        },
      ]);
    }
  }, [existingGroup]);

  const handleSave = () => {
    const body: CreateAnalyticsGroupRequest = {
      name: nameInput,
      conditions: rules,
    };

    if (existingGroup) {
      updateGroupMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            analyticsPageGroup: existingGroup.slug,
          },
          body,
        },
        {
          onSuccess: () => {
            onSuccess();
            onClose();
          },
        },
      );
    } else {
      createGroupMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body,
        },
        {
          onSuccess: () => {
            onSuccess();
            onClose();
          },
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(
    createGroupMutation.error ?? updateGroupMutation.error,
  );

  return (
    <Dialog
      size="lg"
      title={existingGroup ? `Edit ${existingGroup.name}` : 'New page group'}
      isOpen={isOpen}
      handleClose={onClose}
    >
      <InputDecoration label="Name" required>
        <Input
          value={nameInput}
          onChange={setNameInput}
          placeholder="Name of the group..."
        />
      </InputDecoration>
      <Table.Root
        items={rules}
        columns={[
          {
            heading: 'Condition',
            render: (rule, __, _, index) => (
              <div className="relative flex h-full items-center gap-2">
                <div className={`text-xs text-primary `}>
                  {index > 0 ? 'AND' : 'URL'}
                </div>
                <Select
                  onChange={(value) => {
                    setRules((prev) =>
                      prev.map((r, i) =>
                        i === index ? { ...r, operator: value as string } : r,
                      ),
                    );
                  }}
                  value={rule.operator}
                  options={[
                    {
                      title: 'Contains',
                      value: 'contains',
                    },
                    {
                      title: 'Does not contain',
                      value: 'notContains',
                    },
                    {
                      title: 'Equals',
                      value: 'equals',
                    },
                    {
                      title: 'Does not equal',
                      value: 'notEquals',
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            expanded: true,
            heading: 'Value',
            render: (rule, __, _, index) => (
              <Input
                placeholder="Values of the condition..."
                onChange={(value) => {
                  setRules((prev) =>
                    prev.map((r, i) =>
                      i === index ? { ...r, expression: value } : r,
                    ),
                  );
                }}
                value={rule.expression}
              />
            ),
          },
          {
            render: (_, __, ___, index) => (
              <IconButton
                icon={Trash2}
                disabled={rules.length === 1}
                onClick={() =>
                  setRules((prev) => prev.filter((_, i) => index !== i))
                }
              />
            ),
          },
        ]}
      >
        <Table.Footer>
          <div className="-mt-4 flex w-full">
            <Button
              text="Add condition"
              prependIcon={Plus}
              variant="ghost"
              onClick={() =>
                setRules((prev) => [
                  ...prev,
                  { expression: '', operator: 'contains' },
                ])
              }
            />
          </div>
        </Table.Footer>
      </Table.Root>
      <div className="min-h-[450px]">
        {testMutation.data?.data ? (
          <div className="flex flex-col">
            <h1 className="font-bold">Matches</h1>
            {testMutation.data.data.slice(0, 20).map((result) => (
              <a
                href={result.page}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer hover:opacity-60"
                key={result.page}
              >
                {removeDomain(result.page)}
              </a>
            ))}
            {testMutation.data.data.length > 20 && (
              <div>And {testMutation.data.data.length - 20} more...</div>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
          </div>
        )}
      </div>
      {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
      <div className="flex justify-end">
        <Button
          text="Save"
          onClick={handleSave}
          color="secondary"
          isLoading={
            createGroupMutation.isPending || updateGroupMutation.isPending
          }
        />
      </div>
    </Dialog>
  );
};
