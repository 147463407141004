import ToggleButton from '@/Components/ToggleButton';
import { useNavigate } from '@tanstack/react-router';
import { Stars, Network, BarChartHorizontalBig } from 'lucide-react';
import { ReactNode } from 'react';
import { Users } from 'lucide-react';

export type KeywordType =
  | 'ai-keywords'
  | 'matching-terms'
  | 'audience-keywords'
  | 'search-volume';

type Props = {
  children: ReactNode;
  type: KeywordType;
};

export const KeywordResearchHeader = ({ children, type }: Props) => {
  const navigate = useNavigate();

  const handleTypeSelect = (value: KeywordType) => {
    navigate({ to: `/keyword-research/${value}` });
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mx-auto mb-10 mt-8 rounded-lg border border-gray-300 p-0.5">
        <ToggleButton
          className="h-10"
          options={[
            {
              displayName: 'AI KEYWORDS',
              value: 'ai-keywords',
              icon: Stars,
            },
            {
              displayName: 'MATCHING TERMS',
              value: 'matching-terms',
              icon: Network,
            },
            {
              displayName: 'AUDIENCE KEYWORDS',
              value: 'audience-keywords',
              icon: Users,
            },
            {
              displayName: 'SEARCH VOLUME',
              value: 'search-volume',
              icon: BarChartHorizontalBig,
            },
          ]}
          value={type}
          onChange={handleTypeSelect}
        />
      </div>
      <div className="flex w-full flex-col items-center pt-1">{children}</div>
    </div>
  );
};
