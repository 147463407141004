import React from 'react';
import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../lib/utils';
import { ExtendedTElement } from '@/types';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { Icons } from './icons';
import { Divider } from '../components';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';

const listVariants = cva('m-0 ps-6 py-1 text-base', {
  variants: {
    variant: {
      ul: 'list-disc [&_ul]:list-[circle] text-lg [&_ul_ul]:list-[square]',
      ol: 'list-decimal text-lg',
    },
  },
});

const BaseListElement = React.forwardRef(
  (
    {
      className,
      children,
      variant = 'ul',
      ...props
    }: PlateElementProps & VariantProps<typeof listVariants>,
    ref,
  ) => {
    const Element = variant!;
    return (
      <PlateElement
        ref={ref}
        asChild
        className={cn(listVariants({ variant }), className)}
        {...props}
      >
        <Element className="w-full">{children}</Element>
      </PlateElement>
    );
  },
);

export function ListElement({
  className,
  children,
  variant = 'ul',
  ...props
}: PlateElementProps & VariantProps<typeof listVariants>) {
  const nodes = props.editor.children;
  const isOuterList = nodes.find(
    (element) =>
      (element as ExtendedTElement).id ===
      (props.element as ExtendedTElement).id,
  );

  return !isOuterList ? (
    <BaseListElement
      className={className}
      children={children}
      variant={variant}
      {...props}
    />
  ) : (
    <GrabbableListElement
      {...props}
      variant={variant}
      children={children}
      className={className}
    />
  );
}

export function GrabbableListElement({
  className,
  children,
  variant = 'ul',
  element,
  ...props
}: PlateElementProps & VariantProps<typeof listVariants>) {
  const { attributes, listeners, setNodeRef, style, setActivatorNodeRef } =
    usePlateUiDrop(element);
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();
  return (
    <>
      <div {...methods} style={style} className="flex">
        <div
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
          className={invisiblity}
        >
          <Icons.dragHandle className="text-muted-foreground mt-3 h-4 w-4" />
        </div>
        <BaseListElement
          children={children}
          ref={setNodeRef}
          asChild
          element={element}
          variant={variant}
          className={cn(listVariants({ variant }), `${className} ml-4`)}
          {...props}
        />
      </div>
      <Divider id={element.id as string} />
    </>
  );
}
