import React, { useContext } from 'react';
import {
  PlateElement,
  PlateElementProps,
  useEditorState,
} from '@udecode/plate-common';

import { cn } from '../lib/utils';
import { Icons } from './icons';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { Divider } from './divider';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';
import { IsTableContext } from './table-element';
import FloatingAiInput from './floating-ai-input';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Sparkles } from 'lucide-react';
import {
  FloatingAiInputType,
  BlockType,
  Generating,
  NodeTypesEnum,
} from '@/types';
import { useDocumentInfoContext } from '../contexts/DocumentInfoContext';
import { usePostAutoGenerateMutation } from '@/Components/Utils/v2/api';
import { useFocused } from 'slate-react';
import { serializeToMarkdown } from '../lib/serialize';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { getStartOfDocument } from '@/Pages/Document/utils';
import { useGetDocument } from '@/api/openapiComponents';
import { WriteMorePromptType } from '@/api/openapiSchemas';

const ParagraphElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, element, ...props }: PlateElementProps, _) => {
  const isTable = useContext(IsTableContext);
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();
  const { attributes, listeners, setNodeRef, style, setActivatorNodeRef } =
    usePlateUiDrop(element);
  const editor = useEditorState();
  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });
  const { projectId, documentId } = useDocumentInfoContext();
  const editorIsFocussed = useFocused();
  const { data } = useGetDocument(
    {
      pathParams: {
        document: documentId,
        project: projectId,
      },
    },
    {
      staleTime: 10000,
    },
  );
  const { mutate: startWriteMore } = usePostAutoGenerateMutation(
    props.editor,
    projectId,
    documentId,
  );

  const client = useQueryClient();

  const { data: floatingAiInputData } = useQuery<FloatingAiInputType>({
    queryKey: ['FloatingAiInput'],
  });

  const text = element.children.reduce((prev, curr: any) => {
    if (curr.type === NodeTypesEnum.A) {
      return (
        prev +
        curr.children.reduce((prev: any, curr: any) => {
          return prev + curr.text, '';
        })
      );
    }
    return prev + curr.text;
  }, '');

  const isSelected = () => {
    const currentElements = props.editor.getFragment();
    if (
      currentElements.length === 1 &&
      currentElements[0].type === NodeTypesEnum.P &&
      currentElements[0].id === element.id
    ) {
      return true;
    }
    return false;
  };

  const isOpenOnThisLine = floatingAiInputData
    ? floatingAiInputData.id === element.id
    : false;

  const shouldShowPlaceHolder = () => {
    if (isGenerating) {
      return '';
    }
    if (!isTable) {
      if (isOpenOnThisLine) {
        return '';
      }
      return props.nodeProps!.placeholder;
    }
    return '';
  };

  return (
    <>
      <PlateElement
        ref={setNodeRef}
        style={style}
        contentEditable={
          floatingAiInputData
            ? floatingAiInputData.id === element.id
              ? false
              : undefined
            : undefined
        }
        element={element}
        className={cn('relative m-0 px-0 py-1', className)}
        {...methods}
        {...props}
        nodeProps={{
          ...props.nodeProps,
          placeHolder: shouldShowPlaceHolder(),
        }}
      >
        <FloatingAiInput
          key={(element as any).id + isOpenOnThisLine}
          handlePrompt={(item) => {
            if (floatingAiInputData?.selection) {
              const anchor = floatingAiInputData.selection.anchor;
              const startPosition = { focus: anchor, anchor: anchor };
              const startPositionOfDocument = getStartOfDocument(
                editor.children[0] as BlockType,
              );
              const endPosition = {
                focus: {
                  offset: 0,
                  path: startPositionOfDocument,
                },
                anchor: { offset: 0, path: startPositionOfDocument },
              };

              const result = props.editor.range(startPosition, endPosition);
              const value = props.editor.fragment(result);

              const content =
                '#' + data?.data.title + '\n\n' + serializeToMarkdown(value);
              if (item.type === 'custom') {
                startWriteMore({
                  pathParams: {
                    document: documentId,
                    project: projectId,
                  },
                  body: {
                    content,
                    type: 'custom',
                    custom_prompt: item.title,
                  },
                });
              } else {
                startWriteMore({
                  pathParams: {
                    document: documentId,
                    project: projectId,
                  },
                  body: {
                    content,
                    type: item.type as WriteMorePromptType,
                  },
                });
              }
            }
          }}
          editor={props.editor}
          isOpen={isOpenOnThisLine}
          handleClose={(reselect) => {
            client.setQueryData(
              ['FloatingAiInput'],
              (prev) => prev && { ...prev, id: 0 },
            );
            setTimeout(() => {
              if (floatingAiInputData?.selection && reselect) {
                props.editor.select(floatingAiInputData?.selection);
              }
            }, 50);
          }}
        />
        <div className="flex">
          {!isTable ? (
            text !== '' ? (
              <div
                className={invisiblity}
                ref={setActivatorNodeRef}
                {...attributes}
                {...listeners}
              >
                <Icons.dragHandle className="text-muted-foreground mt-[0.3rem] h-4 w-4" />
              </div>
            ) : (
              <div
                className={
                  (!isGenerating &&
                    editorIsFocussed &&
                    floatingAiInputData?.id === element.id) ||
                  (!isGenerating && editorIsFocussed && isSelected())
                    ? ''
                    : 'opacity-0'
                }
              >
                <div className="-ml-1">
                  <IconButton
                    icon={Sparkles}
                    size="sm"
                    dense
                    disabled={!isSelected()}
                    color="secondary"
                    onClick={() => {
                      client.setQueryData(['FloatingAiInput'], () => ({
                        selection: editor.selection,
                        id: element.id,
                      }));
                    }}
                  />
                </div>
              </div>
            )
          ) : null}

          <p
            className={`${!isTable ? 'mb-[0.65rem]' : ''} pl-1 text-lg leading-7`}
          >
            {children}
          </p>
        </div>
      </PlateElement>
      {!isTable && <Divider id={element.id as string} />}
    </>
  );
});

ParagraphElement.displayName = 'ParagraphElement';

export { ParagraphElement };
