import { useChangeKeyword } from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { TextSearch } from 'lucide-react';
import { useState } from 'react';

type Props = {
  document: DocumentResource;
  onSetKeyword: () => void;
};

export const NoKeywordPrompt = ({ onSetKeyword, document }: Props) => {
  const appState = useAppStore();

  const [keywordInput, setKeywordInput] = useState('');

  const changeKeywordMutation = useChangeKeyword();

  const errorHelper = new ErrorHelper(changeKeywordMutation.error as any);

  return (
    <form
      className="mt-12 flex w-[28rem] flex-col items-center gap-2 px-16"
      onSubmit={(e) => {
        e.preventDefault();
        changeKeywordMutation.mutate(
          {
            pathParams: {
              project: appState.currentProject!.id,
              document: document.id,
            },
            body: {
              keyword: keywordInput,
            },
          },
          {
            onSuccess: () => {
              onSetKeyword();
            },
          },
        );
      }}
    >
      <TextSearch size={48} className="text-primary-300" />
      <p className="font-bold">Add target keyword</p>
      <p className="mb-4 text-primary-400">
        Get SEO Score and competitor insights.
      </p>
      <Input
        fullWidth
        value={keywordInput}
        onChange={setKeywordInput}
        placeholder="Enter keyword..."
      />
      {errorHelper.isError() && (
        <div className="w-full">
          <ErrorAlert title={errorHelper.message()} />
        </div>
      )}
      <Button
        fullWidth
        text="Analyze now"
        color="secondary"
        isLoading={
          changeKeywordMutation.isPending || changeKeywordMutation.isSuccess
        }
        type="submit"
      />
    </form>
  );
};
