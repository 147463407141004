import { CurrentSlideOver } from '@/types';
import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, ExternalLink, Stars } from 'lucide-react';
import { MetaFieldDecoration } from './MetaFieldDecoration';
import { UrlInput } from './PlateEditor/components/url-input';
import { Input } from '@/Components/v2/Input/Input';
import { useMetaDescriptionState, useMetaTitleState } from '../hooks';
import { Button } from '@/Components/v2/Button';
import { useDocumentStore } from '../stores';
import {
  useAnalyzePage,
  useDocumentShopifyImport,
  useGetCmsConnection,
} from '@/api/openapiComponents';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { AnimatedCollapsibleContainer } from '@/Components/AnimatedCollapsibleContainer';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useEditor } from '@/Components/Utils/v2/api';
import { getInitialPlateJSValue } from '../utils';
import { useShallow } from 'zustand/react/shallow';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { capitalize } from '@/utils';
import { DocumentResource } from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { Icon } from '@/Components/v2/Icon';
import { faShopify } from '@fortawesome/free-brands-svg-icons';

type Props = {
  includeReportElements?: boolean;
  document: DocumentResource;
  onOpenMetaTitle: () => void;
  onOpenMetaDescription: () => void;
  onCloseSlideOver: () => void;
  currentSlideOver?: CurrentSlideOver;
};

export const CollapsibleMetadata = ({
  currentSlideOver,
  document,
  onCloseSlideOver,
  onOpenMetaDescription,
  onOpenMetaTitle,
}: Props) => {
  const appState = useAppStore();
  const { hasFeature } = useFeatureFlagging();
  const editor = useEditor();
  const client = useQueryClient();

  const { setHeading } = useDocumentStore(
    useShallow(({ setHeading }) => ({
      setHeading,
    })),
  );

  const [isOpen, setIsOpen] = useState(true);
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const [metaDescription, setMetaDescription] = useMetaDescriptionState();

  const [confirmImportFor, setConfirmImportFor] = useState<
    'metadata' | 'content'
  >();

  const projectUrl = useDocumentStore((state) => state.projectUrl);
  const importFromShopifyMutation = useDocumentShopifyImport();
  const analyzeMutation = useAnalyzePage();

  const cmsConnectionQuery = useGetCmsConnection(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      retry: false,
    },
  );

  useEffect(() => {
    if (
      currentSlideOver === 'meta-title' ||
      currentSlideOver === 'meta-description'
    ) {
      setIsOpen(true);
    }
  }, [currentSlideOver]);

  const handleImport = (type?: 'metadata' | 'content') => {
    const importType = type ?? confirmImportFor;
    if (importType === 'metadata') {
      return analyzeMutation.mutate(
        {
          body: {
            url: projectUrl!.url,
          },
        },
        {
          onSuccess: (data) => {
            setMetaTitle(data.data.meta_title);
            setMetaDescription(data.data.meta_description);
            setConfirmImportFor(undefined);
          },
        },
      );
    }
    if (importType === 'content' && !shouldImportFromCms) {
      return analyzeMutation.mutate(
        {
          body: {
            url: projectUrl!.url,
          },
        },
        {
          onSuccess: (data) => {
            setConfirmImportFor(undefined);
            editor?.children.map(() => editor?.delete({ at: [0] }));
            editor?.insertNodes(getInitialPlateJSValue(data.data.content));
          },
        },
      );
    }
    if (importType === 'content' && shouldImportFromCms) {
      return importFromShopifyMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            document: document.id,
          },
        },
        {
          onSuccess: (data) => {
            setConfirmImportFor(undefined);
            setHeading(data.data.title);
            setMetaDescription(data.data.meta_description);
            setMetaTitle(data.data.meta_title ?? '');
            editor?.children.map(() => editor?.delete({ at: [0] }));
            editor?.insertNodes(getInitialPlateJSValue(data.data.text));
            client.setQueriesData(
              {
                predicate: (query) => query.queryKey.includes('getDocument'),
              },
              () => data,
            );
          },
        },
      );
    }
  };

  const hasCmsConnection = cmsConnectionQuery.data?.data.state === 'active';
  const shouldImportFromCms =
    hasCmsConnection &&
    (projectUrl?.cms.has_reference || !projectUrl) &&
    hasFeature('shopify-cms-integration');

  const metaTitleCompetitiveScore =
    document.document_report?.meta_title_competitiveness_score;

  return (
    <div>
      <ConfirmDialog
        isOpen={!!confirmImportFor}
        onClose={() => setConfirmImportFor(undefined)}
        title={`Import ${confirmImportFor} from URL`}
        description={[
          `Are you sure you want to import and overwrite ${confirmImportFor} from ${capitalize(shouldImportFromCms ? (cmsConnectionQuery.data?.data?.type ?? 'URL') : 'URL')}?`,
        ]}
        confirmText="Import"
        onConfirm={() => handleImport()}
        isLoading={
          analyzeMutation.isPending || importFromShopifyMutation.isPending
        }
      />
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex cursor-pointer items-center gap-2 p-2 text-lg text-primary-600 hover:bg-primary-50"
      >
        URL, title and meta description
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      <AnimatedCollapsibleContainer isOpen={isOpen}>
        <div className="flex flex-col gap-3 pt-6">
          <MetaFieldDecoration label="URL" centered>
            <UrlInput document={document} />
          </MetaFieldDecoration>
          <MetaFieldDecoration label="Title" centered>
            <Input
              fullWidth
              value={metaTitle}
              onChange={(value) => setMetaTitle(value)}
              disabled={
                analyzeMutation.isPending &&
                metaDescription.length === 0 &&
                metaTitle.length === 0
              }
              onFocus={() => {
                if (metaTitle.length === 0) {
                  onOpenMetaTitle();
                }
              }}
              placeholder="Add your title"
              appendIcon={
                document.keyword && (
                  <IconButton
                    icon={Stars}
                    onClick={() => {
                      if (currentSlideOver === 'meta-title') {
                        onCloseSlideOver();
                      } else {
                        onOpenMetaTitle();
                      }
                    }}
                  />
                )
              }
            />
          </MetaFieldDecoration>
          <MetaFieldDecoration label="Meta description" centered>
            <TextArea
              fullWidth
              value={metaDescription}
              onChange={(value) => setMetaDescription(value)}
              disabled={
                analyzeMutation.isPending &&
                metaDescription.length === 0 &&
                metaTitle.length === 0
              }
              onFocus={() => {
                if (metaDescription.length === 0) {
                  onOpenMetaDescription();
                }
              }}
              placeholder="Add your meta description"
              appendIcon={
                document.keyword && (
                  <div className="">
                    <IconButton
                      icon={Stars}
                      onClick={() => {
                        if (currentSlideOver === 'meta-description') {
                          onCloseSlideOver();
                        } else {
                          onOpenMetaDescription();
                        }
                      }}
                    />
                  </div>
                )
              }
            />
          </MetaFieldDecoration>
          <div className="flex items-center justify-end pr-8">
            {!shouldImportFromCms && (
              <Button
                variant="ghost"
                text="Fetch metadata from URL"
                textCase="normal-case"
                disabled={!projectUrl}
                isLoading={
                  analyzeMutation.isPending &&
                  confirmImportFor === undefined &&
                  metaDescription.length === 0 &&
                  metaTitle.length === 0
                }
                onClick={() => {
                  if (metaDescription.length > 0 || metaTitle.length > 0) {
                    setConfirmImportFor('metadata');
                  } else {
                    handleImport('metadata');
                  }
                }}
              />
            )}
            <Button
              variant="ghost"
              text={`Import content ${shouldImportFromCms ? 'and metadata ' : ''}from ${capitalize(shouldImportFromCms ? (cmsConnectionQuery.data?.data?.type ?? 'URL') : 'URL')}`}
              textCase="normal-case"
              onClick={() => setConfirmImportFor('content')}
              disabled={!projectUrl}
            />
          </div>
        </div>
      </AnimatedCollapsibleContainer>
    </div>
  );
};
