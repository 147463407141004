import {
  useDocumentChatClear,
  useDocumentsChatMessagesDestroy,
  useDocumentsChatMessagesIndex,
  useDocumentsChatMessagesStore,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { BlockSelection, isHumanMessage, Message } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useStartMessageStreamMutation } from '@/Components/Utils/v2/api';
import { queryKeyFn } from '@/api/openapiContext';
import { useScrollIntoView } from '../ChatTab/hooks/useScrollIntoView';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { DragOverlay } from '@dnd-kit/core';
import { createPortal } from 'react-dom';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useGetDraggableState } from '@/Hooks/useGetDraggableState';
import { HumanMessageV2 } from '../ChatTab/components/HumanMessageV2';
import {
  AIMessageOverlayV2,
  AIMessageV2,
} from '../ChatTab/components/AIMessageV2';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ArrowDown } from 'lucide-react';
import { GetStartedChatBubbleText } from '../ChatTab/components';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { MessageInput } from './components/MessageInput';

type Props = {
  document: DocumentResource;
  selection: BlockSelection | null;
  deselectSelection: () => void;
  disabled?: boolean;
  initialMessage?: ReactNode;
};

export const Chat = ({
  document,
  deselectSelection,
  selection,
  disabled,
  initialMessage,
}: Props) => {
  const client = useQueryClient();

  const { showSnackbar } = useSnackbar();
  const { draggableState } = useGetDraggableState();

  const [text, setText] = useState('');

  const variables = {
    pathParams: { document: document.id },
  };

  const queryKey = queryKeyFn({
    path: '/documents/{document}/chat-messages',
    operationId: 'documentsChatMessagesIndex',
    variables,
  });

  const { mutateAsync: sendMessageAsync, isPending: isSendingMessage } =
    useDocumentsChatMessagesStore();
  const { data: chatMessages, isLoading } =
    useDocumentsChatMessagesIndex(variables);
  const deleteChatMessageMutation = useDocumentsChatMessagesDestroy();
  const { mutate: clearChat, isPending: isClearingChat } = useDocumentChatClear(
    {
      onMutate: () => {
        client.setQueryData(queryKey, () => ({ data: [] }));
      },
      onSuccess: () => {
        client.invalidateQueries({ queryKey });
      },
    },
  );

  const {
    handleStartStream,
    data: { isLoadingMessage, content, isStreamingMessage },
    handleSetLoadingMessage,
  } = useStartMessageStreamMutation(document.id, queryKey);

  const handleRefreshMessage = (id: number) => {
    handleSetLoadingMessage(true);
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleStartStream();
          });
        },
      },
    );
  };

  const { handleScrollIntoView, isInView, scrollToDiv } =
    useScrollIntoView(true);

  const handleSendMessage = async (predefinedText?: string, insert = true) => {
    handleScrollIntoView();
    if (insert) {
      if (selection && selection.selected.showInChat) {
        client.setQueriesData({ queryKey }, (prev) => {
          return {
            ...prev,
            data: [
              {
                content: selection.selected.text,
                display_type: 'SELECTION',
                is_moderated: false,
                id: -1,
                role: 'user',
              },
              ...prev.data,
            ],
          };
        });
      }
      client.setQueriesData({ queryKey }, (prev) => {
        return {
          ...prev,
          data: [
            {
              content: predefinedText || text,
              display_type: 'MESSAGE',
              is_moderated: false,
              id: 0,
              role: 'user',
            },
            ...prev.data,
          ],
        };
      });
    }
    if (selection && selection.selected.showInChat) {
      try {
        await sendMessageAsync({
          pathParams: { document: document.id },
          body: {
            content: selection.selected.text,
            display_type: 'SELECTION',
          },
        });
        if (!predefinedText) {
          if (!text) return;
          setText('');
        }
        deselectSelection();
      } catch (error: any) {
        showSnackbar({
          alignment: 'bottom',
          message: error.message,
          color: 'red',
        });
        return;
      }
    }
    handleSetLoadingMessage(true);

    try {
      await sendMessageAsync({
        pathParams: { document: document.id },
        body: {
          content: predefinedText || text,
          display_type: 'MESSAGE',
        },
      });
      if (!predefinedText) {
        if (!text) return;
        setText('');
      }
      handleStartStream();
    } catch (error: any) {
      showSnackbar({
        alignment: 'top',
        message: error.message,
        color: 'red',
      });
      handleSetLoadingMessage(false);
    }
  };

  const handleSubmitEditedMessage = (id: number, text: string) => {
    client.setQueriesData({ queryKey }, (prev) => {
      const messageIndex = prev.data.findIndex((message) => message.id === id);

      return {
        ...prev,
        data: [
          {
            ...prev.data[messageIndex],
            content: text,
          },
          ...prev.data.slice(messageIndex + 1),
        ],
      };
    });

    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          handleSendMessage(text, false);
        },
      },
    );
  };

  const displayMessages = () => {
    let messages: (string | Message)[] = [];
    if (!selection && !chatMessages?.data) {
      return;
    }

    if (chatMessages?.data) {
      messages = [...messages, ...(chatMessages?.data ?? [])];
    }

    const components: ReactNode[] = [];

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (typeof message === 'string' || message.display_type === 'SELECTION') {
        continue;
      }
      if (isHumanMessage(message)) {
        let selectedText: string | undefined = undefined;

        if (
          typeof messages[i + 1] === 'string' ||
          messages[i + 1]?.display_type === 'SELECTION'
        ) {
          selectedText = messages[i + 1]?.content ?? messages[i + 1];
        }

        components.push(
          <HumanMessageV2
            key={message.id}
            id={message.id}
            isLoading={
              message.id === 0 &&
              isLoadingMessage &&
              deleteChatMessageMutation.isPending
            }
            selectedText={selectedText}
            disabled={
              isStreamingMessage ||
              !!disabled ||
              deleteChatMessageMutation.isPending
            }
            text={message.content}
            onSubmitEdit={handleSubmitEditedMessage}
          />,
        );
      } else {
        components.push(
          <AIMessageV2
            hasSelection={!!selection}
            isLastAIMessage={i === 0}
            key={message.id}
            text={message.content}
            id={message.id}
            handleRefreshMessage={handleRefreshMessage}
          />,
        );
      }
    }
    return components;
  };

  return (
    <>
      <div className="relative flex h-full w-full flex-col justify-center">
        {chatMessages?.data.length === 0 && <GetStartedChatBubbleText />}
        <div
          style={{ scrollbarGutter: 'stable both-edges' }}
          className="mx-auto flex w-full flex-grow flex-col-reverse items-end gap-4 overflow-y-scroll"
        >
          <div className="mx-auto flex w-full max-w-2xl flex-col-reverse items-end gap-4 px-6">
            <div ref={scrollToDiv} />
            {isLoading && (
              <div className="flex flex-col gap-2">
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
                <SkeletonLoader height="md" />
              </div>
            )}
            {(!!content || isLoadingMessage) && (
              <AIMessageOverlayV2 text={content ?? ''} id={0} />
            )}
            {displayMessages()}
            {initialMessage && (
              <div className="mb-8 w-full">{initialMessage}</div>
            )}
          </div>
        </div>
        <div className="relative h-0 w-full">
          <div
            className={`absolute bottom-4 right-4 z-40 animate-bounce rounded-3xl ${
              isInView ? 'hidden' : 'block'
            }`}
          >
            <IconButton
              icon={ArrowDown}
              onClick={handleScrollIntoView}
              color="primary"
            />
          </div>
        </div>
        <div
          className="flex justify-center"
          style={{ scrollbarGutter: 'stable both-edges' }}
        >
          <div className="flex w-full max-w-2xl flex-shrink-0 items-center justify-center overflow-hidden px-6 pb-8 pt-4">
            <MessageInput
              isClearingChat={isClearingChat}
              isSendingMessage={isSendingMessage}
              value={text}
              onChange={setText}
              onSubmit={handleSendMessage}
              selection={selection}
            />
          </div>
        </div>
        {chatMessages?.data &&
          draggableState &&
          createPortal(
            <DragOverlay adjustScale={false} zIndex={2} dropAnimation={null}>
              {chatMessages?.data && draggableState && (
                <AIMessageOverlayV2 text={draggableState.activeElement.text} />
              )}
            </DragOverlay>,
            window.document.body,
          )}
      </div>
    </>
  );
};
