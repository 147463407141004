import { LucideProps } from 'lucide-react';
import { ButtonColors, ButtonSizes } from './v2/Button';
import { isComponentFunction } from './v2/utils';

type Props = {
  icon: React.ComponentType<LucideProps>;
  value: boolean;
  onClick: () => void;
  disabled?: boolean;
  color: ButtonColors;
  dense?: boolean;
  isLoading?: boolean;
  size?: ButtonSizes;
};

export const IconToggle = ({
  icon,
  dense,
  value,
  onClick,
  color,
  disabled,
  isLoading,
  size = 'base',
}: Props) => {
  const getIconSize = () => {
    switch (size) {
      case 'xs':
        return 'h-3 w-3';
      case 'sm':
        return 'h-4 w-4';
      case 'base':
        return 'h-5 w-5';
      case 'lg':
        return 'h-6 w-6';
      case 'xl':
        return 'h-7 w-7';
      default:
        return 'h-5 w-5';
    }
  };

  const getColor = () => {
    const colors = {
      primary: `${value ? 'bg-primary border-primary text-white' : 'border-primary-400 text-primary-400'}`,
      secondary: `${value ? 'bg-secondary border-secondary text-white' : 'border-primary-400 text-primary-400'}`,
      green: `${value ? 'bg-green border-green text-white' : 'border-primary-400 text-primary-400'}`,
      red: `${value ? 'bg-red border-red text-white' : 'border-primary-400 text-primary-400'}`,
      blue: `${value ? 'bg-blue border-blue text-white' : 'border-primary-400 text-primary-400'}`,
      gray: `${value ? 'bg-gray border-gray text-white' : 'border-primary-400 text-primary-400'}`,
      white: `${value ? 'bg-white border-white text-white' : 'border-primary-400 text-primary-400'}`,
      yellow: `${value ? 'bg-yellow border-yellow text-white' : 'border-primary-400 text-primary-400'}`,
      orange: `${value ? 'bg-orange border-orange text-white' : 'border-primary-400 text-primary-400'}`,
      purple: `${value ? 'bg-purple border-purple text-white' : 'border-primary-400 text-primary-400'}`,
    };

    return colors[color];
  };

  const textSize = `text-${size}`;

  const Icon = icon;

  return (
    <div
      onClick={onClick}
      className={`flex aspect-square items-center justify-center rounded-md border-2 ${dense ? 'p-0.5' : 'p-1.5'} ${getColor()} ${disabled ? 'cursor-not-allowed opacity-60' : `cursor-pointer ${value ? 'hover:opacity-85' : 'hover:opacity-55'}`} `}
    >
      {Icon &&
        (isComponentFunction(Icon) ? (
          <div className={`${isLoading ? `invisible` : `visible`} ${textSize}`}>
            {<Icon className={getIconSize()} />}
          </div>
        ) : (
          Icon
        ))}
    </div>
  );
};
