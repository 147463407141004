import { LucideProps } from 'lucide-react';

type Props = {
  title: string;
  icon: React.ComponentType<LucideProps>;
  description: string;
  onClick: () => void;
};

export const ToolCard = ({ description, icon, title, onClick }: Props) => {
  const Icon = icon;

  return (
    <div
      className="flex aspect-square w-full max-w-72 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg bg-white p-4 shadow hover:opacity-60"
      onClick={onClick}
    >
      <p className=" text-center text-xl font-bold text-primary ">{title}</p>

      <Icon className={`h-[40%] w-[40%] text-primary-400`} />

      <p className="text-center text-xs text-primary">{description}</p>
    </div>
  );
};
