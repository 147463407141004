import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { PlateContent, useEditorState } from '@udecode/plate-common';
import { cva } from 'class-variance-authority';
import {
  cn,
  getDocumentText,
  isEmptyEditor,
  isInsideTableCell,
} from '../lib/utils';
import type { PlateContentProps } from '@udecode/plate-common';
import type { VariantProps } from 'class-variance-authority';
import {
  BlockType,
  CurrentSlideOver,
  FloatingAiInputType,
  Generating,
  InternalLinkingResource,
  LeafType,
  NodeTypesEnum,
} from '@/types';
import {
  useGenerateLiveDocument,
  usePostAutoGenerateMutation,
  useSetEditor,
} from '@/Components/Utils/v2/api';
import { getWordCount, hasNoTextMarked } from '@/Pages/Document/utils';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import diff from 'fast-diff';
import { nextLine } from '@/data/initialPlateJSValue';
import { useSaveChanges } from '@/Pages/Document/hooks';
import {
  DocumentResource,
  UserResource,
  WriteMorePromptType,
} from '@/api/openapiSchemas';
import { Loader } from '@/Components/Loader';
import { EditorRefContext } from '@/Pages/Document/context';
import { EditorTitle } from './editor-title';
import {
  useDocumentStore,
  useInternalLinkingStore,
} from '@/Pages/Document/stores';
import { useBlocker } from '@tanstack/react-router';
import { ReactEditor } from 'slate-react';
import { useShallow } from 'zustand/react/shallow';
import { tracking } from '@/Services/tracking/Tracking';

import { CollapsibleMetadata } from '../../CollapsibleMetadata';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Square } from 'lucide-react';

const editorVariants = cva(
  cn(
    'relative overflow-x-auto whitespace-pre-wrap break-words',
    'min-h-[80px] w-full rounded-md bg-white px-3 py-2  ring-offset-white placeholder:text-slate-500 focus-visible:outline-none dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400',
    '[&_[data-slate-placeholder]]:text-slate-500 [&_[data-slate-placeholder]]:!opacity-100 dark:[&_[data-slate-placeholder]]:text-slate-400',
    '[&_[data-slate-placeholder]]:top-[auto_!important]',
    '[&_strong]:font-bold',
  ),
  {
    variants: {
      variant: {
        outline: 'border border-slate-200 dark:border-slate-800',
        ghost: '',
      },
      focused: {
        true: 'ring-2 ring-slate-950 ring-offset-2 dark:ring-slate-300',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      },
      focusRing: {
        true: 'focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:focus-visible:ring-slate-300',
        false: '',
      },
      size: {
        sm: '',
        md: '',
      },
    },
    defaultVariants: {
      variant: 'outline',
      focusRing: true,
      size: 'sm',
    },
  },
);

export type EditorProps = PlateContentProps &
  VariantProps<typeof editorVariants> & {
    document: DocumentResource;
    projectId: number;
    user: UserResource;
    setCurrentSlideOver: (value?: CurrentSlideOver) => void;
    refetchDocument: () => Promise<void>;
    currentSlideOver: CurrentSlideOver | undefined;
  };

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      projectId,
      className,
      focused,
      focusRing,
      refetchDocument,
      readOnly,
      size,
      variant,
      document,
      user,
      setCurrentSlideOver,
      currentSlideOver,
      ...props
    },
    _,
  ) => {
    const { heading, metaTitle, metaDescription } = useDocumentStore(
      useShallow(({ heading, metaTitle, metaDescription }) => ({
        heading,
        metaTitle,
        metaDescription,
      })),
    );
    const [copied, setCopied] = useState(false);
    const editorFocusRef = useContext(EditorRefContext);
    const formerText = useRef<string | null>(null);
    const { data, handleChangeSentence, handleRemoveInternalLink } =
      useInternalLinkingStore(
        useShallow((state) => ({
          data: state.data,
          handleChangeSentence: state.handleChangeSentence,
          handleRemoveInternalLink: state.handleRemoveInternalLink,
        })),
      );
    const editor = useEditorState();
    const setEditor = useSetEditor();
    const client = useQueryClient();
    const { mutate: startWriteMore, isLoading } = usePostAutoGenerateMutation(
      editor,
      projectId,
      document.id,
    );

    const { isSaving } = useSaveChanges(
      document.id,
      projectId,
      { editor },
      !!isLoading,
    );

    const initialDocumentGeneration = useGenerateLiveDocument(
      document.id,
      projectId,
      editor,
    );
    const onBackRef = useRef<() => void>();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const isAtBottom = useRef(false);

    const { data: isGenerating } = useQuery<Generating>({
      queryKey: ['autoGenerationStream'],
    });
    useBlocker(
      () =>
        'If you leave the page before content generation is finished you will cancel the live content generation',
      isGenerating === 'live',
    );
    const updateSentence = (diffedValue: diff.Diff[], type: 'del' | 'ins') => {
      const firstPart = diffedValue[0][1];
      const operationPart = diffedValue[1][1];
      const secondPart = diffedValue[2][1];

      const linkSuggestions = data.reduce(
        (prev, curr) => {
          if (operationPart.includes(curr.linkResource.sentence)) {
            return { ...prev, deletedItems: [...prev.deletedItems, curr] };
          }
          return { ...prev, remainingItems: [...prev.remainingItems, curr] };
        },
        { deletedItems: [], remainingItems: [] } as {
          deletedItems: InternalLinkingResource[];
          remainingItems: InternalLinkingResource[];
        },
      );
      const remainingItems = linkSuggestions.remainingItems;
      const firstHalf = firstPart.split(/\n|\./);
      const firstPartOfSentence = firstHalf[firstHalf.length - 1];
      const secondHalf = secondPart.split(/\n|\./);
      const secondPartOfSentence = secondHalf[0];

      if (type === 'del') {
        const deletedItems = linkSuggestions.deletedItems;
        deletedItems.forEach((lr) => handleRemoveInternalLink(lr.linkResource));
      }
      const linkingResource = remainingItems.find(
        (lr) =>
          lr.linkResource.sentence
            .trim()
            .includes(firstPartOfSentence.trimStart()) &&
          lr.linkResource.sentence
            .trim()
            .includes(secondPartOfSentence.trimEnd()),
      );
      if (
        linkingResource &&
        firstPartOfSentence !== '' &&
        secondPartOfSentence !== ''
      ) {
        if (type === 'ins') {
          handleChangeSentence(
            linkingResource,
            firstPartOfSentence,
            secondPartOfSentence,
            operationPart,
          );
        } else {
          handleChangeSentence(
            linkingResource,
            firstPartOfSentence,
            secondPartOfSentence,
          );
        }
      }
    };

    const { data: writeMoreIntent } = useQuery<{
      prompt: string;
      type: WriteMorePromptType;
    }>({
      queryKey: ['intent-write-more'],
    });

    useEffect(() => {
      let timer: NodeJS.Timeout | undefined;
      if (copied) {
        timer = setTimeout(() => {
          setCopied(false);
        }, 1000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [copied]);

    useEffect(() => {
      if (writeMoreIntent) {
        if (writeMoreIntent.type === 'answer-question') {
          editor.insertNode({
            type: NodeTypesEnum.H2,
            children: [{ text: writeMoreIntent.prompt }],
          });
          editor.insertNode(nextLine);
        }

        startWriteMore({
          pathParams: {
            document: document.id,
            project: projectId,
          },
          body: {
            type: writeMoreIntent.type,
            custom_prompt: writeMoreIntent.prompt,
          },
        });

        client.setQueryData(['intent-write-more'], null);
      }
    }, [writeMoreIntent]);

    useEffect(() => {
      const value = getDocumentText(editor.children as BlockType[]);
      if (currentSlideOver === 'internal-linking' && formerText.current) {
        const diffedValue = diff(value, formerText.current);
        if (diffedValue.length === 3) {
          if (diffedValue[1][0] === 1) {
            updateSentence(diffedValue, 'del');
          }
          if (diffedValue[1][0] === -1) {
            updateSentence(diffedValue, 'ins');
          }
        }
      }

      formerText.current = value;
    }, [editor.selection]);

    const onLeave = useCallback((e: BeforeUnloadEvent) => {
      const confirmationMessage =
        'If you leave the page before content generation is finished you will cancel the live content generation';

      e.returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }, []);

    useEffect(() => {
      if (isLoading === 'live') {
        // Add warning when leaving page while it is generating
        isAtBottom.current = true;
        window.addEventListener('beforeunload', onLeave);
      }

      return () => {
        window.removeEventListener('beforeunload', onLeave);
      };
    }, [isLoading]);

    useEffect(() => {
      client.setQueryData(['document-project-id'], projectId);
    }, []);

    // Handle live generating documents
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('generate')) {
        isAtBottom.current = true;
        const result = editor.string({
          focus: { offset: 0, path: [0, 0] },
          anchor: { offset: 0, path: [editor.children.length, 0] },
        });

        if (result !== '') {
          // If this is not a new article, dont generate anything
          return;
        }

        // Remove query param from url
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname.split('?')[0],
        );

        editor.select({
          anchor: {
            offset: 0,
            path: [0, 0],
          },
          focus: {
            offset: 0,
            path: [0, 0],
          },
        });
        (window.document.activeElement as HTMLElement)?.blur();
        initialDocumentGeneration.generate({
          templateDocumentId: params.get('document_template')
            ? parseInt(params.get('document_template')!)
            : undefined,
        });

        return () => {
          if (onBackRef.current) {
            onBackRef.current();
          }
          client.setQueryData<Generating>(['autoGenerationStream'], false);
          window.removeEventListener('beforeunload', onLeave);
        };
      }

      return () =>
        client.setQueryData<Generating>(['autoGenerationStream'], false);
    }, []);

    useEffect(() => {
      const result = editor.string({
        focus: { offset: 0, path: [0, 0] },
        anchor: { offset: 0, path: [editor.children.length, 0] },
      });
      setEditor(editor);
      if (result !== '') {
        client.setQueryData<boolean>(['checkWordCount'], true);
      } else {
        client.setQueryData<boolean>(['checkWordCount'], false);
      }
    }, [editor.children]);

    const wordCount = getWordCount(heading, editor);
    useEffect(() => {
      const div = scrollRef.current;
      if (
        isLoading &&
        isAtBottom.current &&
        div &&
        div.scrollTop - div.clientHeight <
          div.scrollHeight - div.clientHeight - 150
      ) {
        div.scrollTo({
          top: div.scrollHeight - div.clientHeight - 150,
          behavior: 'smooth',
        });
      }
    }, [wordCount]);

    const handleWheel = (e: SyntheticEvent<HTMLDivElement>) => {
      const div = scrollRef.current;
      if (!div) {
        return;
      }

      //Scroll up
      if ((e.nativeEvent as unknown as { deltaY: number }).deltaY < 0) {
        isAtBottom.current = false;
      }
      //Scroll down
      else {
        if ((div.scrollTop + div.clientHeight ?? 0) > div.scrollHeight - 200) {
          isAtBottom.current = true;
        }
      }
    };

    const handleTitleFocus = () => {
      if (heading.length === 0 && document.keyword) {
        tracking.event('title_suggestions_opened', {
          click_source: 'editor_input',
        });
        setCurrentSlideOver('title-score');
      }
    };

    useEffect(() => {
      if (isEmptyEditor(editor)) {
        editor.select([0, 0]);
        ReactEditor.focus(editor as ReactEditor);
      }
    }, []);

    return (
      <>
        {isSaving && (
          <Loader className="absolute bottom-2 right-6 top-2 z-50 h-5 w-5 stroke-emerald-400" />
        )}

        <div className="h-full w-full">
          <div
            ref={scrollRef}
            onWheel={handleWheel}
            role="textbox"
            className={`thick-scroll relative flex h-full justify-center overflow-y-scroll bg-white`}
          >
            <div className={`w-full bg-white px-12 pt-12`}>
              <div className="-mr-8 p-5">
                <div className="flex justify-between"></div>
                <CollapsibleMetadata
                  document={document}
                  onOpenMetaDescription={() =>
                    setCurrentSlideOver('meta-description')
                  }
                  refetchDocument={refetchDocument}
                  onCloseSlideOver={() => setCurrentSlideOver(undefined)}
                  onOpenMetaTitle={() => setCurrentSlideOver('meta-title')}
                  currentSlideOver={currentSlideOver}
                />
              </div>
              <EditorTitle
                document={document}
                handleTitleFocus={handleTitleFocus}
                isLoading={isLoading}
              />
              <div className="relative flex min-h-[60%] flex-col justify-between">
                <div
                  className={`absolute z-20 h-[100%] w-[100%] bg-white opacity-20 ${
                    !isLoading ? 'hidden' : 'block'
                  }`}
                />
                <SortableContext
                  items={editor?.children.map((el: any) => el.id) || []}
                  strategy={verticalListSortingStrategy}
                >
                  <PlateContent
                    renderEditable={(props) => (
                      <div ref={editorFocusRef}>{props}</div>
                    )}
                    className={cn(
                      editorVariants({
                        focused,
                        focusRing,
                        size,
                        variant,
                      }),
                      className,
                      'pb-56',
                    )}
                    disableDefaultStyles
                    tabIndex={isLoading ? -1 : undefined}
                    aria-disabled={!!isLoading}
                    onKeyDown={(e) => {
                      if (
                        e.code === 'Space' &&
                        editor.selection &&
                        !isGenerating
                      ) {
                        if (
                          hasNoTextMarked(editor) &&
                          !isInsideTableCell(editor)
                        ) {
                          const { value } = editor
                            .nodes({
                              at: {
                                path: [editor.selection.anchor.path[0]],
                                offset: 0,
                              },
                              match: (node) =>
                                (node as BlockType).type === NodeTypesEnum.P &&
                                (node as BlockType).children.reduce(
                                  (prev, curr) => {
                                    return prev + (curr as LeafType).text;
                                  },
                                  '',
                                ) === '',
                            })
                            .next();

                          if (value) {
                            e.preventDefault();
                            e.stopPropagation();
                            client.setQueryData<FloatingAiInputType>(
                              ['FloatingAiInput'],
                              () => ({
                                id: (value[0] as any).id,
                                selection: editor.selection,
                              }),
                            );
                          }
                        }
                      }
                    }}
                    {...props}
                  />
                </SortableContext>
              </div>
            </div>
          </div>
          {isGenerating === 'write-more' && (
            <div className="sticky bottom-4 z-[51] translate-x-[calc(50%-29px)]">
              <IconButton
                id="write-more-button"
                icon={
                  (
                    <div className="p-2">
                      <Square fill="white" size={28} />
                    </div>
                  ) as any
                }
                size="xl"
                variant="fill"
                color="secondary"
              />
            </div>
          )}

          <div className="sticky bottom-0 left-0 right-0 z-50 pl-4">
            <div className="relative">
              <div className="absolute bottom-5 z-50">
                {document.document_report?.word_count.actual ?? 0}{' '}
                {!(document.document_report?.is_generating ?? true) ? (
                  <>of {document.document_report?.word_count.target} words</>
                ) : (
                  'words'
                )}
              </div>
            </div>
            <div className="relative w-full">
              <div className="absolute bottom-0 left-0 right-4 flex h-32 items-end justify-center overflow-hidden">
                <div className="shadow-chat-top flex w-full justify-center bg-white pb-2">
                  <div className="h-6 w-6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
Editor.displayName = 'Editor';

export { Editor };
