import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from './components/ValueWIthGrowth';
import { Button } from '@/Components/v2/Button';
import { SquarePen } from 'lucide-react';
import { GrowthValueHeader } from './components/GrowthValueHeader';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useGetDashboard } from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { calculateGrowth, convertData, sortByGrowth } from './util';
import { CellWithBar } from './components/CellWithBar';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { removeDomain } from '@/utils';
import { useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { useConnectToGoogleSearchConsole } from '../Settings/hooks';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';

export const PageAnalytics = () => {
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/page-analytics' });
  const search = useSearch({ from: '/page-analytics' });

  const [graphState, setGraphState] = useGraphState();
  const [groupGrowthSorting, setGroupGrowthSorting] =
    useGrowthSorting('dashgroupgroup');
  const [pageGrowthSorting, setPageGrowthSorting] =
    useGrowthSorting('dashboardpage');

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  const gscIsActive =
    gscHookProps.googleSearchConsoleData?.data.state === 'active';

  const analyticsQuery = useGetDashboard(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        range: graphState.range,
        granularity: graphState.granularity,
        filters: [
          {
            dimension: 'country',
            operator: 'equals',
            expression: graphState.country.iso_3166_3,
          },
        ],
      },
    },
    {
      enabled: gscIsActive,
    },
  );

  const topPages = analyticsQuery.data?.data.top_pages
    ? convertData(analyticsQuery.data?.data.top_pages)
    : [];

  const topPagesMostClicks =
    topPages.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]?.current
      .clicks ?? 0;
  const groupMostClicks =
    analyticsQuery.data?.data.groups.toSorted(
      (a, b) => b.current.clicks - a.current.clicks,
    )[0]?.current.clicks ?? 0;

  appState.pageTitle('Page analytics');

  if (!gscIsActive && !gscHookProps.isGettingGoogleSearchConsoleData) {
    return (
      <Menus>
        <PageContainer canScroll={false}>
          <div className="relative h-screen w-full overflow-hidden">
            <img
              src="/svg/analytics_placeholder.png"
              className="aspect-square w-full blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="-mt-[420px] w-full">
                {gscHookProps && (
                  <GoogleConsoleConnect
                    {...gscHookProps}
                    page="page analytics"
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainer>
      </Menus>
    );
  }

  return (
    <>
      <Menus>
        <PageContainer title="Analytics">
          <AnalyticsGraph
            data={analyticsQuery.data?.data.graph}
            metrics={analyticsQuery.data?.data.metrics}
            state={graphState}
            onStateChange={setGraphState}
          />

          <Table.Root
            isLoading={analyticsQuery.isLoading}
            skeletonLoaders={5}
            items={
              groupGrowthSorting === 'popular'
                ? (analyticsQuery.data?.data.groups ?? [])
                : sortByGrowth(
                    analyticsQuery.data?.data.groups ?? [],
                    groupGrowthSorting === 'rising' ? 'DESC' : 'ASC',
                    'clicks',
                  )
            }
            columns={[
              {
                heading: 'Group',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    name={item.name}
                    value={item.current.clicks}
                    topValue={groupMostClicks}
                    onClick={() =>
                      navigate({
                        to: `/page-analytics/groups/${item.slug}`,
                      })
                    }
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Clicks" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Impressions" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="CTR" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Position" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-8 flex w-full justify-between">
                <div className="flex gap-2">
                  <h1 className="text-lg font-semibold">Page groups</h1>
                  <ToggleButton
                    value={groupGrowthSorting}
                    onChange={setGroupGrowthSorting}
                    options={[
                      {
                        displayName: 'Popular',
                        value: 'popular',
                      },
                      {
                        displayName: 'Rising',
                        value: 'rising',
                      },
                      {
                        displayName: 'Falling',
                        value: 'falling',
                      },
                    ]}
                  />
                </div>
                <Button
                  prependIcon={SquarePen}
                  onClick={() => navigate({ to: '/page-analytics/groups' })}
                  text="manage groups"
                  variant="ghost"
                />
              </div>
            </Table.Header>
          </Table.Root>

          <Table.Root
            items={(pageGrowthSorting === 'popular'
              ? topPages
              : sortByGrowth(
                  topPages ?? [],
                  pageGrowthSorting === 'rising' ? 'DESC' : 'ASC',
                  'clicks',
                )
            )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
            isLoading={analyticsQuery.isLoading}
            columns={[
              {
                heading: 'Page',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topPagesMostClicks}
                    name={removeDomain(item.current.page)}
                    onClick={() =>
                      navigate({
                        to: '/page-analytics/page/$pageId',
                        params: { pageId: item.current.page },
                      })
                    }
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Clicks" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Impressions" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="CTR" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Position" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <h1 className="text-lg font-semibold">Pages</h1>
              <ToggleButton
                value={pageGrowthSorting}
                onChange={setPageGrowthSorting}
                options={[
                  {
                    displayName: 'Popular',
                    value: 'popular',
                  },
                  {
                    displayName: 'Rising',
                    value: 'rising',
                  },
                  {
                    displayName: 'Falling',
                    value: 'falling',
                  },
                ]}
              />
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                setCurrentPage={(page) => navigate({ search: { page } })}
                lastPage={Math.floor((topPages?.length ?? 0) / 20)}
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
