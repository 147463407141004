import * as Diff from 'diff';
import { ChangePopover } from './ChangePopover';
import {
  handleAccept,
  handleAcceptAll,
  handleReject,
  handleRejectAll,
} from '../util';
import { EditableContainer } from './EditableContainer';
import { TextArea } from '@/Components/v2/TextArea/TextArea';

type Props = {
  oldText: string;
  newText: string;
  onChange: (values: { actualValue?: string; newValue?: string }) => void;
};

export const PlaintextDiff = ({ newText, oldText, onChange }: Props) => {
  const diffs = Diff.diffWords(oldText, newText, { ignoreCase: true });

  return (
    <EditableContainer
      preview={
        <div className="whitespace-pre-wrap">
          {diffs.map((part, index) => {
            if (part.removed && diffs[index + 1]?.added) return null;
            if (!part.added && !part.removed) return part.value;
            return (
              <ChangePopover
                key={index + part.value}
                newText={part.added ? part.value : undefined}
                oldText={
                  part.removed
                    ? part.value
                    : diffs[index - 1]?.removed
                      ? diffs[index - 1]?.value
                      : undefined
                }
                onReject={() => onChange(handleReject(diffs, index))}
                onAccept={() => onChange(handleAccept(diffs, index))}
              >
                {part.value}
              </ChangePopover>
            );
          })}
        </div>
      }
      editor={
        <TextArea
          fullHeight
          value={newText}
          onChange={(value) => onChange({ newValue: value })}
        />
      }
      onAccept={() => onChange(handleAcceptAll(diffs))}
      onReject={() => onChange(handleRejectAll(diffs))}
    />
  );
};
