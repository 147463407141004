import {
  CurrentSlideOver,
  GoogleDocsStatusSettings,
  SettingsState,
  WordPressSettings,
} from '@/types';
import { PlateEditor } from '@udecode/plate-common';
import React, { useEffect, useRef, useState } from 'react';
import { serializeToMarkdown } from './components/PlateEditor/lib/serialize';
import { useDebounce } from '@/Hooks/useDebounce';
import {
  QueryObserverResult,
  RefetchOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { DocumentResource, ProjectUrlResource } from '@/api/openapiSchemas';
import { useDocumentStore, useInternalLinkingStore } from './stores';
import { useShallow } from 'zustand/react/shallow';
import { useDropInitialMountEffect } from '@/Hooks/useDropInitialMountEffect';
import { useToggle } from '@/Hooks/useToggle';
import { useAppStore } from '../AppLoader/stores';
import { EditorRefContext } from './context';
import { useSearch } from '@tanstack/react-router';
import {
  ProjectsConnectionsIndexError,
  ProjectsConnectionsIndexResponse,
  useProjectConnectionProjectConnectionGoogleDocs,
  useProjectConnectionWordPressStore,
  useProjectConnectionWordPressUpdate,
  useProjectsConnectionsDestroy,
  useProjectsDocumentsLinkSuggestionsStore,
  useProjectsUrlsIndex,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { getHostName, removeProtocol } from '@/utils';
import { create } from 'zustand';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useEditor } from '@/Components/Utils/v2/api';

export const useGetSettingsState = (document: DocumentResource) => {
  const [settings, setSettings] = useState<SettingsState>({
    internalName: { text: document.internal_name, dialog: false },
    tone: { text: document.settings.tone_of_voice, dialog: false },
    type: { text: document.settings.type, dialog: false },
    audience: { text: document.settings.audience, dialog: false },
    brief: { text: document.settings.context.value, dialog: false },
  });
  const handleDialog = (key: keyof SettingsState) =>
    setSettings((prev) => ({
      ...prev,
      [key]: { ...prev[key], dialog: !prev[key].dialog },
    }));

  const handleSettings = (key: keyof SettingsState, value: string) =>
    setSettings((prev) => ({
      ...prev,
      [key]: { ...prev[key], text: value },
    }));

  useEffect(() => {
    handleSettings('internalName', document.internal_name);
  }, [document.internal_name]);

  return { settings, handleDialog, handleSettings };
};

export const useSaveChanges = (
  documentId: number,
  projectId: number,
  data: {
    heading?: string;
    metaData?: string;
    editor: PlateEditor;
  },
  isLoading: boolean,
) => {
  const { heading, metaDescription, metaTitle, customFieldValues } =
    useDocumentStore(
      useShallow(
        ({ metaDescription, heading, metaTitle, customFieldValues }) => ({
          heading,
          metaDescription,
          metaTitle,
          customFieldValues,
        }),
      ),
    );

  const client = useQueryClient();

  const updateDocumentMutation = useUpdateDocument({
    mutationKey: [documentId],
    onSuccess: (data) => {
      const queryKey =
        client.getQueryCache().find({
          predicate: (query) => query.queryKey.includes('getDocument'),
        })?.queryKey ?? [];
      client.setQueryData(queryKey, data);
    },
  });

  const text = serializeToMarkdown(data.editor.children);

  const saveDocument = () => {
    if (isLoading) {
      return;
    }
    const customFieldKeys = Object.keys(customFieldValues).filter(
      (key) => customFieldValues[key] !== undefined,
    );

    updateDocumentMutation.mutate({
      pathParams: {
        document: documentId,
        project: projectId,
      },
      body: {
        body: text,
        custom_fields: customFieldKeys.map((key) => ({
          id: Number(key),
          value: customFieldValues[key],
        })),
        meta_description: metaDescription.substring(0, 255),
        title: heading,
        meta_title: metaTitle,
      },
    });
  };

  useDebounce(saveDocument, [text, customFieldValues, isLoading]);

  useDebounce(
    () => {
      const id: undefined | number = undefined;

      saveDocument();
      return () => {
        if (id) {
          clearTimeout(id);
        }
      };
    },
    [heading, metaDescription, metaTitle, isLoading],
    100,
  );

  return { isSaving: updateDocumentMutation.isPending };
};

export const useHeadingState = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>,
] => {
  const { heading, setHeading } = useDocumentStore(
    useShallow(({ heading, setHeading }) => ({ heading, setHeading })),
  );
  const [copiedHeading, setCopiedHeading] = useState(heading);

  useDebounce(() => {
    setHeading(copiedHeading);
  }, [copiedHeading]);

  useDropInitialMountEffect(() => {
    setCopiedHeading(heading);
  }, [heading]);

  return [copiedHeading, setCopiedHeading];
};

const useMetaTitleCopyStore = create<{
  copiedMetaTitle: string;
  setCopiedMetaTitle: (value: string) => void;
}>((set) => ({
  copiedMetaTitle: '',
  setCopiedMetaTitle: (metaTitle: string) =>
    set({ copiedMetaTitle: metaTitle }),
}));

export const useMetaTitleState = (): [string, (value: string) => void] => {
  const { metaTitle, setMetaTitle } = useDocumentStore(
    useShallow(({ metaTitle, setMetaTitle }) => ({ metaTitle, setMetaTitle })),
  );

  const { copiedMetaTitle, setCopiedMetaTitle } = useMetaTitleCopyStore();

  useDebounce(() => {
    setMetaTitle(copiedMetaTitle);
  }, [copiedMetaTitle]);

  useEffect(() => {
    setCopiedMetaTitle(metaTitle);
  }, [metaTitle]);

  return [copiedMetaTitle, setCopiedMetaTitle];
};

export const useMetaDescriptionState = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>,
] => {
  const { metaDescription, setMetaDescription } = useDocumentStore(
    useShallow(({ metaDescription, setMetaDescription }) => ({
      metaDescription,
      setMetaDescription,
    })),
  );
  const [copiedMetaDescription, setCopiedMetaDescription] =
    useState(metaDescription);

  useDebounce(() => {
    setMetaDescription(copiedMetaDescription);
  }, [copiedMetaDescription]);

  useDropInitialMountEffect(() => {
    setCopiedMetaDescription(metaDescription);
  }, [metaDescription]);

  return [copiedMetaDescription, setCopiedMetaDescription];
};

export const useWordPressDialog = (
  publishingDocument: boolean,
  {
    isPublishingError,
    publishError,
  }: {
    isPublishingError: boolean;
    publishError: unknown;
  },
  currentConnections: ProjectsConnectionsIndexResponse | undefined,
  refreshConnections: (
    options?: RefetchOptions | undefined,
  ) => Promise<
    QueryObserverResult<
      ProjectsConnectionsIndexResponse,
      ProjectsConnectionsIndexError
    >
  >,
) => {
  const appState = useAppStore();
  const projectId = appState.currentProject!.id;
  const [wordPressDialog, handleWordPressDialog] = useToggle(false);
  const [wordPressSettings, setWordPressSettings] = useState<WordPressSettings>(
    {
      token: '',
      url: '',
      username: '',
      mode: 'ADD_CONNECTION',
    },
  );

  const {
    mutateAsync: addConnection,
    reset: resetAddConnection,
    error: addConnectionError,
    isError: isAddConnectionError,
    isPending: isPendingNewWordPressConnection,
  } = useProjectConnectionWordPressStore({
    onSuccess: () => {
      refreshConnections();
      handleWordPressDialog();
    },
  });

  const {
    mutateAsync: updateConnection,
    reset: resetUpdateConnection,
    error: updateConnectionError,
    isError: isUpdateConnectionError,
    isPending: isPendingUpdatedWordPressConnection,
  } = useProjectConnectionWordPressUpdate({
    onSuccess: () => {
      refreshConnections();
      handleWordPressDialog();
    },
  });

  const handleCloseAndResetWordPressDialog = () => {
    handleWordPressDialog();
    resetAddConnection();
    resetUpdateConnection();
  };

  const { mutate: deleteConnection } = useProjectsConnectionsDestroy({
    onSuccess: () => {
      refreshConnections();
      handleWordPressDialog();
    },
  });

  const handleEditConnection = () => {
    const connection = currentConnections.data?.find(
      (connection) => connection.type === 'wordpress',
    );
    if (connection) {
      setWordPressSettings((prev) => ({ ...prev, ...connection.settings }));
      handleWordPressDialog();
    }
  };

  useEffect(() => {
    if (currentConnections) {
      const connection = currentConnections.data?.find(
        (connection) => connection.type === 'wordpress',
      );
      if (connection) {
        setWordPressSettings({
          ...connection.settings[0],
          mode: 'EDIT_CONNECTION',
        });
      } else {
        setWordPressSettings((prev) => ({ ...prev, mode: 'ADD_CONNECTION' }));
      }
    }
  }, [currentConnections]);

  const handleAddConnection = () => {
    resetAddConnection();
    resetUpdateConnection();
    handleWordPressDialog();
  };

  const handleChangeWordPress = (
    value: string,
    id: keyof WordPressSettings,
  ) => {
    setWordPressSettings((prev) => ({ ...prev, [id]: value }));
  };

  const submitWordPressConnection = () => {
    const { mode, ...settings } = wordPressSettings;
    resetAddConnection();
    resetUpdateConnection();
    if (wordPressSettings.mode === 'EDIT_CONNECTION') {
      const connection = currentConnections.data.find(
        (connection) => connection.type === 'wordpress',
      )!;
      if (
        settings.url === '' &&
        settings.token === '' &&
        settings.username === ''
      ) {
        deleteConnection({
          pathParams: {
            connection: connection.id,
            project: projectId,
          },
        });
        return;
      }
      updateConnection({
        pathParams: {
          project: projectId,
          connection: connection.id,
        },
        body: {
          settings,
        },
      });
    } else {
      addConnection({
        pathParams: {
          project: projectId,
        },
        body: {
          settings,
        },
      });
    }
  };

  const isEstablishingConnection = () => {
    if (wordPressSettings.mode === 'ADD_CONNECTION') {
      return isPendingNewWordPressConnection || publishingDocument;
    }
    return isPendingUpdatedWordPressConnection || publishingDocument;
  };

  const getErrors = () => {
    if (wordPressSettings.mode === 'ADD_CONNECTION' && isAddConnectionError) {
      return { error: addConnectionError, hasError: isAddConnectionError };
    }
    if (
      wordPressSettings.mode === 'EDIT_CONNECTION' &&
      isUpdateConnectionError
    ) {
      return {
        error: updateConnectionError,
        hasError: isUpdateConnectionError,
      };
    }
    if (isPublishingError) {
      return { error: publishError, hasError: isPublishingError };
    }
    return { error: null, hasError: false };
  };

  return {
    wordPressDialog,
    handleWordPressDialog: handleCloseAndResetWordPressDialog,
    wordPressSettings,
    handleChangeWordPress,
    submitWordPressConnection,
    isEstablishingConnection,
    errors: getErrors(),
    handleEditConnection,
    handleAddConnection,
  };
};

export const useGoogleDocsDialog = (
  currentConnections: ProjectsConnectionsIndexResponse | undefined,
) => {
  const [statusSettings, setStatusSettings] =
    useState<GoogleDocsStatusSettings>({ isOpen: false, mode: 'success' });
  const broadCastChannel = useRef<BroadcastChannel | null>(null);
  const params = useSearch({
    from: '/documents/$documentId',
  });
  const currentProject = useAppStore((state) => state.currentProject);
  const [googleDocsMode, setGoogleDocsMode] = useState<
    'ADD_CONNECTION' | 'EDIT_CONNECTION'
  >('ADD_CONNECTION');

  const [reconnectGoogleDialog, setReconnectGoogleDialog] = useToggle(false);

  const { mutate: createGoogleConnection } =
    useProjectConnectionProjectConnectionGoogleDocs({
      onSuccess: (data) => {
        setReconnectGoogleDialog();
        if (broadCastChannel.current) {
          broadCastChannel.current.close();
        }

        broadCastChannel.current = new BroadcastChannel('googleDocsConnection');

        broadCastChannel.current.onmessage = (
          messageEvent: MessageEvent<'success' | 'failed'>,
        ) => {
          setStatusSettings({ isOpen: true, mode: messageEvent.data });
          broadCastChannel.current!.close();
        };
        window.open(data.data.redirect_url, 'mywindow');
      },
    });

  const handleCloseGoogleDocsStatusDialog = () => {
    if (broadCastChannel.current) {
      broadCastChannel.current.close();
    }
    setStatusSettings((prev) => ({ ...prev, isOpen: false }));
  };

  useEffect(() => {
    return () => {
      if (broadCastChannel.current) {
        broadCastChannel.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (params.googleDocs) {
      const channel: BroadcastChannel = new BroadcastChannel(
        'googleDocsConnection',
      );
      channel.postMessage(params.googleDocs);
      channel.close();
      window.close();
    }
  }, [params.googleDocs]);

  const handleCreateGoogleDocConnection = () => {
    const hostname = getHostName();
    createGoogleConnection({
      pathParams: {
        project: currentProject!.id,
      },
      body: {
        return_url: hostname + '?googleDocs=default',
        success_url: hostname + '?googleDocs=success',
        failed_url: hostname + '?googleDocs=failed',
      },
    });
  };

  useEffect(() => {
    if (currentConnections) {
      const connection = currentConnections.data?.find(
        (connection) => connection.type === 'google_docs',
      );
      if (connection) {
        setGoogleDocsMode('EDIT_CONNECTION');
      }
    }
  }, [currentConnections]);

  return {
    handleCloseGoogleDocsStatusDialog,
    statusSettings,
    reconnectGoogleDialog,
    handleCreateGoogleDocConnection,
    googleDocsMode,
    handleOpenReconnectDialog: setReconnectGoogleDialog,
  };
};
export const useGetEditorFocusElement = () => {
  const editorFocusRef = React.useContext(EditorRefContext);

  return editorFocusRef?.current?.firstChild! as HTMLInputElement;
};

export const useGetLinkSuggestions = () => {
  const { hardReset, isLoadingInternalLinks, populateInternalLinks } =
    useInternalLinkingStore(
      useShallow((state) => ({
        hardReset: state.hardReset,
        isLoadingInternalLinks: state.isLoadingInternalLinks,
        populateInternalLinks: state.populateInternalLinks,
      })),
    );

  const editor = useEditor();
  const { mutate: getLinkSuggestions } =
    useProjectsDocumentsLinkSuggestionsStore({
      onError: hardReset,
      onMutate: isLoadingInternalLinks,
      onSuccess: (data) => {
        const content = editor?.string({
          anchor: { path: [0], offset: 0 },
          focus: { path: [editor.children.length - 1], offset: 0 },
        });
        populateInternalLinks(data.data, content);
      },
    });

  return getLinkSuggestions;
};

export const useSLideoverState = (isEnabled: boolean) => {
  const [currentSlideOver, setCurrentSlideOver] = useState<CurrentSlideOver>();

  return {
    currentSlideOver,
    setCurrentSlideOver: isEnabled ? setCurrentSlideOver : () => {},
  };
};

export const useDocumentUrl = (document: DocumentResource) => {
  const appState = useAppStore();
  const client = useQueryClient();

  const [urlInput, setUrlInput] = useState(
    document.project_url?.url ?? document.url ?? '',
  );
  const [debouncedUrlInput, setDebouncedUrlInput] = useState(
    document.project_url?.url ?? document.url ?? '',
  );
  const urlQuery = useProjectsUrlsIndex({
    pathParams: { project: appState.currentProject!.id },
    queryParams: {
      filters: debouncedUrlInput ? { search: debouncedUrlInput } : undefined,
    },
  });

  useEffect(() => {
    setUrlInput(document.project_url?.url ?? document.url ?? '');
    setDebouncedUrlInput(document.project_url?.url ?? document.url ?? '');
  }, [document.project_url]);

  const directMatch = urlQuery.data?.data.find(
    (url) =>
      removeProtocol(url.url).toLowerCase().trim() ===
        removeProtocol(debouncedUrlInput).toLowerCase().trim() ||
      removeProtocol(url.path).toLowerCase().trim() ===
        removeProtocol(debouncedUrlInput).toLowerCase().trim(),
  );

  const projectUrl = useDocumentStore((state) => state.projectUrl);
  const setProjectUrl = useDocumentStore((state) => state.setProjectUrl);

  const updateDocumentMutation = useUpdateDocument();

  useEffect(() => {
    setProjectUrl(directMatch);
  }, [directMatch]);

  useDebounce(() => {
    setDebouncedUrlInput(urlInput);
  }, [urlInput]);

  useDebounce(() => {
    if (projectUrl?.id === document.project_url?.id) return;
    updateDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          document: document.id,
        },
        body: {
          url: debouncedUrlInput,
          project_url_id: directMatch?.id ?? null,
        },
      },
      {
        onSuccess: (data) => {
          client.setQueriesData(
            {
              predicate: (query) => query.queryKey.includes('getDocument'),
            },
            (oldData) => ({
              ...oldData,
              data: {
                ...oldData.data,
                project_url: data.data.project_url,
                url: data.data.url,
              },
            }),
          );
        },
      },
    );
  }, [projectUrl?.id, debouncedUrlInput]);

  const errorHelper = new ErrorHelper(updateDocumentMutation.error);

  const handleSetProjectUrl = (projectUrl: ProjectUrlResource) => {
    setUrlInput(projectUrl.url);
    setProjectUrl(projectUrl);
  };

  return {
    refetchSuggestions: urlQuery.refetch,
    directMatch,
    suggestions: urlQuery.data?.data,
    isSuggestionsLoading: urlQuery.isFetching || urlInput !== debouncedUrlInput,
    urlInput,
    setUrlInput,
    projectUrl,
    setProjectUrl: handleSetProjectUrl,
    errorHelper,
    reset: updateDocumentMutation.reset,
  };
};
