import { formatThousandSeperator } from '@/utils';
import dayjs from 'dayjs';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export const CustomGraphTooltip = (
  row: TooltipProps<ValueType, NameType>,
  limitValues = false,
) => {
  return (
    <>
      {row.active && row.payload && (
        <div className="flex flex-col justify-between rounded-md border border-primary-300 bg-white p-2 shadow-lg">
          <p className="mb-2  text-primary-800">
            {dayjs(row.label).format('MMM D YYYY')}
          </p>
          {row.payload.map((item) => (
            <div
              key={item.dataKey}
              className="flex items-center justify-between gap-8"
            >
              <p style={{ color: item.color }} className="">
                {item.name?.replaceAll('distribution.', '')}:
              </p>
              <p style={{ color: item.color }} className="">
                {(() => {
                  const value = Array.isArray(item.value)
                    ? item.value[1]
                    : item.value;

                  if (typeof value === 'string') {
                    return value;
                  }

                  return limitValues
                    ? Number(value) > 101
                      ? '101+'
                      : value
                    : formatThousandSeperator(Number(value));
                })()}
                {}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
