import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { AddKeywordsToListDialog, ListTabs } from '../components';
import { useEffect, useState } from 'react';
import { useRankTrackingLists } from '../RankTracking/hooks';
import { useTableSelection } from '@/Components/Table/hooks';
import { RemoveKeywordsFromListDialog } from '../components/RemoveKeywordsFromListDialog';
import {
  useListScannedKeywords,
  useListScannedKeywordsGrouped,
  useProjectScannedKeywordScan,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import Toggle from '@/Components/Toggle';
import { useNavigate, useSearch } from '@tanstack/react-router';
import {
  KeywordResource,
  ScannedKeywordGroupResource,
  TrackedKeywordResource,
  TrackedKeywordUpdateResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import { Button } from '@/Components/v2/Button';
import { AddTrackedKeywordsDialog } from '../RankTracking/components/AddTrackedKeywordsDialog';
import { GroupedKeywordsTable } from './components/GroupedKeywordTable';
import { KeywordsTable } from './components/KeywordsTable';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { UrlsSlideover } from './components/UrlsSlideover';
import { DeleteKeywordsDialog } from '../RankTracking/components/DeleteKeywordsDialog';
import { Group, ScanSearch, ScanText } from 'lucide-react';

export const KeywordScannner = ({ v2 }: { v2: boolean }) => {
  const appState = useAppStore();

  const search = useSearch({
    from: v2 ? '/keyword-clustering/v2' : '/keyword-clustering',
  });
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const [isGrouped, setIsGrouped] = useState(true);
  const scanKeywordsMutation = useProjectScannedKeywordScan({});

  const {
    addController,
    addToListController,
    closeDialogs,
    deleteController,
    editController,
    listsQuery,
    removeFromListController,
    selectedTabIndex,
    setSelectedTabIndex,
    setShowAddKeywordDialog,
    setTrackingToBeAddedToList,
    setTrackingToBeDeleted,
    setTrackingToBeRemovedFromList,
    showAddKeywordDialog,
    trackingToBeAddedToList,
    trackingToBeRemovedFromList,
    trackingToBeDeleted,
  } = useRankTrackingLists<
    ScannedKeywordGroupResource | TrackedKeywordResource
  >({
    onSuccess: (type) => {
      if (type === 'remove-keyword-from-list') {
        (isGrouped ? groupedKeywordsQuery : keywordsQuery).refetch();
        (isGrouped
          ? groupedKeywordSelection
          : keywordSelection
        ).resetSelection();
      }
    },
    noAllTab: v2,
  });

  const [urlsSlideover, setUrlsSlideover] = useState({
    isOpen: false,
    keyword: undefined as KeywordResource | undefined,
    results: undefined as TrackedKeywordUpdateResultResource[] | undefined,
  });
  const [isScanning, setIsScanning] = useState(
    appState.currentProject!.is_content_scanning,
  );
  const selectedList =
    listsQuery.data?.data[v2 ? selectedTabIndex : selectedTabIndex - 1];

  const isGroupedMode = isGrouped && !!selectedList;

  const keywordsQuery = useListScannedKeywords(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        limit: 100,
        page: search.page,
        ...(selectedList ? { filters: { list_id: selectedList.id } } : {}),
      },
    },
    {
      retry: false,
      enabled: !isGroupedMode,
    },
  );

  const groupedKeywordsQuery = useListScannedKeywordsGrouped(
    {
      pathParams: {
        project: appState.currentProject!.id,
        rankTrackingList: selectedList?.id,
      },
    },
    {
      retry: false,
      enabled: isGroupedMode,
    },
  );

  const keywordSelection = useTableSelection();
  const groupedKeywordSelection = useTableSelection({
    selectionIdentifiedBy: 'main_keyword.id',
  });

  useEffect(() => {
    const callBack = () => {
      (isGroupedMode ? groupedKeywordsQuery : keywordsQuery)
        .refetch()
        .then(() => {
          setIsScanning(false);
          showSnackbar({
            message: `Scan complete`,
            color: 'secondary',
          });
        });
    };
    const channel = window.Echo.private(
      `projects.${appState.currentProject?.id}`,
    ).listen('.ContentScanCompleted', callBack);

    return () => {
      channel.stopListening('.ContentScanCompleted', callBack);
    };
  }, []);

  const handleStartScan = () => {
    scanKeywordsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          setIsScanning(true);
          appState.refreshCurrentProject();
        },
      },
    );
  };

  const isGroupedKeyword = (
    item: ScannedKeywordGroupResource | TrackedKeywordResource,
  ): item is ScannedKeywordGroupResource => {
    return 'main_keyword' in item;
  };

  appState.pageTitle('Keyword scanner');

  return (
    <Menus>
      <UrlsSlideover
        results={urlsSlideover.results}
        isOpen={urlsSlideover.isOpen}
        onClose={() => setUrlsSlideover({ ...urlsSlideover, isOpen: false })}
        keyword={urlsSlideover.keyword}
      />
      <AddTrackedKeywordsDialog
        key={'keywordadddialog' + showAddKeywordDialog + 'l' + selectedList?.id}
        hideSuggestions
        listRequired
        initialListId={selectedList?.id}
        onSuccess={(listId) => {
          if (listId === undefined) {
            setSelectedTabIndex(0);
          } else {
            if (listId === selectedList?.id) {
              (isGroupedMode ? groupedKeywordsQuery : keywordsQuery).refetch();
            } else {
              listsQuery.refetch().then((response) => {
                setSelectedTabIndex(
                  response.data?.data?.findIndex((list) => list.id === listId) +
                    (v2 ? 0 : 1),
                );
              });
            }
          }
        }}
        trackedKeywords={[]}
        isOpen={showAddKeywordDialog}
        onClose={() => {
          setShowAddKeywordDialog(false);
        }}
      />

      <DeleteKeywordsDialog
        isOpen={trackingToBeDeleted.length > 0}
        keywords={trackingToBeDeleted.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? (item.main_keyword.keyword?.name ?? '')
            : (item.keyword?.name ?? ''),
        }))}
        onClose={() => setTrackingToBeDeleted([])}
        onSuccess={(keywords) => {
          (isGroupedMode ? groupedKeywordsQuery : keywordsQuery)
            .refetch()
            .then(() => {
              (isGroupedMode
                ? groupedKeywordSelection
                : keywordSelection
              ).resetSelection();
              setTrackingToBeDeleted([]);
              showSnackbar({
                color: 'secondary',
                message: `Deleted ${keywords.length} tracked keyword`,
              });
            });
        }}
      />
      <AddKeywordsToListDialog
        error={addController.error}
        isPending={addToListController.isLoading || addController.isLoading}
        key={trackingToBeAddedToList.length + 'add'}
        keywords={trackingToBeAddedToList.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? (item.main_keyword.keyword?.name ?? '')
            : (item.keyword?.name ?? ''),
        }))}
        listItems={(listsQuery.data?.data ?? []).map((value) => ({
          title: value.name,
          value: parseInt(value.id),
        }))}
        handleSubmit={addToListController.handleSubmit}
        onClose={() => {
          setTrackingToBeAddedToList([]);
        }}
        isDisabled={listsQuery.isLoading}
      />
      <RemoveKeywordsFromListDialog
        key={trackingToBeRemovedFromList.length + 'remove'}
        list={selectedList}
        keywordsToBeDeleted={trackingToBeRemovedFromList.map((item) => ({
          id: isGroupedKeyword(item) ? item.main_keyword.id : item.id,
          name: isGroupedKeyword(item)
            ? (item.main_keyword.keyword?.name ?? '')
            : (item.keyword?.name ?? ''),
        }))}
        onClose={() => {
          setTrackingToBeRemovedFromList([]);
        }}
        isLoading={removeFromListController.isLoading}
        error={removeFromListController.error}
        onSubmit={removeFromListController.handleSubmit}
      />
      <PageContainer
        title="Keyword clustering"
        actions={
          !v2 && (
            <Tooltip
              title="Keywords are currently being scanned"
              disabled={!isScanning}
            >
              <Button
                variant="ghost"
                text="Rescan keywords"
                onClick={handleStartScan}
                prependIcon={ScanSearch}
                isLoading={scanKeywordsMutation.isPending}
                disabled={isScanning}
              />
            </Tooltip>
          )
        }
      >
        <p className="my-4">
          Check if similar keywords need one or more pages based on SERP
          similarity.{' '}
          <a
            href="https://docs.seo.ai/a-40-keyword-clustering"
            target="_blank"
            className="underline"
          >
            Read more
          </a>
          .
        </p>
        <div className={`mb-4 mt-8 flex items-center justify-end gap-2 `}>
          <Tooltip
            title="It is only possible to group keywords on a list"
            description="Choose a list or add keywords to a new list to see grouped keywords"
            side="bottom"
            disabled={!!selectedList}
          >
            <div
              className={`flex items-center gap-2 ${
                !selectedList ? 'opacity-50' : ''
              }`}
            >
              <Toggle
                value={isGrouped && !!selectedList}
                onChange={setIsGrouped}
                disabled={!selectedList}
              />
              Group by similarity
            </div>
          </Tooltip>
          <Button
            text="Add keywords"
            color="secondary"
            onClick={() => setShowAddKeywordDialog(true)}
          />
        </div>
        <ListTabs
          noAllTab={v2}
          deleteController={deleteController}
          editController={editController}
          onCloseDialog={closeDialogs}
          data={listsQuery.data?.data}
          isLoading={listsQuery.isLoading}
          selectedTabIndex={selectedTabIndex}
          selectedList={selectedList}
          addController={addController}
          onChange={(tabIndex) => {
            navigate({ search: { page: 1 } });
            setSelectedTabIndex(tabIndex);
          }}
        />
        {v2 && selectedTabIndex === 0 && listsQuery.data?.data.length === 0 ? (
          <div className="flex w-full flex-col items-center justify-center gap-2 pt-72">
            <Group size={200} className="text-primary-200" />
            <p className="font-bold">No keywords</p>
            <p className="text-center text-gray">
              Add keywords to check SERP similarity.
            </p>
            <Button
              text="Add keywords"
              color="secondary"
              variant="outline"
              onClick={() => setShowAddKeywordDialog(true)}
            />
          </div>
        ) : (
          <>
            {isGroupedMode ? (
              <GroupedKeywordsTable
                onOpenUrlsSlideover={(keyword, results) =>
                  setUrlsSlideover({ isOpen: true, keyword, results })
                }
                selectedList={selectedList}
                isLoading={groupedKeywordsQuery.isFetching}
                items={groupedKeywordsQuery.data?.data}
                selection={
                  groupedKeywordSelection.selection as ScannedKeywordGroupResource[]
                }
                onSelectionChange={groupedKeywordSelection.setSelection}
                pagination={{
                  page: Number(search.page),
                  lastPage: keywordsQuery.data?.meta.last_page ?? 0,
                  onPageChange: (page) => navigate({ search: { page } }),
                }}
                selectionActions={{
                  isLoading: deleteController.isLoading,
                  onAddToList: () =>
                    setTrackingToBeAddedToList(
                      groupedKeywordSelection.selection,
                    ),
                  onRemoveFromList: () =>
                    setTrackingToBeRemovedFromList(
                      groupedKeywordSelection.selection,
                    ),
                  onDelete: () =>
                    setTrackingToBeDeleted(groupedKeywordSelection.selection),
                }}
                v2={v2}
              />
            ) : (
              <KeywordsTable
                onOpenUrlsSlideover={(keyword, results) =>
                  setUrlsSlideover({ isOpen: true, keyword, results })
                }
                selectedList={selectedList}
                isLoading={keywordsQuery.isFetching}
                items={keywordsQuery.data?.data ?? []}
                selection={
                  keywordSelection.selection as TrackedKeywordResource[]
                }
                onSelectionChange={keywordSelection.setSelection}
                pagination={{
                  page: Number(search.page),
                  lastPage: keywordsQuery.data?.meta.last_page ?? 0,
                  onPageChange: (page) => navigate({ search: { page } }),
                }}
                selectionActions={{
                  isLoading: deleteController.isLoading,
                  onAddToList: () =>
                    setTrackingToBeAddedToList(keywordSelection.selection),
                  onRemoveFromList: () =>
                    setTrackingToBeRemovedFromList(keywordSelection.selection),
                  onDelete: () =>
                    setTrackingToBeDeleted(keywordSelection.selection),
                }}
                v2={v2}
              />
            )}
          </>
        )}
      </PageContainer>
    </Menus>
  );
};
