import { Dialog } from '@/Components/Dialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import {
  DocumentResource,
  OrganisationTagResource,
} from '@/api/openapiSchemas';
import { SettingsState } from '@/types';
import { useEffect, useState } from 'react';
import {
  useAnalyzePage,
  useBrandVoiceIndex,
  useGetCmsConnection,
  useListContentTypes,
  useProjectsShow,
  useUpdateDocument,
} from '@/api/openapiComponents';
import { Select } from '@/Components/v2/Select';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useTrackCustomBrandVoices } from '@/Pages/Settings/BrandVoice/hooks/useTrackCustomBrandVoices';
import { useQueryClient } from '@tanstack/react-query';
import { TagEditor } from '../TagEditor/TagEditor';
import {
  useSaveContextListener,
  useSaveInternalNameListener,
  useSaveSettingsListener,
} from './hooks';
import { ContentTypeSelect } from '@/Components/ContentTypeSelect';
import { AdditionalContextForm } from '@/Components/AdditionalContextForm';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { BrandVoiceSelect } from '@/Components/BrandVoiceSelect';

type Props = {
  settings: SettingsState;
  isOpen: boolean;
  onClose: () => void;
  document: DocumentResource;
  handleSettings: (key: keyof SettingsState, value: string) => void;
};

export const SettingsDialog = ({
  document,
  handleSettings,
  onClose,
  isOpen,
  settings,
}: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();
  const placeholder = useGetRandomPlaceHolder();

  const [brief, setBrief] = useState(
    document.settings.context.type_value ? '' : document.settings.brief,
  );
  const [briefFromUrl, setBriefFromUrl] = useState(
    document.settings.context.type_value ? document.settings.brief : '',
  );
  const [hasImportedContext, setHasImportedContext] = useState(
    !!document.settings.context.type_value,
  );
  const [importedUrl, setImportedUrl] = useState(
    document.settings.context.type_value,
  );
  const [selectedTab, setSelectedTab] = useState<'manual' | 'from-url'>(
    document.settings.context.type_value ? 'from-url' : 'manual',
  );
  const [selectedBrandVoice, setSelectedBrandVoice] = useState<
    string | undefined
  >(document.brand_voice ? document.brand_voice.id.toString() : undefined);
  const [editedInternalName, setEditedInternalName] = useState(
    settings.internalName.text,
  );
  const [selectedContentType, setSelectedContentType] = useState<
    number | undefined
  >(document.content_type ? document.content_type.id : undefined);

  const [hasActiveConnection, setHasActiveConnection] = useState(false);

  const hasCmsConnection = useGetCmsConnection({
    pathParams: { project: appState.currentProject!.id },
  });

  useEffect(() => {
    setHasActiveConnection(hasCmsConnection.data?.data.state === 'active');
  }, [hasCmsConnection.isFetched]);

  const listTypesQuery = useListContentTypes(
    {
      queryParams: {
        limit: 100,
        filters: { cms: Number(hasActiveConnection) },
      },
    },
    {
      enabled: hasCmsConnection.isFetched && hasCmsConnection.isSuccess,
    },
  );

  const updateDocumentMutation = useUpdateDocument();
  const { data: projectData, isLoading: isLoadingProjectData } =
    useProjectsShow({ pathParams: { project: appState.currentProject!.id } });

  const analyseUrlMutation = useAnalyzePage({
    onSuccess: (data) => {
      setBriefFromUrl(data.data.content);
      setHasImportedContext(true);
    },
  });

  const handleImportBrief = () => {
    analyseUrlMutation.mutate({
      body: {
        url: importedUrl,
        content_format: 'text',
        page_type: 'analyze',
      },
    });
  };

  useSaveInternalNameListener(
    document,
    appState.currentProject!.id,
    editedInternalName,
  );

  useSaveSettingsListener(
    document,
    appState.currentProject!.id,
    settings.brief.text,
    settings.type.text,
    settings.audience.text,
    settings.tone.text,
  );

  useSaveContextListener(
    document,
    appState.currentProject!.id,
    selectedTab === 'from-url'
      ? briefFromUrl.slice(0, projectData?.data.language?.brief_length ?? 1000)
      : brief.slice(0, projectData?.data.language?.brief_length ?? 1000),
    selectedTab === 'from-url' ? importedUrl : undefined,
  );

  const handleOnChangeBrandVoice = (item: string | undefined) => {
    let brandVoice: null | number = null;
    if (item) {
      brandVoice = parseInt(item);
    }
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: document.id,
          project: appState.currentProject!.id,
        },

        body: {
          brand_voice_id: brandVoice,
        },
      },
      {
        onSuccess: (newData) => {
          client.setQueriesData(
            {
              predicate: (query) => query.queryKey.includes('getDocument'),
            },
            (oldData: any) => {
              return newData;
            },
          );
        },
      },
    );

    setSelectedBrandVoice(item);
  };

  const handleOnChangeContentType = (value: number | undefined) => {
    updateDocumentMutation.mutate(
      {
        pathParams: {
          document: document.id,
          project: appState.currentProject!.id,
        },
        body: {
          content_type_id: value ?? null,
        },
      },
      {
        onSuccess: (newData) => {
          client.setQueriesData(
            {
              predicate: (query) => query.queryKey.includes('getDocument'),
            },
            (oldData: any) => {
              return newData;
            },
          );
        },
      },
    );

    setSelectedContentType(value);
  };

  const handleTagsUpdated = (tags: OrganisationTagResource[]) => {
    updateDocumentMutation.mutate({
      pathParams: {
        document: document.id,
        project: appState.currentProject!.id,
      },
      body: {
        tags: tags.map((tag) => tag.id),
      },
    });
    const queryKey =
      client
        .getQueryCache()
        .find({ predicate: (query) => query.queryKey.includes('getDocument') })
        ?.queryKey ?? [];

    client.setQueryData(
      queryKey,
      (data: { data: DocumentResource; meta: any }) => {
        return { data: { ...data.data, tags }, meta: data.meta };
      },
    );
  };

  return (
    <Dialog isOpen={isOpen} handleClose={onClose} title="Content settings">
      <div className="flex flex-col gap-4">
        <InputDecoration label="Internal name">
          <Input
            onChange={(value) => {
              setEditedInternalName(value);
              handleSettings('internalName', value);
            }}
            value={settings.internalName.text}
          />
        </InputDecoration>
        <InputDecoration label="Additional context">
          <AdditionalContextForm
            brief={brief}
            onBriefChange={setBrief}
            briefFromUrl={briefFromUrl}
            onBriefFromUrlChange={setBriefFromUrl}
            url={importedUrl}
            onUrlChange={setImportedUrl}
            isLoading={analyseUrlMutation.isPending}
            briefLimit={projectData?.data.language?.brief_length ?? 0}
            disabled={isLoadingProjectData}
            isImportSuccess={hasImportedContext}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            onImport={handleImportBrief}
            placeholder={placeholder}
          />
        </InputDecoration>
        <ContentTypeSelect
          disabled={document.project_url?.cms.has_reference}
          contentTypes={listTypesQuery.data?.data ?? []}
          isLoading={listTypesQuery.isLoading}
          onChange={(value) => handleOnChangeContentType(value!)}
          value={selectedContentType}
        />
        <BrandVoiceSelect
          onChange={handleOnChangeBrandVoice}
          value={selectedBrandVoice}
        />
        <InputDecoration label="Tags">
          <TagEditor
            isSettings
            organisationId={appState.organisation!.id}
            tags={document.tags ?? []}
            onTagUpdated={handleTagsUpdated}
          />
        </InputDecoration>
      </div>
    </Dialog>
  );
};
