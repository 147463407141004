import * as React from 'react';
import {
  ArrowRightLeft,
  Grip,
  GripVertical,
  PlusSquare,
  RefreshCcw,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useDraggable } from '@dnd-kit/core';
import BeatLoader from '@/Components/BeatLoader';
import remarkGfm from 'remark-gfm';
import { DocumentActionContext } from '@/Pages/Document/context';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Tooltip } from '@/Components/v2/Tooltip';

type AIMessageOverlayProps = {
  text: string;
  id?: number;
};
type Props = {
  hasSelection?: boolean;
  isLastAIMessage?: boolean;
  text: string;
  id: number;
  handleRefreshMessage: (id: number) => void;
};

export function AIMessageOverlayV2({ text, id }: AIMessageOverlayProps) {
  return (
    <div className="flex w-full flex-col pt-6 opacity-70">
      <p className="mb-4 px-6">
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-lg">
          {text}
        </ReactMarkdown>
        {id === 0 && (
          <BeatLoader
            className="mt-6"
            size={'0.5rem'}
            color="rgb(74 222 128 / 1)"
          />
        )}
      </p>
    </div>
  );
}

export function AIMessageV2({
  text,
  id,
  handleRefreshMessage,
  isLastAIMessage,
  hasSelection,
}: Props) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
    useDraggable({
      data: {
        text,
        source: 'chat',
      },
      id,
    });

  const { handleInsertText, handleReplaceText } = React.useContext(
    DocumentActionContext,
  );

  return (
    <div className="relative w-full">
      <div
        className="group flex w-full flex-col rounded-xl pt-6 hover:bg-white hover:shadow-md"
        ref={setNodeRef}
      >
        <p className="mb-4  px-6">
          <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose text-lg">
            {text}
          </ReactMarkdown>
        </p>
        <div className="flex items-center justify-between gap-2 px-2 pb-2 opacity-0 group-hover:opacity-100">
          <div className="flex items-center gap-1">
            <div {...listeners} {...attributes}>
              <Button
                prependIcon={GripVertical}
                text="Drag"
                size="sm"
                variant="ghost"
                dense
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            {isLastAIMessage && (
              <IconButton
                icon={RefreshCcw}
                tooltip={{ title: 'Refresh message', side: 'bottom' }}
                onClick={() => {
                  handleRefreshMessage(id);
                }}
              />
            )}
            {hasSelection && (
              <Button
                prependIcon={ArrowRightLeft}
                text="Replace"
                variant="ghost"
                dense
                size="sm"
                onClick={() => handleReplaceText(text)}
              />
            )}
            <Button
              prependIcon={PlusSquare}
              text="Insert"
              variant="ghost"
              dense
              size="sm"
              onClick={() => handleInsertText(text)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
