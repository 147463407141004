import { PlateEditor, TElement } from '@udecode/plate-common';
import {
  BaseEditor,
  BaseElement,
  BaseRange,
  BaseSelection,
  BaseText,
} from 'slate';
import Echo from 'laravel-echo';
import { LucideIcon } from 'lucide-react';
import { ComboBoxItem } from './Components/Inputs/ComboBox';
import {
  ConnectionType,
  ContentListResource,
  ContentSuggestionType,
  DocumentReportCompetitorResource,
  DocumentTitleResource,
  LinkSuggestionResource,
  OrganisationTagResource,
} from './api/openapiSchemas';
import React from 'react';

type ExtendedFunction<T> = (e: T) => void;

export enum WSEvents {
  SendQuestionsResult = 'SendQuestionsResult',
  SendSearchResult = 'SendSearchResult',
}

export type Generating = 'live' | 'write-more' | 'offline' | false;

export abstract class Channel {
  /**
   * The Echo options.
   */
  options: any;

  /**
   * Listen for an event on the channel instance.
   */
  abstract listen<T extends any>(
    event: string,
    callback: ExtendedFunction<T>,
  ): Channel;

  /**
   * Listen for a whisper event on the channel instance.
   */
  listenForWhisper<T extends Function>(
    event: string,
    callback: ExtendedFunction<T>,
  ): Channel {
    return this.listen('.client-' + event, callback);
  }

  /**
   * Listen for an event on the channel instance.
   */
  notification<T extends Function>(callback: ExtendedFunction<T>): Channel {
    return this.listen(
      '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
      callback,
    );
  }

  /**
   * Stop listening to an event on the channel instance.
   */
  abstract stopListening(event: string, callback?: Function): Channel;

  /**
   * Stop listening for a whisper event on the channel instance.
   */
  stopListeningForWhisper(event: string, callback?: Function): Channel {
    return this.stopListening('.client-' + event, callback);
  }

  /**
   * Register a callback to be called anytime a subscription succeeds.
   */
  abstract subscribed(callback: Function): Channel;

  /**
   * Register a callback to be called anytime an error occurs.
   */
  abstract error(callback: Function): Channel;
}
declare global {
  var route: any;
  var global: typeof globalThis;
  var Echo: Echo;
}

export enum NodeTypesEnum {
  P = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  UL = 'ul',
  OL = 'ol',
  LI = 'li',
  LIC = 'lic',
  HR = 'hr',
  CODE_BLOCK = 'code_block', // TODO: Support code_block
  BLOCKQUOTE = 'blockquote',
  A = 'a', // TODO Support links
  IMG = 'img', // TODO Support images
  TABLE = 'table', // TODO support tables
  TR = 'tr',
  TD = 'td',
  TH = 'th',
}

export enum MdastNodeType {
  PARAGRAPH = 'paragraph',
  HEADING = 'heading',
  LIST = 'list',
  LIST_ITEM = 'listItem',
  LINK = 'link',
  IMAGE = 'image',
  BLOCKQUOTE = 'blockquote',
  CODE = 'code',
  HTML = 'html',
  EMPHASIS = 'emphasis',
  STRONG = 'strong',
  DELETE = 'delete',
  INLINE_CODE = 'inlineCode',
  THEMATIC_BREAK = 'thematicBreak',
  TEXT = 'text',
  BREAK = 'break',
  LINKREFERENCE = 'linkReference',
  DEFINITION = 'definition',
  TABLE = 'table',
  TABLEROW = 'tableRow',
  TABLECELL = 'tableCell',
}

export type ApiError = {
  message: string;
  errors: Record<string, string[]>;
  internalError?: boolean;
};

export type PositionValues = {
  line: number;
  column: number;
  offset: number;
};

export enum HeadingTypes {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  H5 = 5,
  H6 = 6,
}

export type Ligatures = {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
};

export type Position = {
  start: PositionValues;
  end: PositionValues;
};

export type MarkdownNode = {
  type: MdastNodeType;
  children: MarkdownNode[];
  position: Position;
  ordered?: boolean;
  text?: string; // If Leaf node
  value?: string; // If Leaf node
  depth?: number; // If heading
  url?: string; // If link
  alt?: string; // If image
  title?: string; // If image
  referenceType: 'shortcut'; // if footnote
  label: string; // if footnote
  identifier: string; // if footnote
};

export type LeafType = {
  text: string;
  strikethrough?: boolean;
  underline?: boolean;
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
};

export const isLeafNode = (node: BlockType | LeafType): node is LeafType => {
  return typeof (node as LeafType).text === 'string';
};

export type BlockType = {
  type: NodeTypesEnum;
  parentType?: string;
  url?: string;
  caption?: string;
  language?: string;
  break?: boolean;
  children: (BlockType | LeafType)[];
};

export type Errors = {};

export type Auth = {
  user: User;
};

export type User = {
  id: number;
  name: string;
  email: string;
  language: any;
  first_document: number;
  current_project_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  org_id: number;
  organisation_role?: string;
  hash_id: string;
  last_active: string;
};

export type Usage = {
  words_usage: number;
  words_limit: number;
  words: boolean;
  reports_usage: number;
  reports_limit: number;
  reports: boolean;
  project_keywords_usage: number;
  project_keywords_limit: number;
  project_keywords: boolean;
  keyword_explorer_usage: number;
  keyword_explorer_limit: number;
  keyword_explorer: boolean;
  firstdraft_usage: number;
  firstdraft_limit: number;
  firstdraft: boolean;
  projects_limit: number;
  organisation_size_limit: number;
  start_date: string;
  end_date: string;
  products: Product[];
  valid: boolean;
  subscription: Subscription;
};

export type ContentItem = {
  isOpen: boolean;
  id?: string;
  title?: string;
  keyword?: string;
  url?: string;
};

export type ProjectState = {
  id: number;
  organisation_id: number;
  name: string;
  language_id: number;
  domain: string;
  tone_of_voice: string;
  audience: string;
  context: string;
  legacy: number;
  generating_keywords: number;
  is_crawling: boolean;
  crawl_started_at: string;
  last_crawled_at: string;
  deleted_at: string;
  id_hash: string;
};

export type Product = {
  id: number;
  name: string;
  is_active: number;
  is_hidden: number;
  price: number;
  stripe_price_id: string;
  stripe_product_id: string;
  position: number;
  interval: string;
  created_at: string;
  updated_at: string;
  pivot: Pivot;
};

export type Pivot = {
  subscription_id: number;
  product_id: number;
  quantity: number;
};

export type Subscription = {
  id: number;
  organisation_id: number;
  stripe_id: string;
  is_active: boolean;
  is_trialing: boolean;
  is_canceled: boolean;
  is_churned: boolean;
  has_scheduled_update: boolean;
  current_period_starting_at: string;
  current_period_ending_at: string;
  trial_ends_at: any;
  canceled_at: any;
  cancellation_details: any[];
  ends_at: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  products: Product2[];
};

export type Product2 = {
  id: number;
  name: string;
  is_active: number;
  is_hidden: number;
  price: number;
  stripe_price_id: string;
  stripe_product_id: string;
  position: number;
  interval: string;
  created_at: string;
  updated_at: string;
  pivot: Pivot2;
  limits: {
    organisation_user_limit: number;
    organisation_project_limit: number;
    seo_audit_limit: number;
    generated_article_limit: number;
    generated_words_limit: number;
    project_keyword_limit: number;
    keyword_explorer_limit: number;
    high_quality_ai_model: number;
  };
};

export type Pivot2 = {
  subscription_id: number;
  product_id: number;
  quantity: number;
};

export type Ziggy = {
  url: string;
  port: any;
  defaults: any[];
  routes: Routes;
};

export type PlateTypeNode = (BlockType | LeafType) & { id: string };

export type Routes = {
  'scramble.docs.index': ScrambleDocsIndex;
  'scramble.docs.api': ScrambleDocsApi;
  'dusk.login': DuskLogin;
  'dusk.logout': DuskLogout;
  'dusk.user': DuskUser;
  'horizon.stats.index': HorizonStatsIndex;
  'horizon.workload.index': HorizonWorkloadIndex;
  'horizon.masters.index': HorizonMastersIndex;
  'horizon.monitoring.index': HorizonMonitoringIndex;
  'horizon.monitoring.store': HorizonMonitoringStore;
  'horizon.monitoring-tag.paginate': HorizonMonitoringTagPaginate;
  'horizon.monitoring-tag.destroy': HorizonMonitoringTagDestroy;
  'horizon.jobs-metrics.index': HorizonJobsMetricsIndex;
  'horizon.jobs-metrics.show': HorizonJobsMetricsShow;
  'horizon.queues-metrics.index': HorizonQueuesMetricsIndex;
  'horizon.queues-metrics.show': HorizonQueuesMetricsShow;
  'horizon.jobs-batches.index': HorizonJobsBatchesIndex;
  'horizon.jobs-batches.show': HorizonJobsBatchesShow;
  'horizon.jobs-batches.retry': HorizonJobsBatchesRetry;
  'horizon.pending-jobs.index': HorizonPendingJobsIndex;
  'horizon.completed-jobs.index': HorizonCompletedJobsIndex;
  'horizon.silenced-jobs.index': HorizonSilencedJobsIndex;
  'horizon.failed-jobs.index': HorizonFailedJobsIndex;
  'horizon.failed-jobs.show': HorizonFailedJobsShow;
  'horizon.retry-jobs.show': HorizonRetryJobsShow;
  'horizon.jobs.show': HorizonJobsShow;
  'horizon.index': HorizonIndex;
  'log-viewer.hosts': LogViewerHosts;
  'log-viewer.folders': LogViewerFolders;
  'log-viewer.folders.download': LogViewerFoldersDownload;
  'log-viewer.folders.clear-cache': LogViewerFoldersClearCache;
  'log-viewer.folders.delete': LogViewerFoldersDelete;
  'log-viewer.files': LogViewerFiles;
  'log-viewer.files.download': LogViewerFilesDownload;
  'log-viewer.files.clear-cache': LogViewerFilesClearCache;
  'log-viewer.files.delete': LogViewerFilesDelete;
  'log-viewer.files.clear-cache-all': LogViewerFilesClearCacheAll;
  'log-viewer.files.delete-multiple-files': LogViewerFilesDeleteMultipleFiles;
  'log-viewer.logs': LogViewerLogs;
  'log-viewer.index': LogViewerIndex;
  'ignition.healthCheck': IgnitionHealthCheck;
  'ignition.executeSolution': IgnitionExecuteSolution;
  'ignition.updateConfig': IgnitionUpdateConfig;
  telescope: Telescope;
  'projects.documents.index': ProjectsDocumentsIndex;
  'projects.documents.show': ProjectsDocumentsShow;
  'projects.documents.link-suggestions.store': ProjectsDocumentsLinkSuggestionsStore;
  'document-generations.index': DocumentGenerationsIndex;
  'document-generations.store': DocumentGenerationsStore;
  'document-generations.show': DocumentGenerationsShow;
  'document-generations.generate.store': DocumentGenerationsGenerateStore;
  'document-generations.items.index': DocumentGenerationsItemsIndex;
  'document-generations.items.store': DocumentGenerationsItemsStore;
  'document-generations.refresh-outline.store': DocumentGenerationsRefreshOutlineStore;
  tutorial: Tutorial;
  'get-started': GetStarted;
  'keyword-research': KeywordResearch;
  'keyword-explorer': KeywordExplorer;
  account: Account;
  'create-project': CreateProject;
  'edit-project': EditProject;
  'finalize-project': FinalizeProject;
  'generating-project': GeneratingProject;
  project: Project;
  keywords: Keywords;
  documents: Documents;
  finalize: Finalize;
  processing: Processing;
  register: Register;
  register_invite: RegisterInvite;
  decline_invite: DeclineInvite;
  login: Login;
  'password.request': PasswordRequest;
  'password.email': PasswordEmail;
  'password.reset': PasswordReset;
  'password.update': PasswordUpdate;
  'google.redirect': GoogleRedirect;
  'google.callback': GoogleCallback;
  'verification.notice': VerificationNotice;
  'verification.verify': VerificationVerify;
  'verification.send': VerificationSend;
  logout: Logout;
  'moderator.shadowLogin': ModeratorShadowLogin;
  subscription: Subscription2;
  subscribe: Subscribe;
  end_trial: EndTrial;
  terms: Terms;
  'current-project': CurrentProject;
  'ai-keyword-explorer': AiKeywordExplorer;
  'ai-keyword-explorer.get': AiKeywordExplorerGet;
  'subscription-by-choice': SubscriptionByChoice;
  invite: Invite;
  'invite.cancel': InviteCancel;
  'invite.resend': InviteResend;
  'user.promote': UserPromote;
  'user.delete': UserDelete;
  'update.user': UpdateUser;
  'create.project': CreateProject2;
  'edit.project': EditProject2;
  'remove.keywords': RemoveKeywords;
  'add.keywords': AddKeywords;
  usage: Usage2;
  'diffbot.webhook': DiffbotWebhook;
};

export type ScrambleDocsIndex = {
  uri: string;
  methods: string[];
};

export type ScrambleDocsApi = {
  uri: string;
  methods: string[];
};

export type DuskLogin = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DuskLogout = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DuskUser = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonStatsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonWorkloadIndex = {
  uri: string;
  methods: string[];
};

export type HorizonMastersIndex = {
  uri: string;
  methods: string[];
};

export type HorizonMonitoringIndex = {
  uri: string;
  methods: string[];
};

export type HorizonMonitoringStore = {
  uri: string;
  methods: string[];
};

export type HorizonMonitoringTagPaginate = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonMonitoringTagDestroy = {
  uri: string;
  methods: string[];
  wheres: Wheres;
  parameters: string[];
};

export type Wheres = {
  tag: string;
};

export type HorizonJobsMetricsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonJobsMetricsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonQueuesMetricsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonQueuesMetricsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonJobsBatchesIndex = {
  uri: string;
  methods: string[];
};

export type HorizonJobsBatchesShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonJobsBatchesRetry = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonPendingJobsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonCompletedJobsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonSilencedJobsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonFailedJobsIndex = {
  uri: string;
  methods: string[];
};

export type HorizonFailedJobsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonRetryJobsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonJobsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type HorizonIndex = {
  uri: string;
  methods: string[];
  wheres: Wheres2;
  parameters: string[];
};

export type Wheres2 = {
  view: string;
};

export type LogViewerHosts = {
  uri: string;
  methods: string[];
};

export type LogViewerFolders = {
  uri: string;
  methods: string[];
};

export type LogViewerFoldersDownload = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFoldersClearCache = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFoldersDelete = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFiles = {
  uri: string;
  methods: string[];
};

export type LogViewerFilesDownload = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFilesClearCache = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFilesDelete = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type LogViewerFilesClearCacheAll = {
  uri: string;
  methods: string[];
};

export type LogViewerFilesDeleteMultipleFiles = {
  uri: string;
  methods: string[];
};

export type LogViewerLogs = {
  uri: string;
  methods: string[];
};

export type LogViewerIndex = {
  uri: string;
  methods: string[];
  wheres: Wheres3;
  parameters: string[];
};

export type Wheres3 = {
  view: string;
};

export type IgnitionHealthCheck = {
  uri: string;
  methods: string[];
};

export type IgnitionExecuteSolution = {
  uri: string;
  methods: string[];
};

export type IgnitionUpdateConfig = {
  uri: string;
  methods: string[];
};

export type Telescope = {
  uri: string;
  methods: string[];
  wheres: Wheres4;
  parameters: string[];
};

export type Wheres4 = {
  view: string;
};

export type ProjectsDocumentsIndex = {
  uri: string;
  methods: string[];
  parameters: string[];
  bindings: Bindings;
};

export type Bindings = {
  project: string;
};

export type ProjectsDocumentsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
  bindings: Bindings2;
};

export type Bindings2 = {
  project: string;
  document: string;
};

export type ProjectsDocumentsLinkSuggestionsStore = {
  uri: string;
  methods: string[];
  parameters: string[];
  bindings: Bindings3;
};

export type Bindings3 = {
  project: string;
  document: string;
};

export type DocumentGenerationsIndex = {
  uri: string;
  methods: string[];
};

export type DocumentGenerationsStore = {
  uri: string;
  methods: string[];
};

export type DocumentGenerationsShow = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DocumentGenerationsGenerateStore = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DocumentGenerationsItemsIndex = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DocumentGenerationsItemsStore = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DocumentGenerationsRefreshOutlineStore = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type Tutorial = {
  uri: string;
  methods: string[];
};

export type GetStarted = {
  uri: string;
  methods: string[];
};

export type KeywordResearch = {
  uri: string;
  methods: string[];
};

export type KeywordExplorer = {
  uri: string;
  methods: string[];
};

export type Account = {
  uri: string;
  methods: string[];
};

export type CreateProject = {
  uri: string;
  methods: string[];
};

export type EditProject = {
  uri: string;
  methods: string[];
};

export type FinalizeProject = {
  uri: string;
  methods: string[];
};

export type GeneratingProject = {
  uri: string;
  methods: string[];
};

export type Project = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type Keywords = {
  uri: string;
  methods: string[];
};

export type Documents = {
  uri: string;
  methods: string[];
};

export type Finalize = {
  uri: string;
  methods: string[];
};

export type Processing = {
  uri: string;
  methods: string[];
};

export type Register = {
  uri: string;
  methods: string[];
};

export type RegisterInvite = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type DeclineInvite = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type Login = {
  uri: string;
  methods: string[];
};

export type PasswordRequest = {
  uri: string;
  methods: string[];
};

export type PasswordEmail = {
  uri: string;
  methods: string[];
};

export type PasswordReset = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type PasswordUpdate = {
  uri: string;
  methods: string[];
};

export type GoogleRedirect = {
  uri: string;
  methods: string[];
};

export type GoogleCallback = {
  uri: string;
  methods: string[];
};

export type VerificationNotice = {
  uri: string;
  methods: string[];
};

export type VerificationVerify = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type VerificationSend = {
  uri: string;
  methods: string[];
};

export type Logout = {
  uri: string;
  methods: string[];
};

export type ModeratorShadowLogin = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type Subscription2 = {
  uri: string;
  methods: string[];
};

export type Subscribe = {
  uri: string;
  methods: string[];
};

export type EndTrial = {
  uri: string;
  methods: string[];
};

export type Terms = {
  uri: string;
  methods: string[];
};

export type CurrentProject = {
  uri: string;
  methods: string[];
};

export type AiKeywordExplorer = {
  uri: string;
  methods: string[];
};

export type AiKeywordExplorerGet = {
  uri: string;
  methods: string[];
};

export type SubscriptionByChoice = {
  uri: string;
  methods: string[];
};

export type Invite = {
  uri: string;
  methods: string[];
};

export type InviteCancel = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type InviteResend = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type UserPromote = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type UserDelete = {
  uri: string;
  methods: string[];
  parameters: string[];
};

export type UpdateUser = {
  uri: string;
  methods: string[];
};

export type CreateProject2 = {
  uri: string;
  methods: string[];
};

export type EditProject2 = {
  uri: string;
  methods: string[];
};

export type RemoveKeywords = {
  uri: string;
  methods: string[];
};

export type AddKeywords = {
  uri: string;
  methods: string[];
};

export type Usage2 = {
  uri: string;
  methods: string[];
};

export type DiffbotWebhook = {
  uri: string;
  methods: string[];
  parameters: string[];
  bindings: Bindings4;
};

export type Bindings4 = {
  project: string;
};

export type Document = {
  title: string;
  text: string;
  target: string;
  id: string;
  document_generation_id: number;
  document_id: number;
  _id: number;
  has_any_first_draft_running: boolean;
  result: KeywordInfo;
  language_name: string;
  language_id: number;
  tone: string;
  type: string;
  audience: string;
  brief: string;
  audience_options: any[];
  heading: string;
  meta_description: string;
  firstdraft_running: boolean;
  report_running: number;
  is_generating: boolean;
  locked: boolean;
  locked_user_name: string;
  project_id: string;
  document_generation_article: DocumentGenerationArticle;
};

export type DocumentGenerationArticle = {
  is_running: boolean;
};

export type Result = {
  serps: any[];
  max_length: number;
  max_score: number;
  date: string;
};

export type Language = {
  id: number;
  country_id: number;
  language: string;
  language_code: string;
  country_code: string;
  dfs_language_code: string;
  dfs_location_code: number;
  market_code: any;
  market_pretty?: string;
  flag: string;
  openai_max_chars: number;
  brief_length: number;
  created_at: string;
  updated_at: string;
  country: Country;
};

export type Country = {
  id: number;
  name: string;
  full_name: string;
  is_active: boolean;
  iso_3166_1: string;
  iso_3166_2: string;
  iso_3166_3: string;
  currency_code: string;
  currency_symbol?: string;
  currency_sub_unit: string;
  currency_decimals?: string;
  calling_code: string;
  created_at: string;
  updated_at: string;
};

export type Message = {
  id: number; // Both
  role: 'assistant' | 'user'; // Both
  content: string; // Both
  display_type: 'MESSAGE' | 'SELECTION'; // Selection only as user
  is_moderated: boolean; // Both
};

type HumanMessage = Omit<Message, 'role'> & { role: 'user' };

type AssistantMessage = Omit<Message, 'role|user_id|display_type'> & {
  role: 'assistant';
  user_id: null;
  display_type: 'MESSAGE';
};

type Setting = {
  text: string;
  dialog: boolean;
};

export type SettingsState = {
  internalName: Setting;
  tone: Setting;
  type: Setting;
  audience: Setting;
  brief: Setting;
};

export const isHumanMessage = (message: Message): message is HumanMessage => {
  return message.role === 'user';
};

export const isAssistantMessage = (
  message: Message,
): message is AssistantMessage => {
  return (message as AssistantMessage).role === 'assistant';
};

export type StreamData = string | '[DONE]';

export type StreamDataJSON = { content: string | null };

export type StreamPackage = {
  data?: StreamData;
};

export type StreamPackageJson = {
  content: string;
};

export type MessageErrorResponse = {
  ai_chat_id: string[];
};

export type AIStreamChatMessage = {
  content: string | null;
  isLoadingMessage: boolean;
  isStreamingMessage: boolean;
};

export type AiChat = {
  data: Chat;
};

export type Chat = {
  id: number;
  gpt_model: string;
};

export type BlockSelection = {
  highlightedSelection: BaseRange;
  selected: { text: string; showInChat: boolean };
};

export enum SlateTypes {
  paragraph = 'paragraph',
  heading_one = 'heading_one',
  heading_two = 'heading_two',
  heading_three = 'heading_three',
  heading_four = 'heading_four',
  heading_five = 'heading_five',
  heading_six = 'heading_six',
  block_quote = 'block_quote',
  ol_list = 'ol_list',
  ul_list = 'ul_list',
  list_item = 'list_item',
}

export type SlateBlockNode = {
  type: SlateTypes;
  id: number;
  children: (SlateBlockNode | SlateLeafNode)[];
};

export type SlateLinkNode = SlateLeafNode & {
  link: string;
  children: (SlateBlockNode | SlateLeafNode)[];
};

export type SlateLeafNode = {
  text: string;
  id?: number;
  italic?: boolean;
  bold?: boolean;
  link?: string;
};

export const isSlateLeafNode = (
  node: SlateBlockNode | SlateLeafNode,
): node is SlateLeafNode => {
  return (node as SlateLeafNode).text !== undefined;
};

export const isPlateTypeAlready = (node: BlockType | SlateBlockNode) => {
  if (Object.values(NodeTypesEnum).includes(node.type as any)) {
    return true;
  } else {
    return false;
  }
};

export enum ItemTypes {
  SNIPPET = 'snippet',
}

export type ProjectKeywordKeyword = {
  name: string;
  search_volume: number;
  difficulty: number;
  documents: Document[];
};

export type ProjectKeyword = {
  documents_count: number;
  id: number;
  keyword: ProjectKeywordKeyword;
};

export {};

export type SearchIntents = {
  questions: Question[];
  document: string;
};

export type Question = {
  id: number;
  item: string;
  selected: boolean;
};

type HighestSEOScore = number;

export type KeywordInfo = [
  WSKeywords[],
  Question[],
  number,
  boolean,
  boolean,
  WSSerpData,
  [],
  HighestSEOScore,
];
export type SendSearchResult = {
  text: KeywordInfo;
  document: string;
};

export type WSSerpData = {
  serps: Serp[];
  max_length: number;
  max_score: number;
  date: string;
};

export type Serp = {
  url: string;
  title: string;
  position: number;
  length: number;
  score: number;
  outbound_links: number;
  semantic_keywords: number;
};

export type WSKeywords = {
  id: number;
  item: string;
  selected: boolean;
  used: boolean;
};

export type PostPromptAutoGenerate = {
  body:
    | {
        content: string;
      }
    | PostPromptRequest;
  heading?: string;
};

export type PostPromptRequest = {
  type: 'question' | 'write-more';
  document_id: string;
  text: string;
  custom_prompt: '';
  keywords: string;
  length: 's';
};

export type ExtendedTElement = TElement & { id: string };

export type EditorUniqueID = string;

export type ChatUniqueID = number;

export const isEditorUniqueID = (
  id: EditorUniqueID | ChatUniqueID,
): id is EditorUniqueID => {
  return typeof id === 'string';
};

export type ActiveDraggableState = {
  activeElement: {
    id: EditorUniqueID | ChatUniqueID;
    text: string;
  };
  hoveredElementID: EditorUniqueID | ChatUniqueID | undefined;
};

type LengthType = number | string;

export type LengthObject = {
  value: number;
  unit: string;
};

type CommonProps = {
  color?: string;
  loading?: boolean;
  cssOverride?: React.CSSProperties;
  speedMultiplier?: number;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export type LoaderSizeMarginProps = {
  size?: LengthType;
  margin?: LengthType;
} & CommonProps;

export type InternalLinkingResource = {
  linkResource: LinkSuggestionResource;
  accepted: boolean;
  isHoveredFromTab: boolean;
  isHoveredFromEditor: boolean;
  anchorTextStartIndex: number | null;
  anchorTextEndIndex: number | null;
  rejected: boolean;
  ignored: boolean;
};
export type InternalLinkingStore = {
  isLoading: boolean;
  hasNoMatches: boolean;
  data: InternalLinkingResource[];
  isCached: boolean;
  hardReset: () => void;
  softReset: () => void;
  isLoadingInternalLinks: () => void;
  populateInternalLinks: (
    internalLinkingSuggestions: LinkSuggestionResource[],
    content: string,
  ) => void;
  handleHoverInternalLinkFromEditor: (
    resource: LinkSuggestionResource,
    isHoveredFromEditor: boolean,
  ) => void;
  handleHoverInternalLinkFromTab: (
    resource: LinkSuggestionResource,
    isHoveredFromTab: boolean,
  ) => void;
  handleAcceptInternalLink: (resource: LinkSuggestionResource) => void;
  handleRemoveInternalLink: (resource: LinkSuggestionResource) => void;
  handleRejectInternalLink: (resource: LinkSuggestionResource) => void;
  handleIgnoreInternalLink: (resource: LinkSuggestionResource) => void;
  handleChangeSentence: (
    linkingResource: InternalLinkingResource,
    firstPart: string,
    secondPart: string,
    operationPart?: string,
  ) => void;
  handleChangeAnchorText: (
    resource: InternalLinkingResource,
    index: number,
  ) => void;
};
export {};

export type ContentCreatedTable = {
  isGenerating: boolean;
  id: number;
  targetScore: number | null;
  hash_id: string;
  title: string;
  keyword: string;
  seoScore: number;
  tags?: OrganisationTagResource[];
  wordCount: string;
  buttons: React.ReactNode;
};

export type CurrentSlideOver =
  | 'internal-linking'
  | 'title-score'
  | 'document-length'
  | 'meta-description'
  | 'questions'
  | 'external-links'
  | 'keyword-injection'
  | 'outline-builder'
  | 'meta-title'
  | 'keyword-gap';

export const isBaseElement = (
  node: BaseEditor | BaseText | BaseElement,
): node is BlockType => {
  return (
    (node as BaseElement).children !== undefined &&
    (node as any).addMark === undefined
  );
};

export const isBaseEditor = (
  node: BaseEditor | BaseText | BaseElement,
): node is BaseEditor => {
  return (node as BaseEditor).addMark !== undefined;
};

export const isBaseText = (
  node: BaseEditor | BaseText | BaseElement,
): node is LeafType => {
  return (node as BaseText).text !== undefined;
};

export const isNotDocumentReportCompetitorResource = (
  node:
    | DocumentReportCompetitorResource
    | { id: number; title: string; description: string; url: string },
): node is { id: number; title: string; description: string; url: string } => {
  return (
    (node as { id: number; title: string; description: string; url: string })
      .id !== undefined
  );
};

export const isInternalLinkingResource = (
  node:
    | InternalLinkingResource
    | ExtendedKeywordSuggestion
    | ExtendedNewKeywordSuggestion,
): node is InternalLinkingResource => {
  return (node as InternalLinkingResource).isHoveredFromTab !== undefined;
};

export const isExtendedKeywordSuggestion = (
  node:
    | InternalLinkingResource
    | ExtendedKeywordSuggestion
    | ExtendedNewKeywordSuggestion,
): node is ExtendedKeywordSuggestion => {
  return (node as ExtendedKeywordSuggestion).markdownText !== undefined;
};

export const isExtendedNewKeywordSuggestion = (
  node:
    | InternalLinkingResource
    | ExtendedKeywordSuggestion
    | ExtendedNewKeywordSuggestion,
): node is ExtendedNewKeywordSuggestion => {
  return (
    (node as ExtendedKeywordSuggestion).markdownText === undefined &&
    (node as ExtendedNewKeywordSuggestion).text !== undefined
  );
};

export type SidebarItem<T> = {
  name: string;
  href: string;
  search?: TanStackSearchParams<T>;
  current: boolean;
  disabled?: boolean;
  tooltip?: {
    text: string;
    navLink?: string;
    navLinkText?: string;
  };
  icon: any;
};

export type SidebarMenu<T> = {
  name: string;
  icon: React.ReactNode;
  items: {
    name: string;
    href: string;
    search?: TanStackSearchParams<T>;
    current: boolean;
    disabled?: boolean;
    tooltip?: {
      text: string;
      navLink?: string;
      navLinkText?: string;
    };
    icon: any;
  };
};

export const isSubMenu = <T>(
  item: SidebarItem<T> | SidebarMenu<T>,
): item is SidebarMenu<T> => {
  return (item as SidebarMenu<T>).items !== undefined;
};

export const isContentListItem = (
  item:
    | ContentListResource
    | {
        name: string;
      },
): item is ContentListResource => {
  return (item as ContentListResource).id !== undefined;
};

export type Organisation = {
  id: number;
  organisation_id: number;
  name: string;
  language_id: number;
  domain: string;
  tone_of_voice: string;
  audience: string;
  context: string;
  legacy: number;
  generating_keywords: number;
  is_crawlable: number;
  is_crawling: boolean;
  crawl_started_at?: any;
  last_crawled_at?: any;
  deleted_at?: any;
  id_hash: string;
  show: boolean;
};
export type HomeCurrentProject = {
  id: number;
  organisation_id: number;
  name: string;
  language_id: number;
  domain: string;
  tone_of_voice: string;
  audience: string;
  context: string;
  legacy: number;
  generating_keywords: number;
  is_crawlable: number;
  is_crawling: boolean;
  crawl_started_at?: any;
  last_crawled_at?: any;
  deleted_at?: any;
  id_hash: string;
};

export type HomeProps = {
  auth: Auth;
  usage: Usage;
  ziggy: Ziggy;
  email: string;
  errors: {};
  internal_links_count: 0;
  page: 'dashboard';
  project_id: string;
  projects: Organisation[];
  csrf_token: string;
  current_project: HomeCurrentProject;
  subscription: Subscription | null;
};

export type SerpVisualizerLogic = {
  metaTitle: string;
  metaDescription: string;
};

export type SerpDebounceProps = {
  setMetaTitle: (heading: string) => void;
  setMetaDescription: (metaDescription: string) => void;
};

export type TaskCompleted = {
  task: string;
  completed: boolean;
};
export type PromptInfo = {
  title: string;
  type: string;
  Icon: LucideIcon;
};

export type PlaceHolder = {
  keyword: string;
  title: string;
  url: string;
  audience: string;
  outlinePrompt: string;
};

export type TitleSection = {
  title: string;
  isLoadingTitles: boolean;
  data: DocumentTitleResource[];
  customPrompt?: boolean;
};

export type SerpState = {
  id: number;
  title: string;
  description: string;
  url: string;
};

export type KeywordInjectionTabs = 'UPDATE EXISTING' | 'NEW PARAGRAPHS';
export type Template = {
  mode: 'ADD' | 'UPDATE';
  isOpen: boolean;
  input: string;
  selectedTemplate: ComboBoxItem | null;
  error: boolean;
};

export type KeywordSuggestion = {
  text: string;
  markdownText: string;
  newMarkdownText: string;
};

export type ExtendedKeywordSuggestion = KeywordSuggestion & {
  hoveredFromEditor: boolean;
  hoveredFromTab: boolean;
  accepted: boolean;
  rejected: boolean;
};

export type ExtendedNewKeywordSuggestion = Omit<
  ExtendedKeywordSuggestion,
  'markdownText'
> & {
  hoveredFromEditor: boolean;
  hoveredFromTab: boolean;
  accepted: boolean;
};

type DocumentSuggestionContent = { markdown: string; text: string };
export type DocumentSuggestionEventPayload = {
  uuid: string;
  current: DocumentSuggestionContent;
  keyword: string;
  new: DocumentSuggestionContent;
  suggestion_type: ContentSuggestionType;
};

export type BrandVoiceControllerState = {
  id: number;
  input: string;
  isOpen: boolean;
  urls: string[];
  mode: 'EDIT' | 'ADD' | 'EDIT_NAME';
};

export type KeywordInjectionStore = {
  keywordType: 'missing' | 'semantic' | 'secondary' | undefined;
  keyword: string;
  noKeywordSuggestionsFound: boolean;
  noNewKeywordSuggestionsFound: boolean;
  noMoreKeywordSuggestions: boolean;
  noMoreNewKeywordSuggestions: boolean;
  mode: KeywordInjectionTabs | undefined;
  keywordSuggestions: ExtendedKeywordSuggestion[];
  newKeywordSuggestions: ExtendedNewKeywordSuggestion[];
  isLoadingUpdatedKeywordSuggestions: boolean;
  isLoadingAddedKeywordSuggestions: boolean;
  handleChangeUpdatedLoading: (isLoading: boolean) => void;
  handleChangeAddedLoading: (isLoading: boolean) => void;
  handleInitialLoadSuggestions: () => void;
  handleChangeTab: (value: KeywordInjectionTabs) => void;
  hardResetSlideOver: () => void;
  resetSlideOver: (
    keyword: string,
    keywordType?: 'missing' | 'semantic' | 'secondary',
  ) => void;
  changeKeywordType: (
    Keywordtype: 'missing' | 'semantic' | 'secondary',
  ) => void;
  addKeywordSuggestion: (
    suggestion: KeywordSuggestion,
    editor: PlateEditor,
    content: string,
  ) => void;
  addNewKeywordSuggestion: (
    suggestion: Omit<KeywordSuggestion, 'markdownText'>,
    editor: PlateEditor,
    content: string,
  ) => void;
  removeKeywordSuggestion: (suggestion: KeywordSuggestion) => void;
  removeNewKeywordSuggestion: (
    suggestion: Omit<KeywordSuggestion, 'markdownText'>,
  ) => void;
  handleHoverFromEditor: (
    keywordSuggestion: KeywordSuggestion,
    isHovered: boolean,
  ) => void;
  handleHoverFromTab: (
    keywordSuggestion: KeywordSuggestion,
    isHovered: boolean,
  ) => void;
  handleHoverNewKeywordPositionFromTab: (
    keywordSuggestion: Omit<KeywordSuggestion, 'markdownText'>,
    isHovered: boolean,
  ) => void;
  handleHoverNewKeywordPositionFromEditor: (
    keywordSuggestion: Omit<KeywordSuggestion, 'markdownText'>,
    isHovered: boolean,
  ) => void;
  handleAcceptSuggestion: (keywordSuggestion: KeywordSuggestion) => void;
  handleAcceptNewSuggestion: (
    keywordSuggestion: Omit<KeywordSuggestion, 'markdownText'>,
  ) => void;
  handleRejectSuggestion: (keywordSuggestion: KeywordSuggestion) => void;
  handleRejectNewSuggestion: (
    keywordSuggestion: Omit<KeywordSuggestion, 'markdownText'>,
  ) => void;
};

export type TanStackSearchParams<T, F = {}> = {
  filters?: {
    keyword?: string;
    search?: string;
    tag_mode?: 'all' | 'any';
    tags?: number[];
  } & F;
  limit?: number;
  page?: number;
  sortBy?: T;
  sortDirection?: 'ASC' | 'DESC';
  sortName?: string;
};

export type WordPressSettings = {
  token: string;
  url: string;
  username: string;
  mode: 'ADD_CONNECTION' | 'EDIT_CONNECTION';
};

export type ExportOptions = {
  isOpen: boolean;
  type: ConnectionType;
};

export type GoogleDocsStatusSettings = {
  isOpen: boolean;
  mode: 'success' | 'failed';
};

export type FloatingAiInputType = {
  id: number;
  selection: BaseSelection;
};

export type LoginSearchParams = {
  token?: string;
};
