import { Popover } from '@/Components/v2/Menu/Popover';
import { formatThousandSeperator } from '@/utils';

type Props = {
  value: number | string;
  growth: {
    growth: number | null;
    difference: number;
  };
  responsive?: boolean;
};

export const ValueWithGrowth = ({
  growth,
  value,
  responsive = true,
}: Props) => {
  return (
    <div className="flex flex-shrink-0 items-center gap-1">
      <div className={responsive ? '2xl:w-[3vw]' : ''} />
      <Popover
        disabled={growth.growth === null}
        type="hover"
        side="left"
        trigger={
          <div className="text-primary">
            {typeof value === 'number'
              ? formatThousandSeperator(value ?? 0)
              : value}
          </div>
        }
      >
        <div className="flex flex-col gap-2 p-4 text-primary">
          <p className="font-bold text-primary">Difference:</p>
          {growth.difference > 0 ? '+' : ''}
          {growth.difference.toFixed(2).replace('.00', '')}
        </div>
      </Popover>
      <div
        className={`w-10 text-xs ${growth.growth > 0 ? 'text-green-700' : ''} ${growth.growth < 0 ? 'text-red' : ''}`}
      >
        {growth.growth !== null && growth.growth > 0 && '+'}
        {growth.growth !== null && `${Number(growth.growth)?.toFixed(0)}%`}
      </div>
    </div>
  );
};
