import { ChevronRight } from 'lucide-react';
import { Checkmark } from './Checkmark';

type Props = {
  title: string;
  errorCount: number;
  onClick: () => void;
};

export const RecommendationCard = ({ errorCount, title, onClick }: Props) => {
  return (
    <div
      className="flex cursor-pointer overflow-hidden rounded-lg bg-white shadow hover:opacity-60"
      onClick={onClick}
    >
      <div className="flex flex-grow flex-col overflow-hidden p-4">
        <div className="text-sm uppercase">{title}</div>

        <div className="mt-4 flex items-center gap-2">
          <Checkmark checked={errorCount === 0} />
          {errorCount > 0 ? (
            <p className="text-red">{errorCount} errors</p>
          ) : (
            <p className="text-green">Optimised</p>
          )}
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center p-2">
        <ChevronRight className="h-6 w-6 text-primary" />
      </div>
    </div>
  );
};
