import * as React from 'react';
import { SlideOver } from '@/Components/SlideOver';
import { RefreshCcw, ThumbsUp } from 'lucide-react';
import { SlideOverContentBox } from '@/Components/SlideOver/components';
import { useEditor } from '@/Components/Utils/v2/api';
import {
  BlockType,
  InternalLinkingResource,
  LeafType,
  NodeTypesEnum,
  isLeafNode,
} from '@/types';
import { PlateEditor, wrapNodes } from '@udecode/plate-common';
import {
  removeLigaturesFromMarkdown,
  serializeToMarkdown,
} from '../../../PlateEditor/lib/serialize';
import { trimPunctuation } from '../../utils';
import { twMerge } from 'tailwind-merge';
import { checkIfEndsWithSpecialCharacters } from '@/Pages/Document/utils';
import {
  getDocumentText,
  getKeywordPositionInParagraph,
  getParagraphText,
  isParagraphNode,
} from '../../../PlateEditor/lib/utils';
import { BaseRange } from 'slate';
import LinkSuggestionStatus from './components/LinkSuggestionStatus';
import AcceptedAnimation from './components/AcceptedAnimation';
import { TaskItem } from '@/Components/TaskItem';
import { DocumentReportResource, ProjectResource } from '@/api/openapiSchemas';
import { SuggestedActions } from './components/SuggestionActions';
import {
  useGetEditorFocusElement,
  useGetLinkSuggestions,
} from '@/Pages/Document/hooks';
import { tracking } from '@/Services/tracking/Tracking';
import { useInternalLinkingStore } from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import { Button } from '@/Components/v2/Button';
import { useProjectsDomainUrlsUpdate } from '@/api/openapiComponents';
import { RejectedAnimation } from './components/RejectedAnimation';

type Props = {
  editor: PlateEditor | null;
  documentId: number;
  documentReport?: DocumentReportResource;
  project: ProjectResource;
  isOpen: boolean;
  onClose: () => void;
};

export function InternalLinkingSlideOver({
  documentId,
  documentReport,
  project,
  onClose,
  isOpen,
}: Props) {
  const searchParams = new URLSearchParams(window.location.search);
  const debug = searchParams.get('debug');

  const getLinkSuggestions = useGetLinkSuggestions();
  const {
    data,
    isLoading,
    hasNoMatches,
    handleHoverInternalLinkFromTab,
    handleRemoveInternalLink,
    handleResetLinkSuggestions,
    handleChangeAnchorText,
    handleAcceptInternalLink,
    handleRejectInternalLink,
    handleHardResetInternalLinks,
    handleIgnoreInternalLink,
  } = useInternalLinkingStore(
    useShallow((state) => ({
      data: state.data,
      isLoading: state.isLoading,
      hasNoMatches: state.hasNoMatches,
      handleIgnoreInternalLink: state.handleIgnoreInternalLink,
      handleHoverInternalLinkFromTab: state.handleHoverInternalLinkFromTab,
      handleRemoveInternalLink: state.handleRemoveInternalLink,
      handleResetLinkSuggestions: state.softReset,
      handleChangeAnchorText: state.handleChangeAnchorText,
      handleAcceptInternalLink: state.handleAcceptInternalLink,
      handleRejectInternalLink: state.handleRejectInternalLink,
      handleHardResetInternalLinks: state.hardReset,
    })),
  );
  const editor = useEditor();
  const editorFocusRef = useGetEditorFocusElement();
  const { mutate: updateTargetIsActive } = useProjectsDomainUrlsUpdate();

  React.useEffect(() => {
    return () => {
      handleHardResetInternalLinks();
    };
  }, []);

  const handleClose = () => {
    onClose();
    handleResetLinkSuggestions();
  };

  const addAnchorToText = (
    location: {
      anchor: { path: number[]; offset: number };
      focus: { path: number[]; offset: number };
    },
    url: string,
    anchorText: string,
  ) => {
    if (editor) {
      wrapNodes(
        editor,
        {
          type: NodeTypesEnum.A,
          url,
          target: undefined,
          children: [{ text: anchorText }],
        },
        { at: location, split: true },
      );
    }
  };

  const isNotInDocument = (lr: InternalLinkingResource) =>
    !getDocumentText(editor?.children as BlockType[]).includes(
      lr.linkResource.sentence,
    );

  const handleAddLinkToDocument = (lr: InternalLinkingResource) => {
    const positions = getKeywordPositionInParagraph(editor, lr);
    const keyword = lr.linkResource.sentence
      .split(' ')
      .slice(lr.anchorTextStartIndex!, lr.anchorTextEndIndex! + 1)
      .join(' ')
      .replace(trimPunctuation, '');

    addAnchorToText(positions as BaseRange, lr.linkResource.link.url, keyword);
  };

  const keywordIsNotInSentence = (lr: InternalLinkingResource) => {
    const result = editor
      ?.nodes<BlockType | LeafType>({
        at: {
          anchor: { path: [0], offset: 0 },
          focus: { path: [editor.children.length - 1], offset: 0 },
        },
        match: (node) =>
          (isParagraphNode(node) ||
            (node as BlockType).type === NodeTypesEnum.BLOCKQUOTE) &&
          getParagraphText((node as BlockType).children).includes(
            lr.linkResource.sentence,
          ),
      })
      .next();
    if (result?.value) {
      const node = result.value[0];
      if (!isLeafNode(node)) {
        const paragraphText = getParagraphText(node.children);
        const keyword = lr.linkResource.sentence
          .split(' ')
          .slice(lr.anchorTextStartIndex!, lr.anchorTextEndIndex!)
          .join(' ');
        if (paragraphText.includes(keyword)) {
          return false;
        }
      }
      return true;
    }
    return true;
  };

  const trimWord = (word: string) => word.replace(trimPunctuation, '');

  const renderAcceptAllButton = () => {
    if (hasNoMatches || (!isLoading && data.length === 0)) {
      return undefined;
    }

    return {
      icon: <ThumbsUp />,
      onClick: () => {
        data.forEach((lr) => {
          handleAddLinkToDocument(lr);
          handleRemoveInternalLink(lr.linkResource);
        });

        tracking.event('link_suggestions_accept_all', {
          suggestion_count: data.length,
        });

        handleClose();
      },
      text: 'ACCEPT ALL',
      isLoading: isLoading,
    };
  };

  const debugIsActive = debug === 'links' && data.length > 0 && editor;
  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleClose}
      title="Internal Linking"
      subHeading="Internal links aides Google's indexing of your site and reinforcing keyword relevance across"
      color={'bg-violet-500'}
      handleButton={renderAcceptAllButton()}
    >
      {documentReport && (
        <TaskItem
          completed={!!documentReport?.internal_links.is_completed}
          task="Used internal link"
        />
      )}
      <div className=" font-medium text-gray-600">
        <div className="mb-4 flex items-center justify-between">
          {isLoading ? (
            <LinkSuggestionStatus
              status="Searching"
              title="Searching for internal links"
            />
          ) : (
            <div className="w-full">
              <div className="flex items-center justify-between">
                <p className={'mt-5'}>
                  {data.length !== 0 && (
                    <>
                      <span className="font-bold">{data.length}</span> internal
                      link suggestions to review
                    </>
                  )}
                </p>
              </div>
              {hasNoMatches && (
                <LinkSuggestionStatus
                  status="Not Found"
                  title="Sorry, We couldn't find any matches!"
                />
              )}
              {!isLoading && !hasNoMatches && data.length === 0 && (
                <LinkSuggestionStatus
                  status="Links Reviewed"
                  title="All suggestions reviewed"
                />
              )}
            </div>
          )}
        </div>
        {data.map((lr, i) => {
          const startIndex = lr.anchorTextStartIndex;
          const endIndex = lr.anchorTextEndIndex;

          return (
            <div
              ref={(e) =>
                lr.isHoveredFromEditor &&
                e?.scrollIntoView({ behavior: 'instant', block: 'center' })
              }
              key={`${lr.linkResource.link.id} - ${lr.linkResource.keyword} - ${lr.linkResource.link.url} - ${i}`}
              className={twMerge(
                `relative mb-6 rounded border-2 pb-4 pt-0 text-sm hover:border-fuchsia-400`,
                lr.isHoveredFromEditor ? 'border-fuchsia-400' : '',
              )}
              onMouseEnter={() => {
                if (!lr.accepted && !lr.rejected && !isNotInDocument(lr)) {
                  const positions = getKeywordPositionInParagraph(editor, lr);
                  editorFocusRef.focus();
                  editor?.select({
                    anchor: positions!.focus!,
                    focus: positions!.focus!,
                  });
                  handleHoverInternalLinkFromTab(lr.linkResource, true);
                }
              }}
              onMouseLeave={() => {
                handleHoverInternalLinkFromTab(lr.linkResource, false);
                editor?.deselect();
              }}
            >
              {lr.accepted && <AcceptedAnimation accepted={lr.accepted} />}

              {(isNotInDocument(lr) || lr.rejected) && (
                <RejectedAnimation
                  rejected={lr.rejected}
                  canIgnore={!lr.ignored}
                  onIgnore={() => {
                    updateTargetIsActive({
                      pathParams: {
                        project: project.id,
                        domainUrl: lr.linkResource.link.id,
                      },
                      body: {
                        is_active: false,
                        primary_keyword: lr.linkResource.link.primaryKeyword,
                      },
                    });
                    handleIgnoreInternalLink(lr.linkResource);
                  }}
                />
              )}

              <div className="px-4">
                <SlideOverContentBox title="ANCHOR TEXT">
                  <p className="">
                    {removeLigaturesFromMarkdown(lr.linkResource.sentence)
                      .split(' ')
                      .map((word, i, array) => {
                        if (startIndex === null || endIndex === null) {
                          return (
                            <span
                              key={word + ' ' + i}
                              onClick={() => handleChangeAnchorText(lr, i)}
                              className="break-words hover:cursor-pointer"
                            >
                              {trimWord(word)}{' '}
                            </span>
                          );
                        }
                        if (i >= startIndex && endIndex >= i) {
                          if (
                            array.length - 1 === i &&
                            trimPunctuation.test(word)
                          ) {
                            return (
                              <span
                                key={word + ' ' + i}
                                onClick={() => handleChangeAnchorText(lr, i)}
                                className="hover:cursor-pointer"
                              >
                                <span className="bg-fuchsia-100 font-extrabold text-black underline hover:cursor-pointer">
                                  {trimWord(word)}
                                </span>
                                <span>{word.match(trimPunctuation)![0]}</span>
                              </span>
                            );
                          }
                          return (
                            <span
                              key={word + ' ' + i}
                              onClick={() => handleChangeAnchorText(lr, i)}
                              className="bg-fuchsia-100 font-extrabold text-black underline hover:cursor-pointer"
                            >
                              {i === endIndex ? (
                                <>
                                  {checkIfEndsWithSpecialCharacters(word) ? (
                                    <>
                                      {word.slice(0, word.length - 1)}
                                      <span className="bg-white  font-normal text-gray-600 no-underline hover:cursor-default">
                                        {word[word.length - 1]}
                                      </span>
                                    </>
                                  ) : (
                                    word
                                  )}
                                </>
                              ) : (
                                <>{word} </>
                              )}
                            </span>
                          );
                        }
                        return (
                          <span
                            key={word + ' ' + i}
                            onClick={() => handleChangeAnchorText(lr, i)}
                            className="break-words hover:cursor-pointer"
                          >
                            {word}{' '}
                          </span>
                        );
                      })
                      .reduce((prev, curr) => [prev, ' ', curr] as any)}
                  </p>
                </SlideOverContentBox>
                <SlideOverContentBox title="LINK TO THIS DESTINATION">
                  <>
                    <h6 className=" font-bold text-black">
                      {lr.linkResource.link.title}
                    </h6>
                    <a
                      href={lr.linkResource.link.url}
                      className=" underline underline-offset-4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {lr.linkResource.link.url}
                    </a>
                    <SuggestedActions
                      onAccept={() => {
                        handleAddLinkToDocument(lr);
                        handleAcceptInternalLink(lr.linkResource);
                        tracking.event('link_suggestions_accepted', {
                          link: lr.linkResource,
                        });
                      }}
                      onReject={() => {
                        tracking.event('link_suggestions_rejected', {
                          link: lr.linkResource,
                        });
                        handleRejectInternalLink(lr.linkResource);
                      }}
                      isDisabled={keywordIsNotInSentence(lr)}
                    />
                  </>
                </SlideOverContentBox>
              </div>
            </div>
          );
        })}

        {!isLoading && (
          <Button
            onClick={() => {
              if (editor) {
                getLinkSuggestions({
                  pathParams: {
                    document: documentId,
                    project: project.id,
                  },
                  body: {
                    document: serializeToMarkdown(editor.children),
                  },
                });
                tracking.event('link_suggestions_retry', {
                  suggestion_count: data.length,
                });
              }
            }}
            prependIcon={RefreshCcw}
            text="RETRY"
            dense
            variant="ghost"
            size="sm"
          />
        )}
        {debugIsActive && (
          <>
            <pre>{JSON.stringify(data)}</pre>
            <pre>{serializeToMarkdown(editor?.children)}</pre>
          </>
        )}
      </div>
    </SlideOver>
  );
}
