import * as React from 'react';
import BeatLoader from '@/Components/BeatLoader';
import { CornerDownRight, Pencil, SendHorizontal } from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Button } from '@/Components/v2/Button';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
  text: string;
  id: number;
  isLoading?: boolean;
  disabled?: boolean;
  selectedText?: string;
  onSubmitEdit: (messageId: number, text: string) => void;
};

export function HumanMessageV2({
  text,
  id,
  isLoading,
  disabled,
  selectedText,
  onSubmitEdit,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedMessageValue, setEditedMessageValue] = React.useState('');

  const handleEdit = () => {
    setIsEditing(true);
    setEditedMessageValue(text);
  };

  const handleSubmitEdit = () => {
    onSubmitEdit(id, editedMessageValue);
    setIsEditing(false);
  };

  return (
    <>
      <div className="group pl-24">
        <div className="relative rounded-2xl bg-primary-150">
          {selectedText && (
            <div className="p-2">
              <div className="rounded-lg bg-primary-50 p-2">
                <p className="font-bold">Seleced text</p>
                <div className="max-h-48 overflow-y-auto">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="prose text-lg"
                  >
                    {selectedText}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          )}
          {isEditing ? (
            <div className="relative p-2">
              <TextArea
                value={editedMessageValue}
                rows={5}
                maxHeight={15}
                resize
                onChange={(value) => setEditedMessageValue(value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (e.shiftKey) {
                      return;
                    }
                    e.preventDefault();

                    handleSubmitEdit();
                  }
                }}
                appendIcon={
                  <IconButton
                    icon={SendHorizontal}
                    onClick={handleSubmitEdit}
                    color="secondary"
                  />
                }
              />
            </div>
          ) : (
            <div className="flex flex-col gap-1 p-6">
              <p className="whitespace-pre-wrap text-lg">
                {text}
                {isLoading && (
                  <BeatLoader
                    className="mt-6"
                    size={'0.5rem'}
                    color="rgb(74 222 128 / 1)"
                  />
                )}
              </p>
            </div>
          )}
          <div className="absolute bottom-0 right-full top-0 pr-1 opacity-0 group-hover:opacity-100">
            <IconButton
              color="primary"
              onClick={handleEdit}
              disabled={disabled}
              icon={Pencil}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 text-primary-400">
        {' '}
        <CornerDownRight size={16} /> You asked
      </div>
    </>
  );
}
