import {
  useAnalyzePage,
  useCreateDocument,
  useDocumentShopifyImport,
  useProjectContentScannerUpdate,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { hasher, useFeatureFlagging } from '../AppLoader';
import { useState } from 'react';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectUrlResource } from '@/api/openapiSchemas';

export const useAllPageActions = () => {
  const appState = useAppStore();

  const client = useQueryClient();

  const [isLoadingForId, setIsLoadingForId] = useState<number>();

  const navigate = useNavigate();
  const { hasFeature } = useFeatureFlagging();

  const scanSpecificUrlsMutation = useProjectContentScannerUpdate();
  const importMutation = useAnalyzePage();
  const createDocumentMutation = useCreateDocument();
  const importFromShopifyMutation = useDocumentShopifyImport();
  const { showSnackbar } = useSnackbar();

  const handleError = () => {
    setIsLoadingForId(undefined);
    showSnackbar({ color: 'red', message: 'An error occurred' });
  };

  const handleImport = (url: ProjectUrlResource, keyword: string) => {
    setIsLoadingForId(url.id);

    if (url.cms.has_reference && hasFeature('shopify-cms-integration')) {
      createDocumentMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body: {
            project_url_id: url.id,
            keyword,
          },
        },
        {
          onError: handleError,
          onSuccess: (data) => {
            importFromShopifyMutation.mutate(
              {
                pathParams: {
                  document: data.data.id,
                  project: appState.currentProject!.id,
                },
              },
              {
                onError: handleError,
                onSuccess: (data) => {
                  navigate({
                    to: '/documents/$documentId',
                    params: { documentId: hasher.encode(data.data.id) },
                  });
                },
              },
            );
          },
        },
      );
      return;
    }
    importMutation.mutate(
      {
        body: {
          url: url.url,
        },
      },
      {
        onError: handleError,
        onSuccess: (data) => {
          createDocumentMutation.mutate(
            {
              pathParams: {
                project: appState.currentProject!.id,
              },
              body: {
                keyword: keyword || data.data.target_keyword,
                content: data.data.content,
                meta_description: data.data.meta_description,
                meta_title: data.data.meta_title,
                title: data.data.title,
                project_url_id: url.id,
              },
            },
            {
              onError: handleError,
              onSuccess: (data) => {
                navigate({
                  to: '/documents/$documentId',
                  params: { documentId: hasher.encode(data.data.id) },
                });
              },
            },
          );
        },
      },
    );
  };

  const handleBlankEditor = (id: number, url: string, keyword: string) => {
    if (!keyword) {
      return showSnackbar({ color: 'red', message: 'Please enter a keyword' });
    }
    setIsLoadingForId(id);
    createDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          url,
          keyword,
        },
      },
      {
        onSuccess: (data) => {
          navigate({
            to: '/documents/$documentId',
            params: { documentId: hasher.encode(data.data.id) },
          });
        },
      },
    );
  };

  const handleRescan = (id: number) => {
    client.setQueriesData(
      {
        predicate: (query) =>
          query.queryKey.includes('listUrls') ||
          query.queryKey.includes('projectsUrlsIndex'),
      },
      (prev: any) => {
        return {
          ...prev,
          data: prev.data.map((item: ScannedContentResource) => {
            if (item.id === id) {
              return {
                ...item,
                is_single_scan_loading: true,
              };
            }
            return item;
          }),
        };
      },
    );
    scanSpecificUrlsMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          ids: [id],
        },
      },
      {
        onError: () =>
          showSnackbar({
            message: `Failed to start scan`,
            color: 'red',
          }),
      },
    );
  };

  return {
    isLoadingForId,
    handleImport,
    handleBlankEditor,
    handleRescan,
  };
};
