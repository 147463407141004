import { useUpdateCmsConnection } from '@/api/openapiComponents';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditShopifyAPIKeyDialog = ({ isOpen, onClose }: Props) => {
  const appState = useAppStore();

  const [apiKeyInput, setApiKeyInput] = useState('');
  const [apiSecretInput, setApiSecretInput] = useState('');
  const [storeFrontAccessTokenInput, setStoreFrontAccessTokenInput] =
    useState('');
  const [apiIdInput, setApiIdInput] = useState('');

  const updateCmsConnectionMutation = useUpdateCmsConnection();

  const handleSubmit = () => {
    updateCmsConnectionMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          settings: {
            api_key: apiKeyInput,
            api_secret: apiSecretInput,
            api_id: apiIdInput,
            storefront_access_token: storeFrontAccessTokenInput,
          },
        },
      },
      {
        onSuccess: () => {
          onClose();
          setApiKeyInput('');
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(updateCmsConnectionMutation.error);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      title="New Shopify Credentials"
      error={errorHelper.message()}
      content={
        <div className={'flex flex-col gap-2'}>
          <InputDecoration label="Admin API access token">
            <Input
              value={apiKeyInput}
              onChange={setApiKeyInput}
              placeholder="Enter your token key here..."
            />
          </InputDecoration>
          <InputDecoration label="Storefront API access token">
            <Input
              value={storeFrontAccessTokenInput}
              onChange={setStoreFrontAccessTokenInput}
              placeholder="Enter your token key here..."
            />
          </InputDecoration>
          <InputDecoration label="API KEY">
            <Input
              value={apiIdInput}
              onChange={setApiIdInput}
              placeholder="Enter your token key here..."
            />
          </InputDecoration>
          <InputDecoration label="API secret key">
            <Input
              value={apiSecretInput}
              onChange={setApiSecretInput}
              placeholder="Enter your token key here..."
            />
          </InputDecoration>
        </div>
      }
      confirmText="Save"
      isLoading={updateCmsConnectionMutation.isPending}
      disabled={!apiKeyInput}
      onConfirm={handleSubmit}
    />
  );
};
