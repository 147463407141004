import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cva, VariantProps } from 'class-variance-authority';
import { Icons } from './icons';
import { usePlateUiDrop } from '@/Hooks/usePlateUiDrop';
import { Divider } from '../components';
import { useMouseOverDraggableIcon } from '@/Hooks/useMouseOverDraggableIcon';

const headingVariants = cva('', {
  variants: {
    variant: {
      h1: 'mb-1 font-heading text-2xl font-extrabold',
      h2: 'mb-3 font-heading text-2xl font-extrabold tracking-tight',
      h3: 'mb-3 font-heading text-xl font-extrabold tracking-tight',
      h4: 'mb-3 font-heading text-lg font-extrabold tracking-tight',
      h5: 'text-lg font-semibold tracking-tight',
      h6: 'font-semibold tracking-tight',
    },
  },
});

export function HeadingElement({
  className,
  variant = 'h1',
  children,
  ...props
}: PlateElementProps & VariantProps<typeof headingVariants>) {
  const { element, editor } = props;
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();

  const { attributes, listeners, setNodeRef, style, setActivatorNodeRef } =
    usePlateUiDrop(element);

  const Element = variant!;

  const getContainerMargin = (isFirstBlock: boolean) => {
    if (isFirstBlock) return 'mt-0';
    switch (Element) {
      case 'h1':
        return 'mt-[2em]';
      case 'h2':
        return 'mt-[1.2em]';
      case 'h3':
        return 'mt-[1em]';
      case 'h4':
        return 'mt-[1em]';
      case 'h5':
        return 'mt-[0.75em]';
      default:
        return 'mt-[0.75em]';
    }
  };
  const getMargin = () => {
    switch (Element) {
      case 'h1':
        return 'mt-3';
      case 'h2':
        return 'mt-2';
      case 'h3':
        return 'mt-[0.35rem]';
      case 'h4':
        return 'mt-[0.40rem]';
      case 'h5':
        return 'mt-[0.40rem]';
      default:
        return 'mt-[0.3rem]';
    }
  };

  return (
    <>
      <PlateElement
        ref={setNodeRef}
        style={style}
        asChild
        className={headingVariants({
          variant,
          className: `${className} ${getContainerMargin(
            element.id === editor.children[0].id,
          )}`,
        })}
        {...methods}
        {...props}
      >
        <div className="flex">
          <div
            className={invisiblity}
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
          >
            <Icons.dragHandle
              className={`text-muted-foreground ${getMargin()} h-4 w-4`}
            />
          </div>

          <Element className={`pl-1`}>{children}</Element>
        </div>
      </PlateElement>
      <Divider id={element.id as string} />
    </>
  );
}
