import { ReactNode } from '@tanstack/react-router';
import { PropsWithChildren } from 'react';

type Props = {
  label: string;
  actions?: ReactNode;
  centered?: boolean;
  appendIcon?: ReactNode;
};

export const MetaFieldDecoration = ({
  children,
  centered,
  actions,
  label,
  appendIcon,
}: PropsWithChildren<Props>) => {
  return (
    <div className="relative flex w-full items-center pl-2">
      <div
        className={`flex w-28 flex-shrink-0 justify-between text-sm ${centered ? 'items-center' : 'pt-1'}`}
      >
        {label}
        <div className="mr-1 flex flex-shrink-0">{actions}</div>
      </div>
      <div className="flex flex-grow items-center overflow-hidden">
        {children}
      </div>
      <div className="ml-2 w-8 flex-shrink-0">{appendIcon}</div>
    </div>
  );
};
