import {
  useCreate,
  usePlannedContentListIndex,
  usePlannedContentListItemStore,
  usePlannedContentListStore,
} from '@/api/openapiComponents';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Plus } from 'lucide-react';
import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';

type Props = {
  keywords?: { id: number; name: string }[];
  keyword?: string;
  onClose: () => void;
  onSuccess?: (listId: number) => void;
};

export const AddKeywordsToListDialog = ({
  keywords,
  keyword,
  onClose,
  onSuccess,
}: Props) => {
  const appState = useAppStore();

  const [name, setName] = useState('');
  const [selectedListId, setSelectedListId] = useState<number>(-1);

  const isOpen = (keywords?.length ?? 0) > 0 || !!keyword;
  const addPlannedContentMutation = useCreate();
  const addPlannedContentListMutation = usePlannedContentListStore();
  const addItemsToListMutation = usePlannedContentListItemStore();
  const plannedContentListQuery = usePlannedContentListIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const handleAddKeywords = async (name: string, selectedListId: number) => {
    let newListId: number | undefined;

    if (selectedListId === -1) {
      const response = await addPlannedContentListMutation.mutateAsync({
        body: {
          name,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      });

      newListId = parseInt(response.data.id);
    }
    const id = newListId ?? selectedListId;
    let newKeywordId: undefined | number;

    if (keyword) {
      const response = await addPlannedContentMutation.mutateAsync({
        body: {
          items: [
            {
              keyword: keyword,
            },
          ],
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      });
      newKeywordId = parseInt(response.data?.[0].id);
    }

    const keywordIds = newKeywordId
      ? [newKeywordId]
      : keywords!.map((keyword) => keyword.id);

    addItemsToListMutation.mutate(
      {
        body: {
          items: keywordIds,
        },
        pathParams: {
          project: appState.currentProject!.id,
          plannedContentList: newListId ?? selectedListId,
        },
      },
      {
        onSuccess: () => {
          onClose();
          onSuccess?.(id);
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(
    addItemsToListMutation.error ?? addPlannedContentMutation.error,
  );

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Add keywords to list"
      error={errorHelper.message()}
      content={
        <>
          <p className="mb-2">
            Select a list to add{' '}
            {(keywords?.length ?? 0) > 5 ? keywords!.length : 'the following'}{' '}
            keywords to:
          </p>
          {keywords?.length <= 5 && (
            <ul>
              {keywords?.map((keyword) => (
                <li key={keyword.id}>• {keyword.name}</li>
              ))}
            </ul>
          )}
          {keyword && (
            <ul>
              <li>• {keyword}</li>
            </ul>
          )}
          <div className="mt-6">
            <InputDecoration label="List" required>
              {plannedContentListQuery.isLoading ? (
                <SkeletonLoader height="sm" />
              ) : (
                <Select
                  fullWidth
                  value={selectedListId}
                  onChange={(value) => setSelectedListId(Number(value))}
                  options={[
                    {
                      title: 'Create new list',
                      value: -1,
                      prependIcon: Plus,
                    },
                    ...(plannedContentListQuery.data?.data ?? []).map(
                      (value) => ({
                        title: value.name,
                        value: parseInt(value.id),
                      }),
                    ),
                  ]}
                />
              )}
            </InputDecoration>

            {selectedListId === -1 && (
              <div className="mt-4">
                <InputDecoration label="New list name" required>
                  <Input
                    error={errorHelper.has('name')}
                    value={name}
                    onChange={setName}
                    counter
                    counterMax={32}
                  />
                </InputDecoration>
              </div>
            )}
          </div>
        </>
      }
      isLoading={
        addItemsToListMutation.isPending ||
        addPlannedContentMutation.isPending ||
        addPlannedContentListMutation.isPending
      }
      onClose={onClose}
      onConfirm={() => handleAddKeywords(name, selectedListId)}
      disabled={!selectedListId || (selectedListId === -1 && !name)}
    />
  );
};
