import { CardSection } from '../../components/CardSection';
import { Subheading } from '../components/Subheading';
import { Pencil, RefreshCcw, Unlink } from 'lucide-react';
import { ConnectionCard } from './components/ConnectionCard';
import GoogleIcon from '../../../../../public/svg/google-icon.svg';
import ShopifyIcon from '../../../../../public/svg/shopify-logo.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { useConnectToGoogleSearchConsole } from '../hooks';
import { GSCChooseSiteDialog } from './components/GSCChooseSiteDialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ShopifySetupDialog } from './ShopifySetupDialog';
import { useState } from 'react';
import {
  useDeleteCmsConnection,
  useGetCmsConnection,
  useUpdateCmsConnection,
} from '@/api/openapiComponents';
import { ConnectionState } from '@/api/openapiSchemas';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { EditShopifyAPIKeyDialog } from './components/EditShopifyAPIKeyDialog';

export const Connections = () => {
  const projectId = useAppStore((state) => state.currentProject!.id);

  const { hasFeature } = useFeatureFlagging();

  const [showCreateShopifyDialog, setShowCreateShopifyDialog] = useState(false);
  const [showShopifyUpdateAPIKeyDialog, setShowShopifyUpdateAPIKeyDialog] =
    useState(false);

  const updateShopifyDataMutation = useUpdateCmsConnection();
  const deleteConnectionMutation = useDeleteCmsConnection();
  const cmsConnectionQuery = useGetCmsConnection(
    {
      pathParams: {
        project: projectId,
      },
    },
    {
      retry: false,
      enabled: hasFeature('shopify-cms-integration'),
    },
  );

  const {
    disconnect,
    googleDialog,
    googleSearchConsoleData,
    isGettingGoogleSearchConsoleData,
    isSelectingSiteForGoogleSearchConsole,
    isSettingUpGoogleSearchConsole,
    hasNoAvailableSites,
    selectSiteForGoogleSearchConsole,
    setGoogleDialog,
    setupGoogleConsole,
    initialState,
  } = useConnectToGoogleSearchConsole(projectId);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setupGoogleConsole({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  const handleDeleteConnection = () => {
    deleteConnectionMutation.mutate(
      {
        pathParams: {
          project: projectId,
        },
      },
      {
        onSuccess: () => {
          cmsConnectionQuery.refetch();
        },
      },
    );
  };

  const handleUpdateShopifyData = () => {
    updateShopifyDataMutation.mutate(
      {
        pathParams: {
          project: projectId,
        },
      },
      {
        onSuccess: () => {
          cmsConnectionQuery.refetch();
        },
      },
    );
  };

  return (
    <>
      <EditShopifyAPIKeyDialog
        isOpen={showShopifyUpdateAPIKeyDialog}
        onClose={() => setShowShopifyUpdateAPIKeyDialog(false)}
      />
      <ShopifySetupDialog
        isOpen={showCreateShopifyDialog}
        onClose={() => setShowCreateShopifyDialog(false)}
        onSuccess={() => cmsConnectionQuery.refetch()}
      />
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSites}
        onClose={() => setGoogleDialog(initialState)}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: projectId,
            },
            body: {
              site: googleDialog.selectedSite!,
            },
          });
          setGoogleDialog(initialState);
        }}
        setGoogleDialog={setGoogleDialog}
        googleDialog={googleDialog}
      />
      <Subheading text="Simplify your workflow with our different integrations. Connect and keep everything in sync." />
      <CardSection title="Data sources" gap={4}>
        <ConnectionCard
          connection={{
            isLoadingInitialConnection: isGettingGoogleSearchConsoleData,
            isFetchingConnection:
              isSettingUpGoogleSearchConsole ||
              isGettingGoogleSearchConsoleData,
            onClick: login,
            state: googleSearchConsoleData?.data?.state,
          }}
          items={[
            {
              title: 'Disconnect',
              onClick: () => {
                disconnect({
                  pathParams: {
                    project: projectId,
                    connection: googleSearchConsoleData?.data.id!,
                  },
                });
              },
              prependIcon: Unlink,
            },
            {
              title: 'Edit',
              onClick: () => {
                setGoogleDialog({
                  isOpen: true,
                  sites:
                    googleSearchConsoleData?.data.settings.available_sites ??
                    [],
                  selectedSite: googleSearchConsoleData?.data.settings.site,
                });
              },
              prependIcon: Pencil,
            },
          ]}
          icon={GoogleIcon}
          title="Google Search Console"
          description="Identify keyword gaps in your content by connecting to Google
            Search Console."
        />
        {hasFeature('shopify-cms-integration') && (
          <ConnectionCard
            title="Shopify"
            description="Connect your shopify store to sync editor content to your store"
            icon={ShopifyIcon}
            items={[
              {
                title: 'Change credentials',
                prependIcon: Pencil,
                onClick: () => setShowShopifyUpdateAPIKeyDialog(true),
              },
              {
                title: 'Refresh',
                prependIcon: RefreshCcw,
                onClick: handleUpdateShopifyData,
                disabled: updateShopifyDataMutation.isPending,
              },
              {
                title: 'Disconnect',
                onClick: handleDeleteConnection,
                prependIcon: Unlink,
              },
            ]}
            connection={{
              state:
                cmsConnectionQuery.data?.data?.type === 'shopify' &&
                !cmsConnectionQuery.isError
                  ? (cmsConnectionQuery.data?.data.state as ConnectionState)
                  : 'disabled',
              isFetchingConnection: false,
              isLoadingInitialConnection: cmsConnectionQuery.isFetching,
              onClick: () => setShowCreateShopifyDialog(true),
            }}
          />
        )}
      </CardSection>
    </>
  );
};
