import * as React from 'react';
import { createPortal } from 'react-dom';
import { IconButton } from './v2/IconButton/IconButton';
import { X } from 'lucide-react';
import { Button, ButtonProps } from './v2/Button';
import { ReactNode } from 'react';

export type DialogSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto';

type Props = {
  isOpen: boolean;
  persistent?: boolean;
  children: React.ReactNode;
  dense?: boolean;
  title?: string | ReactNode;
  subtitle?: ReactNode;
  overlayHeader?: boolean;
  actions?: ButtonProps[];
  size?: DialogSize;
  backgroundColor?: 'white' | 'gray';
  fullScreen?: boolean;
  maxWidth?: string;
  handleClose?: () => void;
};
export function Dialog({
  children,
  handleClose,
  isOpen,
  title,
  subtitle,
  persistent = false,
  overlayHeader = false,
  dense = false,
  fullScreen = false,
  actions,
  size = 'auto',
  backgroundColor = 'white',
}: Props) {
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const [hasScrollBar, setHasScrollBar] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  React.useEffect(() => {
    if (scrollContainerRef.current) {
      const updateHasScrollbar = () => {
        if (!scrollContainerRef.current) return;
        const hasScrollBar =
          scrollContainerRef.current.scrollHeight >
          scrollContainerRef.current.clientHeight;
        setHasScrollBar(hasScrollBar);
      };
      const resizeObserver = new ResizeObserver(updateHasScrollbar);
      resizeObserver.observe(scrollContainerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [scrollContainerRef.current]);

  const handleCloseBefore = (e: React.MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    if (!handleClose) {
      return;
    }
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      handleClose();
    }, 350);
  };

  const getWidth = () => {
    switch (size) {
      case 'xs':
        return '20rem';
      case 'sm':
        return '30rem';
      case 'md':
        return '40rem';
      case 'lg':
        return '60rem';
      case 'xl':
        return '80rem';
    }

    return '40rem';
  };

  React.useEffect(() => {
    if (isOpen) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleCloseBefore(e);
        }
      };
      window.addEventListener('keydown', listener);

      return () => window.removeEventListener('keydown', listener);
    }
  }, [isOpen]);

  return isOpen
    ? createPortal(
        <>
          <div
            onMouseDown={persistent ? undefined : handleCloseBefore}
            style={{ backgroundColor: 'rgba(0,0,0, .4)' }}
            className={`fixed top-0 z-[120] flex h-full w-full items-center justify-center ${
              isClosing ? 'animate-fade-out-fast' : 'animate-fade-fast'
            }`}
          >
            <div
              className={`fixed top-0 z-[140] flex h-full w-full items-center justify-center`}
              onMouseDown={persistent ? undefined : handleCloseBefore}
            >
              <div
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  margin: '0 auto',
                  height: fullScreen ? '100vh' : 'auto',
                  width: fullScreen ? '100vw' : '90%',
                  maxWidth: fullScreen ? '100vw' : getWidth(),
                  maxHeight: fullScreen ? '100vh' : '90vh',
                }}
                className={`relative flex flex-col bg-${
                  backgroundColor === 'gray' ? 'gray-50' : 'white'
                } ${isClosing ? 'animate-dialog-out' : 'animate-dialog-in'} ${
                  dense ? '' : 'py-5 pl-7'
                } ${fullScreen ? '' : 'rounded-md border-2'}`}
              >
                {(title || handleClose) && (
                  <div
                    className={`mb-5 flex-shrink-0 text-xl ${fullScreen ? 'container p-0' : ''} ${
                      overlayHeader ? 'absolute left-0 right-0 top-0 z-50' : ''
                    } ${dense ? '' : ''}`}
                  >
                    <div className="pr-7 text-xl font-bold">{title}</div>

                    {subtitle !== undefined && (
                      <div className="text-sm">{subtitle}</div>
                    )}
                    {handleClose && (
                      <div className={'absolute right-2 top-2 z-10'}>
                        <IconButton
                          size={'base'}
                          onClick={handleCloseBefore}
                          icon={X}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  ref={scrollContainerRef}
                  className={`flex-grow  overflow-y-auto ${dense ? '' : 'pr-7'}`}
                >
                  {children}
                </div>
                {actions && (
                  <div
                    style={{
                      scrollbarGutter: hasScrollBar ? 'stable' : undefined,
                    }}
                    className={`mt-5 flex flex-shrink-0 items-center justify-end gap-2 overflow-hidden ${
                      dense ? '' : 'pr-7'
                    }`}
                  >
                    {actions.map((action) => (
                      <Button {...action} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>,
        document.body,
      )
    : null;
}
