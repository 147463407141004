import PageContainer from '@/Components/PageContainer';
import { Menus } from '@/Components/Menus';
import { DiffEditor } from './components/DiffEditor';

// This page has been archived, but it remains in case in the future it is needed again.

export const Diff = () => {
  return (
    <Menus>
      <PageContainer>
        <DiffEditor />
      </PageContainer>
    </Menus>
  );
};
