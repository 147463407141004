import AppLabel, { Variant } from '@/Components/Labels/AppLabel';
import { Card } from '@/Components/v2/Card';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { VerticalMenuItem } from '@/Components/v2/Menu/VerticalMenu';
import { MoreVertical, Unplug } from 'lucide-react';

import { Button } from '@/Components/v2/Button';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Select } from '@/Components/v2/Select';
import { ConnectionState } from '@/api/openapiSchemas.ts';

type Props = {
  connection: {
    onClick: () => void;
    isFetchingConnection: boolean;
    isLoadingInitialConnection: boolean;
    state?: ConnectionState;
  };
  google?: {
    items: { value: string }[];
    value: string;
    onChange: (value?: string) => void;
  };
  items: VerticalMenuItem[];
  icon: string;
  title: string;
  description: string;
};
export const ConnectionCard = ({
  connection,
  items,
  icon,
  title,
  description,
  google,
}: Props) => {
  return (
    <Card variant="filled-outline">
      <div className="h-full px-5 py-4">
        <div className="flex h-full flex-col gap-4">
          <div className="flex items-center justify-between">
            <div>
              {connection.isLoadingInitialConnection ? (
                <div className="h-6 w-24">
                  <SkeletonLoader height="full" />
                </div>
              ) : connection.state === 'active' ? (
                <div className="py-1">
                  <AppLabel variant={Variant.green}>Connected</AppLabel>
                </div>
              ) : connection.state === 'expired' ? (
                <div className="py-1">
                  <AppLabel variant={Variant.red}>Expired</AppLabel>
                </div>
              ) : (
                <div className="py-1">
                  <AppLabel variant={Variant.gray}>Not connected</AppLabel>
                </div>
              )}
            </div>
            {connection.state === 'active' && (
              <PopoverMenu
                trigger={
                  <IconButton
                    disabled={connection.isFetchingConnection}
                    color="gray"
                    icon={MoreVertical}
                  />
                }
                items={items}
              />
            )}
          </div>
          <img height={40} width={40} src={icon} />
          <div className="flex-grow">
            <h1 className="mb-3 text-xl font-extrabold text-primary-700">
              {title}
            </h1>
            <p>{description}</p>
          </div>
          {google && connection.state === 'active' && (
            <Select
              value={google.value}
              onChange={google.onChange}
              options={google.items}
            />
          )}
          {connection.state !== 'active' && (
            <Button
              isLoading={connection.isFetchingConnection}
              prependIcon={Unplug}
              onClick={connection.onClick}
              text="Connect"
              variant="outline"
            />
          )}
        </div>
      </div>
    </Card>
  );
};
