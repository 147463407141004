import { GetDashboardQueryParams } from '@/api/openapiComponents';
import { ProjectResource } from '@/api/openapiSchemas';
import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';

export type GraphState = {
  range: GetDashboardQueryParams['range'];
  clicks: boolean;
  impressions: boolean;
  ctr: boolean;
  position: boolean;
  granularity: GetDashboardQueryParams['granularity'];
  country: {
    iso_3166_3: string;
    iso_3166_2: string;
  };
};

export const getInitialGraphState = (project: ProjectResource) => {
  const data = localStorage.getItem('analytics_graph_state');
  if (data) {
    return JSON.parse(data) as GraphState;
  } else {
    return {
      range: 'P1W' as const,
      clicks: true,
      impressions: true,
      ctr: false,
      position: false,
      granularity: 'day' as const,
      country: {
        iso_3166_2: project.language?.country?.iso_3166_2 ?? 'US',
        iso_3166_3: project.language?.country?.iso_3166_3 ?? 'USA',
      },
    };
  }
};

export const useGraphState = () => {
  const appState = useAppStore();

  const [graphState, setGraphState] = useState(
    getInitialGraphState(appState.currentProject!),
  );

  const handleSetState = (newState: GraphState) => {
    localStorage.setItem('analytics_graph_state', JSON.stringify(newState));

    setGraphState((prevState: GraphState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return [graphState, handleSetState] as const;
};

export const useGrowthSorting = (key: string) => {
  const [growthSorting, setGrowthSorting] = useState<
    'popular' | 'rising' | 'falling'
  >(localStorage.getItem(key + 'analytics_growth_sorting') ?? 'popular');

  const handleGrowthSorting = (newState: 'popular' | 'rising' | 'falling') => {
    localStorage.setItem(key + 'analytics_growth_sorting', newState);
    setGrowthSorting(newState);
  };

  return [growthSorting, handleGrowthSorting] as const;
};
