import * as React from 'react';
import { useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Icons } from '@/Pages/Document/components/PlateEditor/components/icons';
import ReactCountryFlag from 'react-country-flag';
import { LanguageResource } from '@/api/openapiSchemas';

function mergeClasses(...classes) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  languages: LanguageResource[];
  selectedLanguage: LanguageResource | null;
  setSelectedLanguage: (language: LanguageResource | null) => void;
  disabled?: boolean;
  classNames?: string;
  labelClasses?: string;
  label?: React.ReactNode;
  placeholder?: string;
};

export default function LanguageComboboxV2({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  disabled,
  classNames,
  labelClasses,
  placeholder,
  label,
}: Props) {
  const [query, setQuery] = useState<string>('');
  const comboboxBtn = useRef<HTMLButtonElement | null>(null);
  const handleInputFocus = () => comboboxBtn.current?.click();
  const filteredLanguages: LanguageResource[] =
    query === ''
      ? languages
      : languages.filter((language) => {
          return language.display_name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  return (
    <Combobox
      disabled={disabled}
      as="div"
      value={selectedLanguage}
      onChange={(language) => setSelectedLanguage(language)}
    >
      <Combobox.Label
        className={
          labelClasses
            ? labelClasses
            : 'unselectable mb-2 w-full text-base font-bold'
        }
      >
        {label}
      </Combobox.Label>
      <div
        className={`relative mt-2 ${
          disabled ? 'cursor-not-allowed bg-gray-100' : ''
        }`}
      >
        {selectedLanguage?.country?.iso_3166_2 && (
          <div className={'absolute bottom-0 left-4 top-0 flex items-center'}>
            <ReactCountryFlag
              countryCode={selectedLanguage?.country.iso_3166_2}
              svg
              style={{
                height: '18px',
                width: '23px',
              }}
              className="border border-gray-100"
            />
          </div>
        )}
        <Combobox.Input
          className={() =>
            mergeClasses(
              classNames,
              'w-full rounded-lg border border-primary-300 bg-transparent text-primary outline-none ring-0 placeholder:italic placeholder:text-gray-400 focus:border-secondary-400 disabled:text-primary-400',
              selectedLanguage?.country?.iso_3166_2 ? 'pl-11' : 'pl-4',
              disabled ? 'cursor-not-allowed' : '',
            )
          }
          onChange={(event) => setQuery(event.target.value)}
          onClick={() => handleInputFocus()}
          displayValue={(language: LanguageResource) => language?.display_name}
          placeholder={placeholder}
        />
        <Combobox.Button
          ref={comboboxBtn}
          className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ${
            disabled ? 'cursor-not-allowed' : ''
          }`}
        >
          <Icons.chevronsDown className="h-5 w-5 text-primary-400" />
        </Combobox.Button>

        {filteredLanguages.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredLanguages.map((language) => (
              <Combobox.Option
                key={language.id}
                value={language}
                className={({ active }) =>
                  mergeClasses(
                    'relative cursor-pointer select-none py-2 pl-3 pr-9',
                    active ? 'bg-green-200 text-green-800' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <ReactCountryFlag
                        countryCode={language.country?.iso_3166_2}
                        svg
                        style={{
                          height: '18px',
                          width: '23px',
                        }}
                        className="border border border-gray-100"
                      />
                      <span
                        className={mergeClasses(
                          'ml-3 truncate',
                          selected && 'font-semibold',
                        )}
                      >
                        {language.display_name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={mergeClasses(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-green-800' : 'text-gray-400',
                        )}
                      >
                        {/*<Icons.check className='h-4 w-4 text-slate-500 dark:text-slate-400' />*/}
                        <Icons.check className="h-5 w-5" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
