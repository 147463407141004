import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from './components/ValueWIthGrowth';
import { useState } from 'react';
import { GrowthValueHeader } from './components/GrowthValueHeader';
import { useGetDashboard, useShowPageGroup } from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { CellWithBar } from './components/CellWithBar';
import { calculateGrowth, convertData, sortByGrowth } from './util';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { EditGroupDialog } from './components/EditGroupDialog';
import { Button } from '@/Components/v2/Button';
import { Pencil } from 'lucide-react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { removeDomain, removeProtocol } from '@/utils';
import { useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';

export const PageAnalyticsGroup = () => {
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/page-analytics/groups/$groupId' });
  const params = useParams({ from: '/page-analytics/groups/$groupId' });
  const search = useSearch({ from: '/page-analytics/groups/$groupId' });

  const [graphState, setGraphState] = useGraphState();
  const [growthSorting, setGrowthSorting] = useGrowthSorting('grouppage');

  const [showEditDialog, setShowEditDialog] = useState(false);

  const groupQuery = useShowPageGroup({
    pathParams: {
      project: appState.currentProject!.id,
      analyticsPageGroup: params.groupId,
    },
  });
  const analyticsQuery = useGetDashboard({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      group: params.groupId,
      range: graphState.range,
    },
  });

  const topPages = analyticsQuery.data?.data.top_pages
    ? convertData(analyticsQuery.data?.data.top_pages)
    : [];

  const topPagesMostClicks =
    topPages.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]?.current
      .clicks ?? 0;

  return (
    <>
      <EditGroupDialog
        isOpen={showEditDialog && groupQuery.isSuccess}
        existingGroup={groupQuery.data?.data}
        onClose={() => setShowEditDialog(false)}
        onSuccess={() => {
          groupQuery.refetch();
          analyticsQuery.refetch();
          setShowEditDialog(false);
        }}
      />
      <Menus>
        <PageContainer
          title={`Page group: ${groupQuery.data?.data.name ?? ''}`}
          canGoBack
          actions={
            <Button
              text="Edit"
              prependIcon={Pencil}
              variant="ghost"
              onClick={() => setShowEditDialog(true)}
            />
          }
        >
          <AnalyticsGraph
            data={analyticsQuery.data?.data.graph}
            metrics={analyticsQuery.data?.data.metrics}
            state={graphState}
            onStateChange={setGraphState}
          />

          <Table.Root
            isLoading={analyticsQuery.isFetching}
            items={(growthSorting === 'popular'
              ? topPages
              : sortByGrowth(
                  topPages ?? [],
                  growthSorting === 'rising' ? 'DESC' : 'ASC',
                  'clicks',
                )
            )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
            columns={[
              {
                heading: 'Page',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topPagesMostClicks}
                    name={removeDomain(item.current.page)}
                    onClick={() =>
                      navigate({
                        to: `/page-analytics/page/$pageId`,
                        params: { pageId: item.current.page },
                      })
                    }
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Clicks" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Impressions" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="CTR" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: <GrowthValueHeader heading="Position" />,
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <h1 className="text-lg font-semibold">Pages</h1>
              <ToggleButton
                value={growthSorting}
                onChange={setGrowthSorting}
                options={[
                  {
                    displayName: 'Popular',
                    value: 'popular',
                  },
                  {
                    displayName: 'Rising',
                    value: 'rising',
                  },
                  {
                    displayName: 'Falling',
                    value: 'falling',
                  },
                ]}
              />
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                setCurrentPage={(page) => navigate({ search: { page } })}
                lastPage={Math.floor((topPages?.length ?? 0) / 20)}
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
