import { ShrinkableDragOverlay } from '@/Components/ShrinkableDragOverlay';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { CustomFieldDraggable } from './components/CustomFieldDraggable';
import { CustomFieldDraggableContainer } from './components/CustomFieldDraggableContainer';
import { CustomFieldDropZone } from './components/CustomFieldDropzone';
import { CustomFieldDefinitionResource } from '@/api/openapiSchemas';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useListCustomFieldDefinitions,
  useReorderCustomFields,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import PageContainer from '@/Components/PageContainer';

export const ReorderCustomFields = () => {
  const appState = useAppStore();
  const client = useQueryClient();
  const navigate = useNavigate({ from: '/settings/custom-fields/reorder' });
  const search = useSearch({ from: '/settings/custom-fields/reorder' });

  const [draggedElement, setDraggedElement] =
    useState<CustomFieldDefinitionResource>();

  const updateCustomFieldOrderMutation = useReorderCustomFields();
  const customFieldsQuery = useListCustomFieldDefinitions({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 100,
      page: search.page,
    },
  });

  const handleDataChange = (items: CustomFieldDefinitionResource[]) => {
    client.setQueriesData(
      {
        predicate: (query) => {
          return query.queryKey.includes('listCustomFieldDefinitions');
        },
      },
      (oldData: any) => {
        return {
          ...oldData,
          data: items,
        };
      },
    );
    const bodyIndex = items.findIndex((item) => item.id === 'body');

    updateCustomFieldOrderMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: {
        custom_fields: items
          .filter((item) => item.id !== 'body')
          .reduce(
            (acc, item, index) => ({ ...acc, [index - bodyIndex]: item.id }),
            {},
          ),
      },
    });
  };

  const customFields = !customFieldsQuery.data?.data.some(
    (el) => el.id === 'body',
  )
    ? [
        ...(customFieldsQuery.data?.data ?? []).filter(
          (field) => field.position < 0,
        ),
        {
          id: 'body',
          name: 'Body',
          description: 'The content body',
          position: 0,
          value: '',
        } as CustomFieldDefinitionResource,
        ...(customFieldsQuery.data?.data ?? []).filter(
          (field) => field.position >= 0,
        ),
      ]
    : (customFieldsQuery.data?.data ?? []);

  return (
    <PageContainer
      title="Reorder custom fields"
      isSubContainer
      actions={
        <div className="flex items-center justify-end gap-2">
          <Button
            text="Finish Reordering"
            color="secondary"
            onClick={() => navigate({ to: '/settings/custom-fields/manage' })}
          />
        </div>
      }
    >
      <DndContext
        onDragStart={({ active }) => {
          setDraggedElement(active.data.current?.customField);
        }}
        onDragEnd={({ over }) => {
          if (over) {
            const items = customFields;
            if (!items) return;

            const draggedIndex = items.findIndex(
              (item) => item.id === draggedElement?.id,
            );
            let switchToIndex = items.findIndex(
              (item) => item.id === over.data.current?.customField.id,
            );

            if (
              draggedIndex > switchToIndex &&
              !(over.id as string).includes('start')
            ) {
              switchToIndex += 1;
            }
            const reorderedItems = arrayMove(
              items,
              draggedIndex,
              switchToIndex,
            );
            handleDataChange(reorderedItems);
          }
          setDraggedElement(undefined);
        }}
      >
        {createPortal(
          <DragOverlay>
            {draggedElement && (
              <ShrinkableDragOverlay>
                <CustomFieldDraggableContainer customField={draggedElement} />
              </ShrinkableDragOverlay>
            )}
          </DragOverlay>,
          window.document.body,
        )}
        {customFields.map((customField, index) => (
          <>
            {index === 0 && (
              <CustomFieldDropZone
                key="start"
                isFirst
                customField={customField}
                isActive={
                  !!draggedElement &&
                  index !==
                    customFields.findIndex(
                      (item) => item.id === draggedElement.id,
                    )
                }
              />
            )}
            <CustomFieldDraggable
              key={customField.id}
              customField={customField}
              isActive={
                !!draggedElement &&
                index !==
                  customFields.findIndex(
                    (item) => item.id === draggedElement.id,
                  ) &&
                index !==
                  customFields.findIndex(
                    (item) => item.id === draggedElement.id,
                  ) -
                    1
              }
            />
          </>
        ))}
      </DndContext>
    </PageContainer>
  );
};
