import { Menus } from '@/Components/Menus';
import { useEffect, useRef, useState } from 'react';
import * as Table from '@/Components/Table';
import PageContainer from '@/Components/PageContainer';
import { OverviewGraph } from './components/OverviewGraph';
import { useAppStore } from '../AppLoader/stores';
import { useCopyTable, useTable } from '@/Components/Table/hooks';
import { Errors } from '@/Components/Errors';
import {
  Globe,
  Copy,
  History,
  MoreVertical,
  Plus,
  Table2,
  Trash2,
  LineChart,
  ListPlus,
  ListMinus,
} from 'lucide-react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { AddTrackedKeywordsDialog } from './components/AddTrackedKeywordsDialog';
import { KeywordRankTrackingSlideOverContent } from './components/KeywordRankTrackingSlideoverContent';
import { downloadCSV, formatThousandSeperator } from '@/utils';
import {
  useExportListAsCsv,
  useProjectsTrackedKeywordsIndex,
} from '@/api/openapiComponents';
import {
  TrackedKeywordResource,
  TrackedKeywordUpdateResultResource,
} from '@/api/openapiSchemas';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { RankLabel } from '@/Components/Labels/RankLabel';
import { GradualScoreVariant } from '@/Components/Labels/AppLabel';
import { RankTrackingTableFilters } from './components/RankTrackingTableFilters';
import { Button } from '@/Components/v2/Button';
import { ChangeIndicator } from './components/ChangeIndicator';
import { Tooltip } from '@/Components/v2/Tooltip';
import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useNavigate } from '@tanstack/react-router';
import { RemoveKeywordsFromListDialog } from '../components/RemoveKeywordsFromListDialog';
import { AddKeywordsToListDialog } from '../components/AddKeywordsToListDialog';
import { ListTabs } from '../components';
import { SlideOver } from '@/Components/SlideOver';
import { useRankTrackingLists } from './hooks';
import { DeleteKeywordsDialog } from './components/DeleteKeywordsDialog';

export const getRankTrackingVariant = (
  position: number,
): keyof typeof GradualScoreVariant => {
  if (position <= 3) {
    return 'best';
  } else if (position <= 10) {
    return 'great';
  } else if (position <= 20) {
    return 'good';
  } else if (position <= 50) {
    return 'ok';
  } else {
    return 'remaining';
  }
};

export const RankTracking = () => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const [showOptionsForKeywordId, setShowOptionsForKeywordId] =
    useState<number>();

  const [selectedKeyword, setSelectedKeyword] =
    useState<TrackedKeywordResource>();

  const { showSnackbar } = useSnackbar();
  const refreshingKeywordsRef = useRef<TrackedKeywordResource[]>([]);
  const [slideoverTab, setSlideoverTab] = useState<
    'positions' | 'serp-overview'
  >('positions');

  const {
    addController,
    closeDialogs,
    deleteController,
    editController,
    addToListController,
    removeFromListController,
    listsQuery,
    selectedTabIndex,
    setSelectedTabIndex,
    setShowAddKeywordDialog,
    setTrackingToBeAddedToList,
    setTrackingToBeDeleted,
    setTrackingToBeRemovedFromList,
    showAddKeywordDialog,
    trackingToBeAddedToList,
    trackingToBeDeleted,
    trackingToBeRemovedFromList,
  } = useRankTrackingLists({
    onSuccess: (type) => {
      resetSelection();
      if (type === 'remove-keyword-from-list') {
        rankTrackingQuery.refetch();
      }
    },
  });

  useEffect(() => {
    window.Echo.private(`projects.${appState.currentProject?.id}`).listen(
      'TrackedKeywordUpdatedEvent',
      (e: { id: number; keyword: string }) => {
        rankTrackingQuery.refetch().then(() => {
          if (
            refreshingKeywordsRef.current.some((keyword) => keyword.id === e.id)
          ) {
            showSnackbar({
              message: `Rank updated for keyword "${e.keyword}"`,
              color: 'secondary',
            });
            refreshingKeywordsRef.current =
              refreshingKeywordsRef.current.filter(
                (keyword) => keyword.id !== e.id,
              );
          }
        });
      },
    );

    return () => {
      window.Echo.leave(`projects.${appState.currentProject?.id}`);
    };
  }, []);

  const {
    tableState,
    setSorting,
    setFilters,
    setPage,
    selection,
    setSelection,
    resetSelection,
    resetSorting,
  } = useTable<
    | 'id'
    | 'search_volume'
    | 'difficulty'
    | 'position'
    | 'change'
    | 'estimated_traffic',
    {
      search: string;
      change_min: number;
      change_max: number;
      position_min: number;
      position_max: number;
      search_volume_min: number;
      search_volume_max: number;
      difficulty_min: number;
      difficulty_max: number;
    },
    TrackedKeywordResource
  >({ sortBy: 'estimated_traffic', sortDirection: 'DESC' });

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => item.keyword?.name).join('\n'),
  );

  const {
    mutateAsync: exportingRankTrackingKeywords,
    isPending: isExportingRankTrackingKeywords,
  } = useExportListAsCsv();

  const selectedList = listsQuery.data?.data[selectedTabIndex - 1];
  const rankTrackingQuery = useProjectsTrackedKeywordsIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...tableState,
      limit: 100,
      filters: {
        ...tableState.filters,
        list_id:
          selectedTabIndex === 0
            ? undefined
            : listsQuery.data?.data[selectedTabIndex - 1].id,
      },
    },
  });

  const resetFilters = () => {
    setFilters(undefined);
    resetSorting();
  };

  appState.pageTitle('Rank tracking');

  return (
    <Menus>
      <SlideOver
        isOpen={!!selectedKeyword?.id}
        onClickOutside={() => setSelectedKeyword(undefined)}
        size="lg"
      >
        <KeywordRankTrackingSlideOverContent
          key={selectedKeyword?.id}
          tab={slideoverTab}
          setTab={setSlideoverTab}
          title={selectedKeyword?.keyword?.name ?? ''}
          projectId={appState.currentProject!.id}
          trackedKeywordId={selectedKeyword?.id}
          onClose={() => setSelectedKeyword(undefined)}
        />
      </SlideOver>
      <AddKeywordsToListDialog
        error={addController.error}
        isPending={addToListController.isLoading || addController.isLoading}
        key={trackingToBeAddedToList.length + 'add'}
        keywords={trackingToBeAddedToList.map((item) => ({
          id: item.id,
          name: item.keyword?.name ?? '',
        }))}
        listItems={(listsQuery.data?.data ?? []).map((value) => ({
          title: value.name,
          value: parseInt(value.id),
        }))}
        handleSubmit={addToListController.handleSubmit}
        onClose={() => {
          setTrackingToBeAddedToList([]);
        }}
        isDisabled={listsQuery.isLoading}
      />
      <RemoveKeywordsFromListDialog
        key={trackingToBeRemovedFromList.length + 'remove'}
        list={selectedList}
        keywordsToBeDeleted={trackingToBeRemovedFromList.map((item) => ({
          id: item.id,
          name: item.keyword?.name ?? '',
        }))}
        onClose={() => {
          rankTrackingQuery.refetch();
          setTrackingToBeRemovedFromList([]);
        }}
        isLoading={removeFromListController.isLoading}
        error={removeFromListController.error}
        onSubmit={removeFromListController.handleSubmit}
      />
      <DeleteKeywordsDialog
        isOpen={trackingToBeDeleted.length > 0}
        keywords={trackingToBeDeleted.map((item) => ({
          id: item.id,
          name: item.keyword?.name ?? '',
        }))}
        onClose={() => setTrackingToBeDeleted([])}
        onSuccess={(keywords) => {
          rankTrackingQuery.refetch().then(() => {
            resetSelection();
            setTrackingToBeDeleted([]);
            showSnackbar({
              color: 'secondary',
              message: `Deleted ${keywords.length} tracked keyword`,
            });
          });
        }}
      />
      <AddTrackedKeywordsDialog
        key={'keywordadddialog' + showAddKeywordDialog}
        onSuccess={(listId) => {
          resetFilters();
          if (!listId) {
            setSelectedTabIndex(0);
          } else {
            listsQuery.refetch().then((response) => {
              setSelectedTabIndex(
                response.data?.data?.findIndex((list) => list.id === listId) +
                  1,
              );
            });
          }
        }}
        trackedKeywords={rankTrackingQuery?.data?.data ?? []}
        isOpen={showAddKeywordDialog}
        onClose={() => {
          setShowAddKeywordDialog(false);
          rankTrackingQuery.refetch();
        }}
      />
      <PageContainer title="Rank tracking">
        <OverviewGraph />
        <div className="flex justify-end">
          <Tooltip
            disabled={(appState.currentProject?.domain?.length ?? 0) > 0}
            description="Your project does not have a domain, which is required to track keywords"
          >
            <Button
              disabled={(appState.currentProject?.domain?.length ?? 0) === 0}
              onClick={() => setShowAddKeywordDialog(true)}
              text="Add keywords"
              prependIcon={Plus}
              color="secondary"
            />
          </Tooltip>
        </div>
        <div className="my-4">
          <Errors error={rankTrackingQuery.error} />
        </div>
        <ListTabs
          deleteController={deleteController}
          editController={editController}
          onCloseDialog={closeDialogs}
          data={listsQuery.data?.data}
          isLoading={listsQuery.isLoading}
          selectedTabIndex={selectedTabIndex}
          selectedList={selectedList}
          addController={addController}
          onChange={(tabIndex) => {
            resetSelection();
            setPage(1);
            setSelectedTabIndex(tabIndex);
          }}
        />
        <Table.Root
          selection={selection}
          onSelectionChange={setSelection}
          onSortChange={setSorting}
          isLoading={rankTrackingQuery.isLoading}
          items={rankTrackingQuery.data?.data ?? []}
          sorting={tableState}
          meta={rankTrackingQuery.data?.meta}
          subRows={{
            columns: [
              {
                render: (_, row) => (
                  <div className="text-primary">{row.keyword?.name}</div>
                ),
              },
              {
                rightAlign: true,
                render: (item) => (
                  <div>
                    <RankLabel
                      rank={item.position}
                      variant={getRankTrackingVariant(item.position)}
                    />
                  </div>
                ),
                colSpan: 1,
              },
              {
                rightAlign: true,
                render: (item) => (
                  <ChangeIndicator isSubrow value={item.change} />
                ),
                colSpan: 1,
              },
              {
                rightAlign: true,
                render: (_, row) => (
                  <div className="text-right opacity-60">
                    {!row.keyword?.search_volume && !row.keyword?.difficulty ? (
                      <Tooltip
                        description={
                          'The data is updating. This may take a while.'
                        }
                      >
                        <div className="text-xs">Loading</div>
                      </Tooltip>
                    ) : (
                      <>
                        {row.keyword?.search_volume === null ||
                        row.keyword?.search_volume === undefined
                          ? '-'
                          : row.keyword?.search_volume === 0
                            ? '< 10'
                            : formatThousandSeperator(
                                row.keyword?.search_volume ?? 0,
                              )}
                      </>
                    )}
                  </div>
                ),
                colSpan: 1,
              },
              {
                rightAlign: true,
                render: (_, row) => (
                  <div className="opacity-60">
                    {!row.keyword?.search_volume && !row.keyword?.difficulty ? (
                      <Tooltip
                        description={
                          'The data is updating. This may take a while.'
                        }
                      >
                        <div className="text-xs">Loading</div>
                      </Tooltip>
                    ) : (
                      row.keyword?.difficulty
                    )}
                  </div>
                ),
                colSpan: 1,
              },
              {
                render: () => <div />,
                colSpan: 1,
              },
              {
                render: (item) => (
                  <div className="flex">
                    <a
                      href={item.url ?? ''}
                      target="_blank"
                      className="max-w-xs truncate hover:opacity-50"
                      rel="noreferrer"
                    >
                      {item.path}
                    </a>
                  </div>
                ),
                colSpan: 1,
              },
              {
                render: () => <div />,
                colSpan: 1,
              },
            ],
            rows: ((item) => item.latest_update?.results ?? []) as (
              item: TrackedKeywordResource,
            ) => TrackedKeywordUpdateResultResource[],
          }}
          columns={[
            {
              heading: 'Keyword',
              sortableHeader: 'keyword',
              render: (item) => (
                <div className="flex font-semibold">
                  <div
                    className="cursor-pointer hover:opacity-60"
                    onClick={() => {
                      setSlideoverTab('positions');
                      setSelectedKeyword(item);
                    }}
                  >
                    {item.keyword?.name}
                  </div>
                </div>
              ),
            },
            {
              heading: 'Position',
              rightAlign: true,
              sortableHeader: 'position',
              render: (item) =>
                !item.latest_update ? (
                  <SkeletonLoader height="2xs" />
                ) : item.latest_update.position ? (
                  <div className={`flex`}>
                    <RankLabel
                      variant={getRankTrackingVariant(
                        item.latest_update.position,
                      )}
                      rank={item.latest_update.position}
                    />
                  </div>
                ) : (
                  <div>-</div>
                ),
            },
            {
              heading: 'Change',
              sortableHeader: 'change',
              rightAlign: true,
              render: (item) =>
                !item.latest_update ? (
                  <SkeletonLoader height="2xs" />
                ) : (
                  <div className="flex flex-col items-end gap-1">
                    <ChangeIndicator value={item.latest_update.change} />
                  </div>
                ),
            },
            {
              heading: 'Est. traffic',
              sortableHeader: 'estimated_traffic',
              rightAlign: true,
              render: (item) => (
                <div className="text-right">
                  {!item.keyword?.search_volume && !item.keyword?.difficulty ? (
                    <Tooltip
                      description={
                        'The data is updating. This may take a while.'
                      }
                    >
                      <div className="text-xs">Loading</div>
                    </Tooltip>
                  ) : (
                    <>
                      {item.latest_update?.estimated_traffic === null ||
                      item.latest_update?.estimated_traffic === undefined
                        ? '-'
                        : formatThousandSeperator(
                            Number(item.latest_update?.estimated_traffic),
                          )}
                    </>
                  )}
                </div>
              ),
            },
            {
              heading: 'Search volume',
              sortableHeader: 'search_volume',
              rightAlign: true,
              render: (item) => (
                <div className="text-right">
                  {!item.keyword?.search_volume && !item.keyword?.difficulty ? (
                    <Tooltip
                      description={
                        'The data is updating. This may take a while.'
                      }
                    >
                      <div className="text-xs">Loading</div>
                    </Tooltip>
                  ) : (
                    <>
                      {item.keyword?.search_volume === null ||
                      item.keyword?.search_volume === undefined
                        ? '-'
                        : item.keyword?.search_volume === 0
                          ? '< 10'
                          : formatThousandSeperator(
                              item.keyword?.search_volume ?? 0,
                            )}
                    </>
                  )}
                </div>
              ),
            },
            {
              heading: 'Difficulty',
              sortableHeader: 'difficulty',
              rightAlign: true,
              render: (item) => (
                <>
                  {!item.keyword?.search_volume && !item.keyword?.difficulty ? (
                    <Tooltip
                      description={
                        'The data is updating. This may take a while.'
                      }
                    >
                      <div className="text-xs">Loading</div>
                    </Tooltip>
                  ) : (
                    item.keyword?.difficulty
                  )}
                </>
              ),
            },
            {
              heading: 'Link',
              sortableHeader: 'url',
              render: (item, _, { subrowCount, toggleSubRows }) => (
                <div className="flex max-w-xs flex-col gap-1 overflow-hidden">
                  {item.latest_update ? (
                    <div className="flex items-center gap-2">
                      <a
                        title={item.latest_update?.meta_title ?? ''}
                        href={item.latest_update?.url ?? ''}
                        target="_blank"
                        className="truncate hover:opacity-50"
                        rel="noreferrer"
                      >
                        {item.latest_update.path}
                      </a>
                      {subrowCount > 1 ? (
                        <div
                          className="cursor-pointer text-sm text-primary-600 hover:opacity-50"
                          onClick={toggleSubRows}
                        >
                          (+{item.latest_update.results?.length! - 1})
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <SkeletonLoader height="2xs" />
                  )}
                </div>
              ),
            },
            {
              render: (item) => (
                <div className="flex flex-col items-center justify-center">
                  <PopoverMenu
                    isOpen={item.id === showOptionsForKeywordId}
                    onClose={() => setShowOptionsForKeywordId(undefined)}
                    items={[
                      {
                        prependIcon: History,
                        title: 'View history',
                        onClick: () => {
                          setSlideoverTab('positions');
                          setSelectedKeyword(item);
                        },
                      },
                      {
                        prependIcon: Table2,
                        title: 'SERP overview',
                        onClick: () => {
                          setSlideoverTab('serp-overview');
                          setSelectedKeyword(item);
                        },
                      },
                      {
                        prependIcon: Plus,
                        title: 'Create new content',
                        onClick: () => {
                          navigate({
                            to: '/create-content/blank',
                            search: (prev) => ({
                              ...prev,
                              keyword: item.keyword?.name,
                            }),
                          });
                        },
                      },
                      {
                        prependIcon: Globe,
                        title: 'Open in editor',
                        disabled: !item.latest_update?.url,
                        onClick: () => {
                          navigate({
                            to: '/create-content/import',
                            search: (prev) => ({
                              ...prev,
                              url: item.latest_update?.url ?? '',
                            }),
                          });
                        },
                      },
                      {
                        prependIcon: ListPlus,
                        title: 'Add to list',
                        onClick: () => {
                          setTrackingToBeAddedToList([item]);
                        },
                      },
                      ...(selectedList
                        ? [
                            {
                              prependIcon: ListMinus,
                              title: 'Remove from list',
                              onClick: () => {
                                setTrackingToBeRemovedFromList([item]);
                              },
                            },
                          ]
                        : []),
                      {
                        prependIcon: Trash2,
                        title: 'Delete',
                        onClick: () => {
                          setShowOptionsForKeywordId(undefined);
                          setTrackingToBeDeleted([item]);
                        },
                      },
                    ]}
                    trigger={
                      <IconButton
                        icon={MoreVertical}
                        onClick={() => setShowOptionsForKeywordId(item.id)}
                      />
                    }
                  />
                </div>
              ),
            },
          ]}
        >
          <Table.Header
            onExportCSV={{
              isLoading: isExportingRankTrackingKeywords,
              onClick: () => {
                exportingRankTrackingKeywords({
                  pathParams: {
                    project: appState.currentProject!.id,
                  },
                  body: {
                    filters: tableState.filters,
                    sort_by: tableState.sortBy,
                    sort_direction: tableState.sortDirection,
                  },
                }).then((response) => {
                  downloadCSV(response, 'rank_tracking_keywords.csv');
                });
              },
            }}
            search={tableState.filters?.search}
            onSearchChange={(value) => setFilters({ search: value })}
          >
            {selection.length === 0 ? (
              <RankTrackingTableFilters
                isLoading={rankTrackingQuery.isLoading}
                total={rankTrackingQuery.data?.meta?.total}
                tableState={tableState}
                setFilters={setFilters}
              />
            ) : (
              <SelectionActions
                selection={selection}
                actions={[
                  {
                    prependIcon: Trash2,
                    size: 'sm',
                    dense: true,
                    text: 'Delete',
                    variant: 'ghost',
                    onClick: () => setTrackingToBeDeleted(selection),
                  },
                  {
                    prependIcon: Copy,
                    size: 'sm',
                    dense: true,
                    text: 'Copy',
                    variant: 'ghost',
                    onClick: handleCopyToClipboard,
                  },
                  {
                    prependIcon: ListPlus,
                    size: 'sm',
                    dense: true,
                    text: 'Add to list',
                    variant: 'ghost',
                    onClick: () => setTrackingToBeAddedToList(selection),
                  },
                  ...(selectedList
                    ? [
                        {
                          prependIcon: ListMinus,
                          size: 'sm' as const,
                          dense: true,
                          text: 'Remove from list',
                          variant: 'ghost' as const,
                          onClick: () =>
                            setTrackingToBeRemovedFromList(selection),
                        },
                      ]
                    : []),
                ]}
              />
            )}
          </Table.Header>
          <Table.NoContent>
            <div className="flex flex-col items-center gap-4">
              <LineChart size={128} className="text-primary-200" />
              <p className="text-lg font-bold text-primary">No results</p>
              <Tooltip
                disabled={(appState.currentProject?.domain?.length ?? 0) > 0}
                description="Your project does not have a domain, which is required to track keywords"
              >
                <Button
                  disabled={
                    !appState.subscription?.usage.credits.tracked_keywords
                      .is_allowed ||
                    (appState.currentProject?.domain?.length ?? 0) === 0
                  }
                  onClick={() => setShowAddKeywordDialog(true)}
                  text="Add keywords"
                  prependIcon={Plus}
                  color="secondary"
                  variant="outline"
                />
              </Tooltip>
            </div>
          </Table.NoContent>
          <Table.Footer>
            {(rankTrackingQuery.data?.data ?? []).length > 0 && (
              <Pagination
                currentPage={tableState.page}
                lastPage={rankTrackingQuery.data?.meta.last_page ?? 1}
                setCurrentPage={setPage}
              />
            )}
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
};
