import { Input } from '@/Components/v2/Input/Input';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { ReactNode, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { DiffRow } from './DiffRow';
import { EditableContainer } from './EditableContainer';
import { MarkdownDiff } from './MarkdownDiff';
import { PlaintextDiff } from './PlaintextDiff';
import { Button } from '@/Components/v2/Button';
import Toggle from '@/Components/Toggle';

type Props = {
  current: {
    heading: string;
    metaTitle: string;
    metaDescription: string;
    body: string;
    setTitle: (title: string) => void;
    setMetaDescription: (metaDescription: string) => void;
    setBody: (body: string) => void;
    setHeading: (heading: string) => void;
  };
  editor?: ReactNode;
};

export const DiffEditor = ({ current, editor }: Props) => {
  const [isEditingBody, setIsEditingBody] = useState(false);
  const [inputOldHeading, setInputOldHeading] = useState('');
  const [inputOldTitle, setinputOldTitle] = useState('');
  const [inputOldMetaDescription, setInputOldMetaDescription] = useState('');
  const [inputOldBody, setInputOldBody] = useState('');

  const [heading, setHeading] = useState('');
  const [actualHeading, setActualHeading] = useState('');

  const [metatitle, setMetaTitle] = useState('');
  const [actualTitle, setActualTitle] = useState('');

  const [metaDescription, setMetaDescription] = useState('');
  const [actualMetaDescription, setActualMetaDescription] = useState('');

  const body = `# Random Potato XD: So Quirky!

Feeling like your life needs a bit of spontaneous fun? What if a quirky twist could help?

Enter the world of "Random Potato XD: So Quirky," where humor is injected into your daily routine.

By embracing the unexpected and hilariously random, this phenomenon can bring a smile to anyone's face.

## The Charm of Quirky Potatoes

Quirky potatoes have a unique charm that adds a whimsical twist to everyday life.

In 2016, quirky potatoes—a social media trend involving creatively decorated spuds—brought joy and laughter amidst the mundane. People loved sharing photos of their quirky potato creations online, fostering a community fueled by fun and creativity.

Indeed, it's not every day that a simple spud can transform into a delightful source of entertainment. From mustache-wearing potatoes to those dressed as superheroes, these quirky vegetables ignited an imaginative spark in people of all ages.

Whether you encounter one in your kitchen or spot a creative display on social media, there's no denying the power of the quirky potato to brighten your day. With endless customization possibilities, quirky potatoes continue to inspire and amuse us.

Join the quirky potato movement and discover the unbridled joy these charming spuds can bring.

## The History of Potatoes in Pop Culture

Potatoes have charmed pop culture for centuries.

From Mr. Potato Head's debut in 1952 to modern memes, the humble tuber has remained a versatile symbol. Its journey through pop culture highlights the adaptability of potatoes, whether as quirky toys, viral internet sensations, or beloved food items. The potato's inherent simplicity allows it to represent playfulness, creativity, and nostalgia across generations.

These representations have continued through digital eras.

The "random potato XD so quirky" memes of the internet age have further solidified its status. This playful image serves as both a humorous respite and a unifier of online culture.

From early farming symbolism in children's toys to the plethora of quirky internet potatoes we see today, potatoes have been an integral, if often unassuming, part of popular culture. Their consistent presence underscores their innate appeal and timeless ability to adapt and enchant.

## Why Are Potatoes So Versatile?

Potatoes are incredibly versatile due to their myriad culinary applications and nutritional value. They can be boiled, baked, mashed, or fried, making them a staple in many kitchens.

Their mild flavor allows them to complement a variety of dishes.

Beyond culinary uses, potatoes have industrial applications, from adhesives to biodegradable plastics. This versatility extends their usefulness well beyond the kitchen.

Potatoes thrive in diverse climates, ensuring they are available worldwide. This availability and adaptability enhance their global popularity. From comfort foods to complex dishes, the humble potato’s versatility ensures it remains relevant across different cultures and cuisines.

## The Rise of the 'Random Potato' Meme

In recent years, the "random potato XD so quirky" meme has exploded in popularity, thanks to its nonsensical yet relatable humor that resonates with internet culture.

Millennials and Gen Z have embraced the humor in randomness, leading to the rise of quirky memes.

The randomness of a potato—an otherwise mundane object—perfectly embodies this absurdist trend. People love it for its unexpectedness, simplicity, and sheer silliness.

The quirky nature of these memes adds to their charm and reach. This meme format thrives on a mix of surprise and nostalgia, combining everyday objects with absurd humor. With each surprising twist, the "random potato" meme secures its foothold as a staple of internet humor.

## How to Use Potatoes in Creative Cooking

Get inspired and think outside the box!

Potatoes can be more than just your average side dish. From crispy potato tacos to sweet potato pancakes, there are endless opportunities to explore creative cooking with this versatile vegetable. Experimenting in the kitchen can lead to innovative dishes that surprise and delight your taste buds.

Try blending mashed potatoes with herbs or incorporating them into savory cakes, like potato and salmon cakes.

Consider using potatoes as a crispy pizza crust alternative. This trend involves grating potatoes and pressing them into a thin layer to form a crust, then baking until crisp. It’s a glorious gluten-free option that also adds a unique flavor twist.

Don’t forget about using a spiralizer to make potato noodles, creating fun substitutes for traditional pasta. Such inventive methods allow potatoes to shine not just as a side dish, but as the star of your gastronomic creations.

## Fun Facts About Potatoes

Potatoes are incredibly versatile vegetables.

Did you know that potatoes were the first vegetable grown in space? This fascinating feat was accomplished in 1995 aboard the Space Shuttle Columbia, making potatoes the first space-grown product of this essential food group.

Potatoes come in multiple colors and are excellent sources of vitamin C. A medium potato contains about 30% of the daily recommended intake, making it a surprisingly good option for boosting your immune system with just one serving.

Potatoes are deliciously quirky and nutritionally valuable. Their impact on culinary cultures worldwide is undeniable, and they continue to be a staple in inventive and classic recipes alike. From space explorations to vital health benefits, this humble vegetable certainly has quite the resume!

## Quirky Potato Recipes to Try

If you're a fan of quirky and delicious dishes, you’ll love these unique potato recipes. Potatoes are not only versatile but also add a fun twist in the world of cooking.

First up, consider making potato waffles for breakfast. These crispy delights can be topped with savory items.

For a snack, try making potato popsicles. Yes, you read that right—a frozen treat made of smooth potato puree.

Feeling adventurous? Create a potato pizza by replacing the traditional pizza crust with a thin layer of baked, seasoned potatoes.

Need a quirky dinner idea? Make potato sushi! Use thinly sliced, cooked potatoes as the "rice" and wrap them around your favorite sushi fillings.

Finally, for dessert, indulge in potato brownies. These fudgy treats include mashed potatoes, making them moist and delicious without tasting like potatoes.

## Decorating with Potatoes

Turn heads with potato decorations!

This quirky idea makes for fun and unique decor. Potatoes can be crafted into cute and creative decorations for various occasions, such as holiday centerpieces or whimsical room accents. Surprisingly, potatoes can be dyed or painted to match any theme or color scheme.

Potato stamps are also a fun idea. Simply cut a potato in half, carve out a design, and use it as a stamp. Press these potato stamps into paint to create custom wrapping paper or greeting cards. This easy craft project can add a personalized touch to your gifts.

Sculpting potatoes into shapes for table decor or party themes can be surprisingly enjoyable. From quirky Christmas ornaments to Halloween jack-o'-lanterns, potatoes offer endless possibilities for creativity. Embrace the versatility of potatoes and turn them into unique, eye-catching decorations that will surely garner compliments from guests.

## Growing Your Own Potatoes

Growing your own potatoes is an engaging and rewarding experience, perfect for gardening enthusiasts and food lovers alike. A great starting point is selecting the right seed potatoes.

Seed potatoes are essentially small potatoes that have sprouted shoots. Once you have these, choose a suitable location to plant them. Potatoes thrive in well-drained, fertile soil with plenty of sunlight. A raised bed or large container is ideal.

Prepare the soil by digging trenches about four inches deep and planting the seed potatoes with the eyes facing up. As the plants grow, hill the soil around the stems to keep the developing tubers covered. This prevents them from being exposed to sunlight, which can cause them to turn green and become inedible.

Regular watering and a little patience will yield a wonderful harvest in about 10 to 12 weeks. When the plant's foliage starts to yellow and die, it's usually a sign that your potatoes are ready to be harvested. Dig carefully to avoid damaging your precious spuds, and enjoy the satisfaction that comes from eating homegrown potatoes.

## The Science Behind Potato Growth

Potato plants, or *Solanum tuberosum*, undergo a fascinating growth process influenced by various environmental factors. Soil conditions and temperature significantly impact their development.

Potato plants require nutrient-rich soil, with nutrients like nitrogen, phosphorus, and potassium being vital.

Photosynthesis fuels potato growth. The plant's leaves absorb sunlight and convert it into energy, which is then used to develop tubers underground. As the plant matures, the energy is redirected from the leaves to the tubers.

Proper irrigation is crucial for growth and tuber size. Inconsistent watering leads to irregularly shaped potatoes, impacting yield. It's important to maintain consistent care for quality produce. Balancing irrigation, sunlight, and nutrient supply will ensure a healthy and abundant harvest.

## Potato Crafts for Kids

Unleash creativity with potato crafts!

Kids can transform ordinary potatoes into fun shapes. All they need are a few basic supplies like potatoes, paints, googly eyes, and pipe cleaners. They can create potato stamps, paint adorable potato animals, or even make funny potato characters to display.

These crafts are engaging and simple.

Not only are potato crafts a blast, but they also help develop fine motor skills. Kids will learn to handle paintbrushes and crafting materials, enhancing their coordination.

Parents and kids can bond over these activities. Spend a rainy afternoon crafting together and creating memorable art pieces. Let children’s imaginations run wild with "random potato XD so quirky" creations that bring laughter and joy to your household.

## The Nutritional Benefits of Potatoes

Potatoes are a beloved staple food, offering numerous nutritional benefits. Rich in complex carbohydrates, they provide sustained energy, making them a powerhouse food perfect for everyday meals.

They also boast a wealth of dietary fiber, which is essential for a healthy digestive system, promoting regular bowel movements. It can also help you feel fuller longer, aiding in weight management.

In addition, potatoes are a good source of vitamins. Vitamin C, found in potatoes, is crucial for immune function and skin health. Vitamin B6 supports brain development and function, contributing to overall well-being.

With their myriad benefits and delightful quirkiness, potatoes truly are a remarkable vegetable!

`;

  const [actualBody, setActualBody] = useState(body);

  const [showChanges, setShowChanges] = useState(true);

  return (
    <div className="flex flex-col">
      <div className="mb-16 bg-primary-50 p-4">
        <p className="text-lg font-bold">Debugging tools</p>

        <DiffRow before={'Before'} after={'After'} />
        <DiffRow
          before={
            <Input
              value={inputOldHeading}
              onChange={(value) => setInputOldHeading(value)}
            />
          }
          header={'Heading'}
        />
        <DiffRow
          before={
            <Input
              value={inputOldTitle}
              onChange={(value) => setinputOldTitle(value)}
            />
          }
          header={'Metatitle'}
        />
        <DiffRow
          before={
            <TextArea
              value={inputOldMetaDescription}
              onChange={(value) => setInputOldMetaDescription(value)}
            />
          }
          header={'Meta description'}
        />
        <DiffRow
          before={
            <TextArea
              value={inputOldBody}
              onChange={(value) => setInputOldBody(value)}
            />
          }
          header={'Body (Markdown)'}
        />

        <div className="mt-4 flex justify-end">
          <Button
            text="Set values"
            color="secondary"
            onClick={() => {
              setHeading(inputOldHeading);
              setActualHeading(inputOldHeading);

              setMetaTitle(inputOldTitle);
              setActualTitle(inputOldTitle);

              setMetaDescription(inputOldMetaDescription);
              setActualMetaDescription(inputOldMetaDescription);
            }}
          />
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <div className="flex items-center gap-2">
          <Toggle value={showChanges} onChange={setShowChanges} />
          <span>Show changes</span>
        </div>
      </div>
      <DiffRow hideBefore={isEditingBody} before={'Before'} after={'After'} />
      <DiffRow
        hideBefore={isEditingBody}
        before={<div className="text-lg font-bold">{heading}</div>}
        after={
          <div className="text-2xl font-bold">
            <PlaintextDiff
              newText={current.heading}
              oldText={showChanges ? actualHeading : current.heading}
              onChange={({ actualValue, newValue }) => {
                if (actualValue) setActualHeading(actualValue);
                if (newValue) current.setHeading(newValue);
              }}
            />
          </div>
        }
        header={'Heading'}
      />
      <DiffRow
        hideBefore={isEditingBody}
        before={<div className="text-lg font-bold">{metatitle}</div>}
        after={
          <PlaintextDiff
            newText={current.metaTitle}
            oldText={showChanges ? actualTitle : current.metaTitle}
            onChange={({ actualValue, newValue }) => {
              if (actualValue) setActualTitle(actualValue);
              if (newValue) current.setTitle(newValue);
            }}
          />
        }
        header={'Metatitle'}
      />
      <DiffRow
        hideBefore={isEditingBody}
        before={<div className="whitespace-pre-wrap">{metaDescription}</div>}
        after={
          <div className="whitespace-pre-wrap">
            <PlaintextDiff
              newText={current.metaDescription}
              oldText={
                showChanges ? actualMetaDescription : current.metaDescription
              }
              onChange={({ actualValue, newValue }) => {
                if (actualValue) setActualMetaDescription(actualValue);
                if (newValue) current.setMetaDescription(newValue);
              }}
            />
          </div>
        }
        header={'Meta description'}
      />
      <DiffRow
        header="Body"
        hideBefore={isEditingBody}
        before={
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="prose text-base leading-tight"
          >
            {body}
          </ReactMarkdown>
        }
        after={
          <EditableContainer
            onStartEditing={() => setIsEditingBody(true)}
            onFinishedEditing={() => setIsEditingBody(false)}
            preview={
              <MarkdownDiff
                oldMarkdown={showChanges ? actualBody : current.body}
                newMarkdown={current.body}
                onChange={({ actualValue, newValue }) => {
                  if (actualValue) setActualBody(actualValue);
                  if (newValue) current.setBody(newValue);
                }}
              />
            }
            editor={editor}
          />
        }
      />
    </div>
  );
};
