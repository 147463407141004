import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody';
import { CurrentSlideOver } from '@/types';
import { Link, MessageCircleQuestion, SearchX, Table2 } from 'lucide-react';
import { ReportSection } from '../ReportSection/components/ReportSection';
import { ToolCard } from './components/ToolCard';

type Props = {
  onOpenCompetitorBenchmarkTable: () => void;
  setCurrentSlideOver: (value?: CurrentSlideOver) => void;
};

export const Tools = ({
  setCurrentSlideOver,
  onOpenCompetitorBenchmarkTable,
}: Props) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-2 overflow-y-auto p-4">
      <div className="w-full max-w-2xl px-6">
        <ReportSection title="SEO TOOLS">
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] justify-items-center gap-4">
            <ToolCard
              title="Keyword Gaps"
              icon={SearchX}
              description="Uncover keyword gaps in your content based on Search Console data."
              onClick={() => setCurrentSlideOver('keyword-gap')}
            />
            <ToolCard
              title="Competitor Benchmark"
              icon={Table2}
              description="Compare your performance against your competitors."
              onClick={onOpenCompetitorBenchmarkTable}
            />
            <ToolCard
              title="Questions"
              icon={MessageCircleQuestion}
              description="Find questions that your audience is asking."
              onClick={() => setCurrentSlideOver('questions')}
            />
            <ToolCard
              title="Internal links"
              icon={Link}
              description="Add internal links to your content"
              onClick={() => setCurrentSlideOver('internal-links')}
            />
          </div>
        </ReportSection>
      </div>
    </div>
  );
};
