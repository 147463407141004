type Props = {
  name: string;
  value: string;
};

export const StatItem = ({ name, value }: Props) => {
  return (
    <div className="flex items-end gap-1">
      <div className={`-mb-1.5 text-2xl font-bold text-primary`}>{value}</div>
      <div className="text-sm text-primary">{name}</div>
    </div>
  );
};
