import { ButtonColors } from '../Button';

export type CustomSvgProps = {
  /**
   * How large should the icon be?
   */
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  /**
   * What color to use?
   */
  color?: ButtonColors;
  /**
   * The svg data
   */
  children: JSX.Element | JSX.Element[];
};

const SvgIcon = ({ size = 'base', color, children }: CustomSvgProps) => {
  const getStrokeColor = color
    ? {
        green: 'stroke-green-400',
        red: 'stroke-red-400',
        blue: 'stroke-blue-400',
        gray: 'stroke-gray-400',
        primary: 'stroke-primary-400',
        secondary: 'stroke-secondary-400',
        white: 'stroke-white',
        orange: 'stroke-orange-400',
        yellow: 'stroke-yellow-400',
      }[color]
    : 'stroke-white';

  const iconGetSize = (size: 'xs' | 'sm' | 'base' | 'lg' | 'xl') => {
    switch (size) {
      case 'xs':
        return 12;
      case 'sm':
        return 16;
      case 'lg':
        return 24;
      case 'xl':
        return 28;
      default:
        return 20;
    }
  };
  return (
    <svg
      className={`m-auto block bg-none ${getStrokeColor}`}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width={iconGetSize(size)}
      height={iconGetSize(size)}
    >
      {children}
    </svg>
  );
};

SvgIcon.displayName = 'CustomSvg';
export { SvgIcon };
