import {
  useGetCmsConnection,
  useGetShopifyAdminScopeUrl,
  useGetShopifyScopes,
} from '@/api/openapiComponents';
import { Banner } from '../Banner';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useEffect, useState } from 'react';
import { ShopifyScopesResource } from '@/api/openapiSchemas';
import { Button } from '@/Components/v2/Button';

export const ShopifyScopesBanner = () => {
  const appState = useAppStore();

  const [missingScopes, setmissingScopes] = useState<ShopifyScopesResource[]>(
    [],
  );

  const shopifyScopesQuery = useGetShopifyScopes({});
  const cmsConnectionQuery = useGetCmsConnection({
    pathParams: {
      project: appState.currentProject!.id,
    },
  });

  const shopifyUrlMutation = useGetShopifyAdminScopeUrl();

  const handleOpenShopifyAdminUrl = () => {
    if (cmsConnectionQuery.isSuccess) {
      const cmsConnection = cmsConnectionQuery.data.data;

      if (cmsConnection.state !== 'active') {
        return;
      }

      if (cmsConnection.settings.scopesUrl) {
        window.open(cmsConnection.settings.scopesUrl, '_blank');
        return;
      }
    }

    shopifyUrlMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) => {
          if (data.data.settings.scopesUrl === null) {
            return;
          }
          cmsConnectionQuery.refetch();

          window.open(data.data.settings.scopesUrl, '_blank');
          shopifyUrlMutation.reset();
        },
      },
    );
  };

  useEffect(() => {
    if (shopifyScopesQuery.isSuccess && cmsConnectionQuery.isSuccess) {
      const shopifyScopes = shopifyScopesQuery.data.data;
      const cmsConnection = cmsConnectionQuery.data.data;

      if (cmsConnection.state !== 'active') {
        setmissingScopes([]);
        return;
      }

      const missingScopes = shopifyScopes.filter(
        (scope) => !cmsConnection.settings.accessScopes.includes(scope.name),
      );

      setmissingScopes(missingScopes);
    }
  }, [shopifyScopesQuery.isSuccess, cmsConnectionQuery.isSuccess]);

  return (
    <Banner
      isOpen={missingScopes?.length > 0}
      className="flex flex-col items-center gap-4 bg-amber-100 py-3"
    >
      <div className="flex flex-col gap-2">
        <p className="flex items-center gap-2 font-semibold">
          We have made updates to our shopify integration which requires the
          following additional scopes
        </p>
        <ul className="ml-4">
          {missingScopes.map((scope) => (
            <li key={scope.name}>• {scope.name}</li>
          ))}
        </ul>
        <div>
          <Button
            size="sm"
            dense
            text="Update Shopify Scopes"
            variant="outline"
            isLoading={shopifyUrlMutation.isPending}
            onClick={handleOpenShopifyAdminUrl}
          />
        </div>
      </div>
    </Banner>
  );
};
