import React, { useLayoutEffect, useRef, useState } from 'react';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
};

export const AnimatedCollapsibleContainer = ({ children, isOpen }: Props) => {
  const [height, setHeight] = useState(isOpen ? undefined : 0);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(isOpen ? ref.current.clientHeight : 0);

      const resizeObserver = new ResizeObserver(() => {
        setHeight(isOpen ? ref.current?.scrollHeight : 0);
      });
      resizeObserver.observe(ref.current);
      return () => resizeObserver.disconnect();
    }
  }, [isOpen, ref.current?.clientHeight, children]);

  return (
    <div
      className="min-w-0 overflow-hidden transition-all"
      style={{ height: height }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};
