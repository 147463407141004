import { Icon } from '@/Components/v2/Icon';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { Tooltip } from '@/Components/v2/Tooltip';
import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { Check, ExternalLink, Info, X } from 'lucide-react';

type Props = {
  name: string;
  exported: boolean;
  error?: string;
  url?: string;
  admin_url?: string;
};

export const ExportJobItem = ({
  exported,
  name,
  admin_url,
  error,
  url,
}: Props) => {
  return (
    <div
      className={`flex items-center justify-between border-b border-primary-100 py-0.5 `}
    >
      <div
        className={`flex flex-grow items-center gap-2 truncate ${name === 'Untitled' ? 'text-primary' : ''}`}
      >
        {name || 'Untitled'}
      </div>
      <div className="flex flex-shrink-0 items-center gap-1">
        {error && (
          <Tooltip title={error}>
            <Info className="mt-1 text-primary" size={18} />
          </Tooltip>
        )}

        {exported ? (
          <>
            {url && (
              <IconButton
                dense
                icon={ExternalLink}
                onClick={() => window.open(url)}
              />
            )}
            {admin_url && (
              <IconButton
                dense
                icon={
                  <Icon
                    icon={faShopify}
                    className="h-[18px] w-[18px] text-primary"
                  />
                }
                onClick={() => window.open(admin_url)}
              />
            )}
            <Check className="text-green" />
          </>
        ) : error ? (
          <X className="text-red" />
        ) : (
          <div>
            <LoadingSpinner color="primary" />
          </div>
        )}
      </div>
    </div>
  );
};
