import { Check } from 'lucide-react';

type Props = {
  checked: boolean;
};

export const Checkmark = ({ checked }: Props) => {
  return (
    <div
      className={`flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full ${checked ? 'bg-green-400' : 'bg-red-400'}`}
    >
      {checked ? (
        <Check fontWeight={900} className="text-white" size={14} />
      ) : (
        <div className="font-bold text-white">!</div>
      )}
    </div>
  );
};
