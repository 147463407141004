import { Button } from '@/Components/v2/Button';
import { Check, Pencil, Undo2 } from 'lucide-react';
import { ReactNode, useState } from 'react';

type Props = {
  preview: ReactNode;
  editor: ReactNode;
  onStartEditing?: () => void;
  onFinishedEditing?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
};

export const EditableContainer = ({
  preview,
  editor,
  onAccept,
  onReject,
  onFinishedEditing,
  onStartEditing,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <form
      className="h-full"
      onSubmit={(e) => {
        e.preventDefault();
        setIsEditing(false);
        onFinishedEditing?.();
      }}
    >
      <div className="group flex h-full flex-col">
        <div className="flex-grow">{isEditing ? editor : preview}</div>
        <div
          className={`sticky bottom-0 z-50 flex justify-end gap-2 bg-white p-2 ${
            isEditing ? '' : 'opacity-0 group-hover:opacity-100'
          }`}
        >
          <button type="submit" />
          {isEditing ? (
            <Button
              type="submit"
              dense
              size="sm"
              variant="outline"
              text="finish editing"
              appendIcon={Check}
            />
          ) : (
            <>
              <Button
                dense
                size="sm"
                variant="outline"
                text="Edit"
                appendIcon={Pencil}
                onClick={() => {
                  setIsEditing(true);
                  onStartEditing?.();
                }}
              />
              <Button
                dense
                size="sm"
                variant="outline"
                text="Reject"
                appendIcon={Undo2}
                onClick={onReject}
              />
              <Button
                dense
                size="sm"
                variant="outline"
                text="Accept"
                appendIcon={Check}
                onClick={onAccept}
              />
            </>
          )}
        </div>
      </div>
    </form>
  );
};
