import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { StatItem } from './StatItem';
import { IconToggle } from '@/Components/IconToggle';
import { Eye, Medal, MousePointerClick, ScanSearch } from 'lucide-react';
import { CustomGraphTooltip } from '@/Pages/RankTracking/components/CustomGraphTooltip';
import { formatLargeNumber, formatThousandSeperator } from '@/utils';
import dayjs from 'dayjs';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { Select } from '@/Components/v2/Select';
import { ListPageQueriesQueryParams } from '@/api/openapiComponents';
import ToggleButton from '@/Components/ToggleButton';
import { GraphState } from '../hooks';
import ReactCountryFlag from 'react-country-flag';
import { useState } from 'react';
import { ChooseCountryDialog } from './ChooseCountryDialog';

type DataPoint = {
  date: string;
  clicks?: number;
  impressions?: number;
  ctr?: number;
  position?: number;
};

type Props = {
  state: GraphState;
  onStateChange: (state: GraphState) => void;
  data?: DataPoint[];
  metrics?: {
    current: Omit<DataPoint, 'date'>;
    previous: Omit<DataPoint, 'date'>;
  };
};

export const rangeDisplayNames = {
  P1W: '7 days',
  P2W: '14 days',
  P4W: '28 days',
  P3M: '3 months',
  P6M: '6 months',
  P1Y: '1 year',
  P16M: '16 months',
};

export const AnalyticsGraph = ({
  data,
  metrics,
  onStateChange,
  state,
}: Props) => {
  const [showChooseCountryDialog, setShowChooseCountryDialog] = useState(false);

  return (
    <div>
      <ChooseCountryDialog
        isOpen={showChooseCountryDialog}
        onChooseCountry={(country) => onStateChange({ ...state, country })}
        onClose={() => setShowChooseCountryDialog(false)}
      />
      <div className="mb-2 mt-6 flex items-center justify-end">
        {/* Graph settings */}
        <div className="flex items-center gap-2">
          <IconToggle
            icon={ScanSearch}
            color="blue"
            value={state.clicks}
            onClick={() =>
              onStateChange({
                ...state,
                clicks: !state.clicks,
              })
            }
          />
          <IconToggle
            icon={Eye}
            color="purple"
            value={state.impressions}
            onClick={() =>
              onStateChange({
                ...state,
                impressions: !state.impressions,
              })
            }
          />
          <IconToggle
            icon={MousePointerClick}
            color="green"
            value={state.ctr}
            onClick={() =>
              onStateChange({
                ...state,
                ctr: !state.ctr,
              })
            }
          />
          <IconToggle
            icon={Medal}
            color="orange"
            value={state.position}
            onClick={() =>
              onStateChange({
                ...state,
                position: !state.position,
              })
            }
          />
          <ToggleButton
            value={state.granularity}
            options={[
              {
                displayName: 'Day',
                value: 'day',
              },
              {
                displayName: 'Week',
                value: 'week',
              },
              {
                displayName: 'Month',
                value: 'month',
              },
            ]}
            onChange={(value) =>
              onStateChange({
                ...state,
                granularity: value,
              })
            }
          />

          <IconToggle
            icon={
              <ReactCountryFlag
                countryCode={state.country.iso_3166_2}
                svg
                style={{
                  height: '18px',
                  width: '23px',
                }}
                className="border border-gray-100"
              />
            }
            value={false}
            onClick={() => setShowChooseCountryDialog(true)}
          />

          <Select
            value={state.range}
            onChange={(value) =>
              onStateChange({
                ...state,
                range: value as ListPageQueriesQueryParams['range'],
              })
            }
            options={[
              {
                title: rangeDisplayNames.P1W,
                value: 'P1W',
              },
              {
                title: rangeDisplayNames.P2W,
                value: 'P2W',
              },
              {
                title: rangeDisplayNames.P4W,
                value: 'P4W',
              },
              {
                title: rangeDisplayNames.P3M,
                value: 'P3M',
              },
              {
                title: rangeDisplayNames.P6M,
                value: 'P6M',
              },
              {
                title: rangeDisplayNames.P1Y,
                value: 'P1Y',
              },
              {
                title: rangeDisplayNames.P16M,
                value: 'P16M',
              },
            ]}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        {data ? (
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorClicks" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#29A6F9" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#29A6F9" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPosition" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FFAE00" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#FFAE00" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorImpressions" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#9553FF" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#9553FF" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorCtr" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00D841" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#00D841" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={'date'}
              tickFormatter={(value) => dayjs(value as string).format('MMM D')}
            />

            <Tooltip
              isAnimationActive={false}
              position={{ y: 50 }}
              content={(row) =>
                CustomGraphTooltip(
                  {
                    ...row,
                    payload: row.payload?.map((item) => ({
                      ...item,
                      value:
                        item.id === 'position'
                          ? item.value.toFixed(1)
                          : item.id === 'ctr'
                            ? `${(item.value * 100).toFixed(2)}%`
                            : item.value,
                    })),
                  },
                  false,
                )
              }
            />

            {state.clicks && (
              <>
                <YAxis
                  yAxisId={'clicks'}
                  tickFormatter={(value) =>
                    formatThousandSeperator(Number(value))
                  }
                />
                <Area
                  animationDuration={0}
                  id="clicks"
                  yAxisId={'clicks'}
                  type="linear"
                  strokeWidth={2}
                  dataKey="clicks"
                  stroke="#29A6F9"
                  fill="url(#colorClicks)"
                />
              </>
            )}

            {state.ctr && (
              <>
                <YAxis
                  yAxisId={'ctr'}
                  hide={state.impressions}
                  orientation="right"
                />
                <Area
                  animationDuration={0}
                  id="ctr"
                  yAxisId={'ctr'}
                  type="linear"
                  strokeWidth={2}
                  dataKey="ctr"
                  stroke="#00D841"
                  fill="url(#colorCtr)"
                />
              </>
            )}

            {state.impressions && (
              <>
                <YAxis
                  yAxisId={'impressions'}
                  orientation="right"
                  allowDataOverflow
                  tickFormatter={(value) =>
                    formatThousandSeperator(Number(value))
                  }
                />
                <Area
                  animationDuration={0}
                  id="impressions"
                  yAxisId={'impressions'}
                  type="linear"
                  strokeWidth={2}
                  dataKey="impressions"
                  stroke="#9553FF"
                  fill="url(#colorImpressions)"
                />
              </>
            )}

            {state.position && (
              <>
                <YAxis
                  yAxisId={'position'}
                  hide={state.clicks}
                  reversed
                  domain={[0, 100]}
                />
                <Area
                  animationDuration={0}
                  baseValue={100}
                  id="position"
                  yAxisId={'position'}
                  type="linear"
                  strokeWidth={2}
                  dataKey="position"
                  stroke="#FFAE00"
                  fill="url(#colorPosition)"
                />
              </>
            )}
          </AreaChart>
        ) : (
          <div className="h-full">
            <SkeletonLoader height="full" />
          </div>
        )}
      </ResponsiveContainer>
      <div className="mb-6 flex justify-start">
        {metrics ? (
          <div className="flex items-center gap-4">
            <StatItem
              name="clicks"
              value={formatLargeNumber(metrics.current.clicks ?? 0)}
            />
            <StatItem
              name="impressions"
              value={formatLargeNumber(metrics.current.impressions ?? 0)}
            />
            <StatItem
              name="CTR"
              value={`${((metrics.current.ctr ?? 0) * 100).toFixed(1)}%`}
            />
            <StatItem
              name="position"
              value={metrics.current.position?.toFixed(1)}
            />
          </div>
        ) : (
          <div className="w-96">
            <SkeletonLoader height="xs" />
          </div>
        )}
      </div>
    </div>
  );
};
