import { useGetTopCountries } from '@/api/openapiComponents';
import { Dialog } from '@/Components/Dialog';
import { Input } from '@/Components/v2/Input/Input';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Search } from 'lucide-react';
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChooseCountry: (country: {
    iso_3166_2: string;
    iso_3166_3: string;
  }) => void;
};

export const ChooseCountryDialog = ({
  isOpen,
  onChooseCountry,
  onClose,
}: Props) => {
  const appState = useAppStore();

  const [searchInput, setSearchInput] = useState('');

  const countriesQuery = useGetTopCountries(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: isOpen,
    },
  );

  return (
    <Dialog title="Choose country" isOpen={isOpen} handleClose={onClose}>
      <div className="flex flex-col gap-2">
        <Input
          placeholder="Search country..."
          value={searchInput}
          onChange={setSearchInput}
          prependIcon={Search}
        />
        {countriesQuery.data?.data
          .filter((country) =>
            country.name?.toLowerCase()?.includes(searchInput.toLowerCase()),
          )
          .map((country) => {
            return (
              <div
                key={country.id}
                onClick={() => {
                  onChooseCountry(country);
                  onClose();
                }}
                className="flex cursor-pointer items-center gap-2 hover:opacity-60"
              >
                <ReactCountryFlag
                  countryCode={country.iso_3166_2}
                  svg
                  style={{
                    height: '18px',
                    width: '23px',
                  }}
                  className="border border-gray-100"
                />
                <span>{country.name}</span>
              </div>
            );
          })}
      </div>
    </Dialog>
  );
};
