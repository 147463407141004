import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { PageAnalytics } from './PageAnalytics';
import { PageAnalyticsGroup } from './PageAnalyticsGroup';
import { ManageGroups } from './ManageGroups';
import { validateTanStackSearch } from '@/utils';
import { PageDetails } from './PageDetails';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: validateTanStackSearch,
    path: '/page-analytics',
    component: () => <PageAnalytics />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: validateTanStackSearch,
    path: '/page-analytics/groups/$groupId',
    component: () => <PageAnalyticsGroup />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: validateTanStackSearch,
    path: '/page-analytics/page/$pageId',
    component: () => <PageDetails />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/page-analytics/groups',
    component: () => <ManageGroups />,
  }),
];
