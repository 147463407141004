import {
  useCreateShopifyConnection,
  useGetShopifyScopes,
} from '@/api/openapiComponents';
import { Dialog } from '@/Components/Dialog';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const ShopifySetupDialog = ({ isOpen, onClose, onSuccess }: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();

  const [shopNameInput, setShopNameInput] = useState('');
  const [apiKeyInput, setApiKeyInput] = useState('');
  const [apiSecretInput, setApiSecretInput] = useState('');
  const [storeFrontAccessTokenInput, setStoreFrontAccessTokenInput] =
    useState('');
  const [apiIdInput, setApiIdInput] = useState('');

  const createShopifyMutation = useCreateShopifyConnection();
  const scopesQuery = useGetShopifyScopes({});

  const errorHelper = new ErrorHelper(createShopifyMutation.error);

  const handleSubmit = () => {
    createShopifyMutation.mutate(
      {
        body: {
          shop_name: shopNameInput,
          api_key: apiKeyInput,
          api_secret: apiSecretInput,
          storefront_access_token: storeFrontAccessTokenInput,
          api_id: apiIdInput,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          client.refetchQueries({
            predicate: (query) => query.queryKey.includes('getCmsConnection'),
          });
          onClose();
          onSuccess();
        },
      },
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Create shopify connection"
      handleClose={onClose}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <p>
          For the shopify connection to work, the following scopes must be
          enabled in the app settings on shopify
        </p>
        <ul>
          {scopesQuery.data?.data.map((scope) => (
            <li
              key={scope.name}
              className={
                errorHelper.has('scopes')?.find((name) => name === scope.name)
                  ? 'text-red'
                  : ''
              }
            >
              • {scope.name}{' '}
              {scope.alias && (
                <>
                  <span className="font-bold">OR</span> {scope.alias}
                </>
              )}
            </li>
          ))}
        </ul>
        <InputDecoration label="Shopname" required>
          <Input
            value={shopNameInput}
            onChange={setShopNameInput}
            error={errorHelper.has('shop_name')}
            placeholder="Enter your shop name here..."
          />
        </InputDecoration>
        <InputDecoration label="Admin API access token" required>
          <Input
            value={apiKeyInput}
            onChange={setApiKeyInput}
            error={errorHelper.has('api_key')}
            placeholder="Enter your token key here..."
          />
        </InputDecoration>{' '}
        <InputDecoration label="Storefront API access token" required>
          <Input
            value={storeFrontAccessTokenInput}
            onChange={setStoreFrontAccessTokenInput}
            error={errorHelper.has('storefront_access_token')}
            placeholder="Enter your token key here..."
          />
        </InputDecoration>
        <InputDecoration label="API KEY" required>
          <Input
            value={apiIdInput}
            onChange={setApiIdInput}
            error={errorHelper.has('api_id')}
            placeholder="Enter your token key here..."
          />
        </InputDecoration>
        <InputDecoration label="API secret key" required>
          <Input
            value={apiSecretInput}
            error={errorHelper.has('api_secret')}
            onChange={setApiSecretInput}
            placeholder="Enter your token key here..."
          />
        </InputDecoration>
        {errorHelper.isError() && (
          <ErrorAlert
            title={
              errorHelper.has('scopes')
                ? 'You are missing the highlighted scopes in your shopify app'
                : errorHelper.message()
            }
          />
        )}
        <div className="flex justify-end">
          <Button
            text="Create connection"
            type="submit"
            color="secondary"
            isLoading={createShopifyMutation.isPending}
          />
        </div>
      </form>
    </Dialog>
  );
};
