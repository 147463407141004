import { useState } from 'react';

type Props = {
  percentage?: number;
  text?: string;
  targetPercentage?: number;
};

export const ScoreBar = ({
  percentage = 0,
  targetPercentage = 0,
  text,
}: Props) => {
  const [progressBarRef, setProgressBarRef] = useState<HTMLDivElement | null>(
    null,
  );

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-between">
        <div className="text-gray-600">
          <span className="text-4xl font-extrabold">{percentage}</span>
          <span className="self-end  font-extrabold">%</span>
        </div>
        <p className="self-center text-sm text-gray-600">
          {text
            ?.replace('{{targetPercentage}}', '' + targetPercentage)
            .replace('{{percentage}}', '' + percentage)}
        </p>
      </div>
      <div
        ref={setProgressBarRef}
        className="relative mb-2 mt-2 h-3 rounded-full bg-gray-200"
      >
        {/* Pin */}
        {
          <div
            style={{
              left: `calc(${targetPercentage}% - 6px)`,
            }}
            className="absolute -top-[14px] h-0 w-0
        border-l-[6px] border-r-[6px]
        border-t-[10px] border-l-transparent
        border-r-transparent border-t-gray-500"
          />
        }
        {/* Bar */}
        <div
          style={{
            right: `calc(${100 - percentage}% - ${
              16 - (percentage / 100) * 16
            }px)`,
            background: `linear-gradient(90deg, #F85777 0px, #FAD337 ${
              (progressBarRef?.clientWidth ?? 0) / 2
            }px, #34d399 ${progressBarRef?.clientWidth}px)`,
          }}
          className="absolute -bottom-1 -left-1 -top-1 rounded-full transition-all"
        />
      </div>
    </div>
  );
};
